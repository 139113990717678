import React from "react";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Toolbar, CssBaseline, Typography, IconButton, Stack } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { useMsal } from "@azure/msal-react";
import TranslationContent from "../../Translations/TranslationContent";
import { useViewport } from "../../Context/ViewportProvider";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../Context/SessionProvider";
import MobileDrawer from "./MobileDrawer";
import DesktopDrawer from "./DesktopDrawer";
import { DrawerHeader } from "./DrawerHeader";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, mobileOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((open || mobileOpen )&& {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



const MainHeader = ({ children }) => {
  const { width, height } = useViewport();
  const [open, setOpen] = React.useState(width > 850 && height > 500 ? true : false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const session = React.useContext(SessionContext);

  let navigationList = {
    basic: [
      {
        section: "home",
        path: "/home"
      },
    ],
    tools: [
      {
        section: "compare",
        path: "/compare"
      },
      {
        section: "export",
        path: "/export"
      },
    ],
    management: [
      session.showAdminsTools ? 
      {
        section: "userManagement",
        path: "/users",
      } : null,
      session.showAdminsTools ?
      {
        section: "data",
        path: "/data",
      } : null,
    ],
  }

  React.useEffect(() => {
    if(width < 750) {
      setOpen(false);
    }
  },[width])

  const handleDrawerOpen = () => {
    if(width > 750) {
      setOpen(true);
    }
    else {
      setMobileOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if(width > 750) {
      setOpen(false);
    }
    else {
      setMobileOpen(false);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  }

  const handleNavigation = (path) => {
    if(path === "/users") {
      window.open("https://myaccount.microsoft.com/groups/groups-i-own", "_blank");
      return;
    }
    if(path === "/compare" && window.location.pathname.includes("/compare")) {
      navigate("/compare");
      return;
    }
    if(path !== "/users" && !(path === "/compare" && window.location.pathname.includes("/compare"))) {
      navigate(path);
      return;
    }
    return;
  }

  return(
    <Box sx={{ display: 'flex', maxWidth: "100%", overflowX: "hidden" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack spacing={0}>
            <Typography variant="h6" noWrap component="div" color="primary">
              <TranslationContent contentID="mainTitle"/>
            </Typography>
            <Typography variant="caption" component="div" color="primary">
              {session.name} ({session.email})
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      {width > 750 && height > 500 ?
        <DesktopDrawer open={open} navigationList={navigationList} handleLogout={handleLogout} handleNavigation={handleNavigation} handleDrawerClose={handleDrawerClose} />
      :
        <MobileDrawer mobileOpen={mobileOpen} navigationList={navigationList} handleLogout={handleLogout} handleNavigation={handleNavigation} handleDrawerClose={handleDrawerClose} />
      }
      <Box component="main" sx={{ flexGrow: 1, maxWidth: "99%", p: width > 750 ? 2 : 1, mt: width > 750 ? 0 : 1.5 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}

export default MainHeader;