import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  light: {
    document: {
      width: "2000px"
    },
    page: {
      //flexDirection: 'row',
      backgroundColor: '#E4E4E4',
      //backgroundColor: "black",
      //backgroundColor: "#A9A9A9",
      width: "100%",
      height: "100%"
    },
    section: {
      margin: 10,
      padding: 10,
      textAlign: "center",
      fontSize: "10px"
      //flexGrow: 1,
    },
    text: {
      fontSize: "10px"
    },
    header: {
      fontSize: "25px",
      fontWeight: 800
    },
    chart: {},
    table: {},
    textView: {
      margin: 5,
      padding: 5,
      textAlign: "left",
      marginLeft: "10%",
      marginRight: "10%",
      fontSize: "10px"
    },
    headerView: {
      margin: 5,
      padding: 5,
      textAlign: "center",
    },
    chartView: {
      backgroundColor: '#E4E4E4',
    },
    tableView: {
      backgroundColor: '#E4E4E4',
    },
    empty: {
      display: "none"
    }
  },

  dark: {
    document: {
      width: "2000px"
    },
    page: {
      //flexDirection: 'row',
      backgroundColor: '#E4E4E4',
      //backgroundColor: "black",
      //backgroundColor: "#A9A9A9",
      width: "100%",
      height: "100%"
    },
    section: {
      margin: 10,
      padding: 10,
      textAlign: "center",
      fontSize: "10px"
      //flexGrow: 1,
    },
    text: {
      fontSize: "10px"
    },
    header: {
      fontSize: "25px",
      fontWeight: 800
    },
    chart: {},
    table: {},
    textView: {
      margin: 5,
      padding: 5,
      textAlign: "left",
      marginLeft: "10%",
      marginRight: "10%",
      fontSize: "10px"
    },
    headerView: {
      margin: 5,
      padding: 5,
      textAlign: "center",
    },
    chartView: {
      backgroundColor: "#121212",
      padding: "5px"
    },
    tableView: {
      backgroundColor: "#121212",
      padding: "5px"
    },
    empty: {
      display: "none"
    }
  }
});

const PDFDocument = ({exportContent, theme }) => {
  return(
    <Document 
      style={styles[theme].document} 
      title="Export Tool Content Preview"
      author=""
      subject=""
      //keywords=""
      creator="auth.name"
      producer="Ruukki Product Comparison Tool"
      //language={lang.lang}
    >
      <Page size="A4" style={styles[theme].page}>
        {exportContent.map((item, index) => 
        <Item key={item.type + index} style={styles} item={item} theme={theme} />
         )}
    </Page>
    </Document>
  )
      
}

export default PDFDocument;

const Item = ({ item, theme }) => {
  if(item.type === "header") {
    return(
      <View style={styles[theme].headerView}>
        <Text style={styles[theme].header}>
          {item.content}
        </Text>
      </View>
    )
  }
  if(item.type === "text") {
    return(
      <View style={styles[theme].textView}>
        <Text style={styles[theme].text}>
          {item.content}
        </Text>
      </View>
    )
  }
  if(item.type === "chart") {
    return(
      <View style={styles[theme].chartView}>
        <Image src={item.content} />
      </View>
    )
  }
  if(item.type === "table") {
    return(
      <View style={styles[theme].tableView}>
        <Image src={item.content} />
      </View>
    )
  }
  if(item.type === "") {
    return(
      <View style={styles[theme].empty}>
      </View>
    )
  }
}