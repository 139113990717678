export const  darkLineTheme = {
  //"background": "#ffffff",
  "text": {
    "fontSize": 12,
    "fill": "white",
    //"fill": "#333333",
    "outlineWidth": 0,
    "outlineColor": "transparent"
},
  "crosshair": {
    "line": {
      "stroke": 'white', // Change this to your desired color
      "strokeWidth": 1,
      //"strokeOpacity": 0.75
    },
  },
  "axis": {
    "domain": {
        "line": {
            "stroke": "#777777",
            "strokeWidth": 1
        }
    },
    "legend": {
        "text": {
            "fontSize": 12,
            "fill": "red",
            //"fill": "#333333",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        }
    },
    "ticks": {
        "line": {
            "stroke": "#777777",
            "strokeWidth": 1
        },
        "text": {
            "fontSize": 11,
            "fill": "white",
            //"fill": "#333333",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        }
    }
},
  "tooltip": {
    "wrapper": {
      "zIndex": 1400,
      "maxHeight": "100vw",
      "maxWidth": "100vh",
      "minHeight": "200px",
    },
    "container": {
      "background": "#ffffff",
      "color": "#333333",
      "fontSize": 12
    },
    "basic": {},
    "chip": {},
    "table": {},
    "tableCell": {},
    "tableCellValue": {}
}
}


export const lightLineTheme = {
  //"background": "#ffffff",
  "text": {
    "fontSize": 12,
    "fill": "black",
    //"fill": "#333333",
    "outlineWidth": 0,
    "outlineColor": "transparent"
},
  "tooltip": {
    "wrapper": {
      "zIndex": 1400,
    },
    "container": {
        "background": "#ffffff",
        "color": "#333333",
        "fontSize": 12
    },
    "basic": {},
    "chip": {},
    "table": {},
    "tableCell": {},
    "tableCellValue": {}
}
}