import { Autocomplete, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import React from "react";
import TranslationContent from "../../../Translations/TranslationContent";


const Filter = ({  objectKey, entry, removeFilter, comparisonData, addFilter }) => {
  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    let masterDataCopy = [...comparisonData.data];

    if (comparisonData.filters.length > 0) {
      // If no commented back and approved by customer, remove this and line 21 after July 2024
      // - SK 2.7.2024
      //let currentObjectKeyIndex = comparisonData.headers.findIndex(header => header.uniqueKey === objectKey);

      masterDataCopy = masterDataCopy.filter(row => {
        for (const filter of comparisonData.filters) {
          if(filter.objectKey === objectKey) {
            return true;
          }
          if (filter?.objectKey?.split(":")[2] === "string" /*&& currentObjectKeyIndex > comparisonData.headers.findIndex(header => header.uniqueKey === filter.objectKey)*/) {
            if (!filter.values.includes(row[filter.objectKey])) {
              return false; // If any filter condition fails, exclude the row
            }
          }
        }
        return true; // Include the row if all filter conditions are met
      });
    }

    setFilteredData(masterDataCopy);
  }, [comparisonData, objectKey]);
  
  let options = [];

  const cleanData = (dataEntry) => {
    if(typeof dataEntry[objectKey] !== 'undefined' && dataEntry[objectKey] !== null) {
      options.push(dataEntry[objectKey]);
    }
  }

  filteredData.forEach(cleanData);

  let cleanedOptions = [...new Set(options)];

  function getOccurrence(array, value) {
    return array.filter((v) => (v === value)).length;
  }

  const handleFilterSelect = (event, value) => {
    addFilter(objectKey, value);
  }

  const toggleFilter = (type) => {
    if(comparisonData.filters.filter(filter => filter.objectKey === objectKey).length > 0) {
      removeFilter(objectKey);
    }
    else {
      addFilter(objectKey, type);
    }
  }

  const autoCompleteValues = () => {
    let cleanedFilters = [...comparisonData.filters];
    let values = [];
    cleanedFilters = cleanedFilters.filter(filter => filter.objectKey === objectKey);

    cleanedFilters.forEach((cleanFilter) => values = values.concat(cleanFilter.values));
    return values;
  }


  if(entry.type === "string") {
  return(
    <>
      <Autocomplete 
        size="small"
        multiple
        id={objectKey}
        options={cleanedOptions}
        //getOptionLabel={(option) => option.value}
        getOptionLabel={(option) => option + ` (${getOccurrence(options, option)})` }
        //value={comparisonData.filters.filter(filter => filter.objectKey === objectKey)}
        value={autoCompleteValues()}
        onChange={(event, value) => handleFilterSelect(event, value)}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label={entry?.name}
            placeholder={entry?.name}
          />
        )}
      />
    {autoCompleteValues().length > 0 ?
    <Typography variant="caption">{autoCompleteValues().length} / {cleanedOptions.length} <TranslationContent contentID="options" /></Typography>
    :
    <Typography variant="caption" sx={{marginLeft: "10px"}}>{cleanedOptions.length} <TranslationContent contentID="options" /></Typography>
    }
    </>
  )
  }
  else {
    return(
      <FormControlLabel 
        label={entry?.name}
        control=
          {
            <Checkbox 
            size="small"
              checked={comparisonData.filters.filter(filter => filter.objectKey === objectKey).length > 0}
              //onChange={(event) => handleFilterSelect(event, entry.type)}
              onChange={() => toggleFilter(objectKey, entry.type)}
            />
          } 
      />
    )
  }
  
}


export default Filter;