import React from "react";
import { Typography } from "@mui/material";
import TranslationContent from "../../../../Translations/TranslationContent";


const HeaderTypeInvalid = () => {
  return(
    <>
      <Typography sx={{color: "black", mb: 1, mt: 1}} variant="body2"><TranslationContent contentID="name" />:<TranslationContent contentID="category" />:<b style={{color: "red"}}><TranslationContent contentID="type" /></b></Typography>
      <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="headerTypeInvalidDesc" />:</Typography>
      <div style={{height: "8px"}}/>
      <Typography sx={{color: "black"}} variant="body2"><b style={{color: "green"}}>string</b> = <TranslationContent contentID="text" /></Typography>
      <Typography sx={{color: "black"}} variant="body2"><b style={{color: "green"}}>number</b> = <TranslationContent contentID="numberDesc" /> <b><TranslationContent contentID="withPoint" /></b>)</Typography>
      <Typography sx={{color: "black"}} variant="body2"><b style={{color: "green"}}>discount</b> = <TranslationContent contentID="discountDesc" /> <b><TranslationContent contentID="withPoint" /></b>)</Typography>
      <Typography sx={{color: "black"}} variant="body2"><b style={{color: "green"}}>boolean</b> = <TranslationContent contentID="booleanDesc" /></Typography>
      <Typography sx={{color: "black"}} variant="body2"><b style={{color: "green"}}>date</b> = <TranslationContent contentID="dateDesc" /></Typography>
      <div style={{height: "8px"}}/>
      <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="headerTypeInvalidSummary" /></Typography>
    </>
  )
}

export default HeaderTypeInvalid;