import { logger } from "../../services/logger";
import { isValidNumber, isValidDiscount, isValidBoolean, isValidDate } from "../../Validators/helperFunctions";
import { formatNumber } from "../FormatData/formatNumber";
import { formatDiscount } from "../FormatData/formatDiscount";
import { formatBoolean } from "../FormatData/formatBoolean";
import { formatDate } from "../FormatData/formatDate";

export const createDataValues = async (data, headers, discounts) => {
  logger("Starting to create data values...");
  let initializedData = [];

  initializedData = await Promise.all(data.map( async (row) => {
    let currentObjectKeysWithValues = Object.keys(row);
    for(const header of headers) {
      if(currentObjectKeysWithValues.some(key => key === header.uniqueKey)) {
        switch(header.type.trim()) {

          case "string":
            if(!typeof row[header.uniqueKey] === "string") {
              row[header.uniqueKey] = "Invalid value";
              break;
            }
            if(row[header.uniqueKey].trim().length < 1) {
              row[header.uniqueKey] = "No value";
              break;
            }
            break;

          case "number":
            if(!isValidNumber(row[header.uniqueKey])) {
              row[header.uniqueKey] = 0;
              break;
            }

            let initializedNumber = await formatNumber(row[header.uniqueKey]);
            if(initializedNumber === 0 || header.category !== "price") {
              row[header.uniqueKey] = initializedNumber;
              break;
            }

            let discountKey = discounts[0]?.key;

            //let initialDiscount = discounts.find(discount => discount.label === row[discountKey.uniqueKey]);
            let initialDiscount = discounts.find(discount => discount.label === row[discountKey.key]);

            if(!initialDiscount?.discount > 0) {
              row[header.uniqueKey] = initializedNumber;
              break;
            }

            row[header.uniqueKey] = parseFloat((initializedNumber * (1 - initialDiscount.discount / 100)).toFixed(2));
            break;

          case "discount":
            if(!isValidDiscount(row[header.uniqueKey])) {
              row[header.uniqueKey] = 0;
              break;
            }
            row[header.uniqueKey] = await formatDiscount(row[header.uniqueKey]);
            break;

          case "boolean":
            if(!isValidBoolean(row[header.uniqueKey])) {
              row[header.uniqueKey] = false;
              break;
            }
            row[header.uniqueKey] = await formatBoolean(row[header.uniqueKey]);
            break;

          case "date":
            if(!isValidDate(row[header.uniqueKey])) {
              row[header.uniqueKey] = "Date format not valid";
              break;
            }
            row[header.uniqueKey] = await formatDate(row[header.uniqueKey]);
            break;

          default:
            row[header.uniqueKey] = "Value type missing from header";
            break;
        }
      }
      else {
        switch(header.type) {
          case "string":
            row[header.uniqueKey] = "No value";
            break;
          case "number":
            row[header.uniqueKey] = 0;
            break;
          case "discount":
            row[header.uniqueKey] = 0;
            break;
          case "boolean":
            row[header.uniqueKey] = false;
            break;
          case "date":
            row[header.uniqueKey] = "No date specified";
            break;
          default:
            row[header.uniqueKey] = "Value type missing from header";
            break;
        }
      }
    }
    return row;
  }));
  logger(`Created ${initializedData.length} data values`);
  return initializedData;
};