import { fileDataValidator } from "./fileDataValidator";
import { fileHeaderValidator } from "./fileHeaderValidator";
import { logger } from "../services/logger";
import * as XLSX from "xlsx";


export const validateFile = async (file) => {
  logger("Starting to validate file...");

  if (!file) {
    logger("File validation failed, missing file!");
    logger(console.trace());
    return;
  }

  const reader = new FileReader();

  const fileReadPromise = new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(e.target.result);
    };

    reader.onerror = () => {
      reject(new Error("File read error"));
    };

    reader.readAsArrayBuffer(file);
  });

  try {
    const fileData = await fileReadPromise;
    const data = new Uint8Array(fileData);
    const workbook = XLSX.read(data, { type: 'array' });

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    if (jsonData.length > 0) {
      const headers = jsonData[0];
      const validatedHeaders = await fileHeaderValidator(headers);
      logger("Validated headers:");
      logger(validatedHeaders);

      const dataRows = jsonData.slice(1);
      const validatedDataRows = await fileDataValidator(headers, dataRows);
      logger("Validated data rows:");
      logger(validatedDataRows);

      if (validatedHeaders.length > 0 || validatedDataRows.length > 0) {
        return [...validatedHeaders, ...validatedDataRows];
      } else {
        return [];
      }
    }
  } catch (error) {
    logger(error);
    logger(console.trace());
  } finally {
    logger("File validated!");
  }
};
