import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SettingDivider from "../../Settings/SettingDivider";
import { Typography, Stack, Collapse } from "@mui/material";
import TranslationContent from "../../../../../Translations/TranslationContent";
import SwitchSetting from "../../Settings/SwitchSetting";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SliderSetting from "../../Settings/SliderSetting";
import SelectSetting from "../../Settings/SelectSetting";


const BarChartScaleSettings = 
  ({ 
    valueScale,
    updateValueScale,
    minValue,
    updateMinValue,
    maxValue,
    updateMaxValue,
    data,
    selectedValueButtons
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  
  // yMin and yMax in state so that controlled slider would always have number state
  // Chart itself uses the minValue and maxValue values that can be string "auto" or number
  const [yMin, setYMin] = React.useState(0);
  const [yMax, setYMax] = React.useState(0);

  // calculated range of values
  const [minYScaleOptionValue, setMinYScaleOptionValue] = React.useState(0);
  const [maxYScaleOptionValue, setMaxYScaleOptionValue] = React.useState(0);
  const { width } = useViewport();

  React.useEffect(() => {
    calculateHighestValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const calculateHighestValue = (data) => {
    let allValues = [];

    for(let i = 0; i < data.length; i++) {
      for(let x = 0; x < selectedValueButtons.length; x++) {
        allValues.push(Math.round(data[i][selectedValueButtons[x]]));
      }
    }

    let largestNumber = Math.max(...allValues);
    setYMax(largestNumber);

    let smallestNumber = Math.min(...allValues);
    if(smallestNumber < 0) {
      setYMin(smallestNumber);
    }
    else {
      setYMin(0);
    }

    let maxOptionValue = Math.round(largestNumber * 1.2)
    let minOptionValue = -(maxOptionValue);

    setMaxYScaleOptionValue(maxOptionValue);
    setMinYScaleOptionValue(minOptionValue);
  }

  
  const updateYMin = () => {
    calculateHighestValue(data)
    if(minValue === "auto") {
      updateMinValue(minYScaleOptionValue);
      setYMin(minYScaleOptionValue);
    }
    else {
      updateMinValue("auto");
    }
  }

  const updateYMax = () => {
    calculateHighestValue(data)
    if(maxValue === "auto") {
      updateMaxValue(maxYScaleOptionValue);
      setYMax(maxYScaleOptionValue);
    }
    else {
      updateMaxValue("auto");
    }
  }

  const updateYMinNumber = (value) => {
    updateMinValue(value);
    setYMin(value);
  }

  const updateYMaxNumber = (value) => {
    updateMaxValue(value);
    setYMax(value);
  }

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="scales">
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 1.5}}>
      <SelectSetting
          label="typeSetting"
          value={valueScale}
          handleChange={(value) => updateValueScale(value)}
          optionKey="barScaleType"
        />
      </Stack>
      <SettingDivider />

      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="yMin" /></b></Typography>
        <SwitchSetting 
          label="auto"
          value={minValue === "auto"}
          handleChange={updateYMin}  
        />
      </Stack>
      <Collapse in={minValue !== "auto"}>
        <SliderSetting 
          label="number"
          icon="min"
          value={yMin}
          updateValue={(value) => updateYMinNumber(value)}
          minValue={minYScaleOptionValue}
          maxValue={yMax}
        />
        </Collapse>

      <SettingDivider />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="yMax" /></b></Typography>
        <SwitchSetting 
          label="auto"
          value={maxValue === "auto"}
          handleChange={updateYMax}  
        />
      </Stack>
      <Collapse in={maxValue !== "auto"}>
        <SliderSetting 
          label="number"
          icon="max"
          value={yMax}
          updateValue={(value) => updateYMaxNumber(value)}
          minValue={yMin}
          maxValue={maxYScaleOptionValue}
        />
        </Collapse>

    </SettingContainer>
  )
}

export default BarChartScaleSettings;