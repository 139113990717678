import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, Divider, Box, IconButton, Collapse, ButtonGroup, Tooltip, Backdrop } from "@mui/material";
import BarChart from "./BarChart/BarChart";
import ChartTypeSelector from "./ChartTypeSelector";
import EmptyChart from "./EmptyChart";
import { duplicateChecker } from "../../../FilterUtils/duplicateChecker"
import TableSection from "../Tables/TableSection";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadMenu from "./DownloadMenu";
import TranslationContent from "../../../Translations/TranslationContent";
import { ExportContext, ExportDispatchContext } from "../../../Context/ExportProvider";
import { toPng } from 'html-to-image';
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import PieChart from "./PieChart/PieChart";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import { initializeChartData } from "../../../DataFactory/ChartData/initializeChartData";
import { initializeFilters } from "../../../DataFactory/ChartData/initializeFilters";
import { discountCalculator } from "../../../DataFactory/ChartData/discountCalculator";
import { logger } from "../../../services/logger";
import LineChart from "./LineChart/LineChart";
import { useViewport } from "../../../Context/ViewportProvider";


const GridContainer = styled(Grid)(() => ({
  position: 'relative',
  width: "99%",
}));


const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.drawer,
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));


const ChartSection = ({ comparisonData }) => {
  const [showChartsSection, setShowChartsSection] = React.useState(true);
  const exportContent = React.useContext(ExportContext);
  const updateExportContent = React.useContext(ExportDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const [activeChartType, setActiveChartType] = React.useState("bar");
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  const [chartData, setChartData] = React.useState({ 
    filteredData: [],
    numericFilters: [],
    stringFilters: [],
    booleanFilters: [],
    dateFilters: [],
    preferredIdentifier: comparisonData.preferredIdentifier || "",
  });
  const { width } = useViewport();

  React.useEffect(() => {
    if(comparisonData.filters.length > 0) {
      setIsOverlayVisible(true);
    }
    if(comparisonData.filters.length === 0) {
      createChartData();
      setIsOverlayVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[comparisonData])

  const createChartData = async () => {
    setIsOverlayVisible(false);

    let newChartData = JSON.parse(JSON.stringify({...chartData}));
    let dataClone = JSON.parse(JSON.stringify([...comparisonData.data]));

    newChartData.stringFilters = [...chartData.stringFilters];
    newChartData.numericFilters = [...chartData.numericFilters];
    newChartData.booleanFilters = [...chartData.booleanFilters];
    newChartData.dateFilters = [...chartData.dateFilters];
    newChartData.filteredData = [...chartData.filteredData];
    newChartData.filteredData = newChartData.filteredData.slice(newChartData.filteredData.length);
     
    let copyComparisonData = {...comparisonData};
    copyComparisonData.data = [...comparisonData.data]
    copyComparisonData.filters = [...comparisonData.filters];
    let initializationActions = [
      initializeChartData(dataClone, copyComparisonData.filters),
      initializeFilters(copyComparisonData.filters)
    ];

    const initialization = await Promise.all(initializationActions);
    let data = initialization[0];
    let filters = initialization[1];

    let discountedData = await discountCalculator([...data], [...comparisonData.discounts]);
    let filteredData = await duplicateChecker([...discountedData], [...filters.stringFilters], comparisonData.preferredIdentifier);

    newChartData.filteredData = filteredData;
    newChartData.stringFilters = filters.stringFilters;
    newChartData.numericFilters = filters.numericFilters;
    newChartData.booleanFilters = filters.booleanFilters;
    newChartData.dateFilters = filters.dateFilters;

    setChartData(newChartData)
  }

  const toggleChartShow = () => {
    setShowChartsSection(!showChartsSection);
  }

  const handleChartTypeChange = (chartType) => {
    setActiveChartType(chartType);
  }

  let addImageToExportContent = () => {
    let updatedExportContent = [...exportContent];
    let newContent = {
      type: "text",
      content: "Oops! Error when adding chart to export list!"
    }
    var node = document.getElementById('comparison-chart-container');
    toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        newContent = {
          type: "chart",
          content: dataUrl
        }
        updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]});
        addAlert({message: "snackbarItemAddedToExportList", type: "success"});
  })
  .catch(function (error) {
    logger("oops, something went wrong!");
    logger(error);
    logger(console.trace());
    newContent = {
      type: "text",
      content: "Oops! Error when adding chart to export list!"
    }
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]});
    addAlert({message: "snackbarItemAddedToExportListFailed", type: "error"});
  });
  }

  return(
    <>
      <GridContainer container direction="column" rowSpacing={1} mt={5}>
        <Grid xs={12}>
          <Stack direction={"row"} sx={{alignItems: "center"}}>
            <IconButton color="primary" onClick={() => toggleChartShow()}>
              {showChartsSection ? 
                <KeyboardArrowUpIcon />
              :
                <KeyboardArrowDownIcon />
              }
            </IconButton>
            <Typography sx={{marginTop: "auto", marginBottom: "auto"}}>
              <b>
                <TranslationContent contentID="charts" />
              </b>
            </Typography>
            &nbsp;&nbsp;
            <ChartTypeSelector 
              activeChartType={activeChartType}
              handleChartTypeChange={handleChartTypeChange}
              buttonsEnabled={comparisonData.filters.length > 0 && showChartsSection}
            />
            <ButtonGroup 
              sx={{marginRight: 0, marginLeft: "auto", verticalAlign: "middle"}}
              orientation={width > 500 ? "horizontal" : "vertical"}
            >
              <Button 
                size="small"
                variant="contained"
                disabled={chartData.filteredData.length < 1 || !showChartsSection}
                
                sx={{
                  width: width > 500 ? "" : "70px",
                  mb: width > 500 ? "" : "3px",
                  marginRight: 1,
                  marginLeft: "auto", 
                  height: "32px"}} 
                  
                onClick={() => addImageToExportContent()}
              >
                <Tooltip title={<TranslationContent contentID="addChartToExportList" />} arrow>
                  <PlaylistAddCircleIcon />
                </Tooltip>
              </Button>
              <Box>
                <DownloadMenu 
                  domElement="comparison-chart-container"
                  disabled={chartData.filteredData.length < 1 || !showChartsSection}
                />
              </Box>
            </ButtonGroup>
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Divider sx={{marginBottom: "1vh"}}/>
        </Grid>
      
        <Grid xs={12}>
          <Collapse in={showChartsSection}>
            <Grid container spacing={1} rowSpacing={1} mb={2}>
              <ChartManager activeChartType={activeChartType} chartData={chartData} />             
            </Grid>
          </Collapse>
        </Grid>

        <CustomBackdrop open={isOverlayVisible && showChartsSection}>
          <Stack spacing={2} bgcolor={"#121212"} p={5}>
            <Typography>
              <b>
                <TranslationContent contentID="filtersChanged" />
              </b>
            </Typography>
            <Button variant="contained" color="primary" onClick={() => createChartData()}>
              <RefreshIcon />&nbsp;&nbsp;<TranslationContent contentID="update" />
            </Button>
          </Stack>
        </CustomBackdrop>
      </GridContainer>
      
      <TableSection 
        chartData={chartData}
        comparisonData={comparisonData}
        createChartData={createChartData} 
        isOverlayVisible={isOverlayVisible}
      />
      
    </>
  )
}

export default ChartSection;


const ChartManager = ({ activeChartType, chartData }) => {
  switch (activeChartType) {
    case 'bar':
      return(<BarChart chartData={chartData} />);
    case 'pie':
      return(<PieChart chartData={chartData} />);
    case 'line':
      return(<LineChart chartData={chartData} />);
    default:
      return(<EmptyChart />);
  }
}