import { logger } from "../../services/logger";

export const createLineChartData = /*async*/ (chartData, stringFilter, numericFilters) => {
  logger("Starting to create line chart data...");
  let lineChartData = [];

  lineChartData = chartData.map(data => {

    let lineValues = [];
    lineValues = numericFilters.map(filter => {
      let newValue = {
        x: filter?.objectKey?.split(":")[0] || "Not found",
        y: data[filter?.objectKey] || 0,
      }
      return newValue;
    })
    
    const filteredObjectValues = Object.keys(data)
      .filter(key => !numericFilters.some(filter => filter.objectKey === key))
      .reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});

    let pieChartDataObject = {
      id: data[stringFilter],
      data: [...lineValues],
      otherData: {...filteredObjectValues},
    }
    return pieChartDataObject;
  })

  logger(lineChartData);
  return lineChartData;
}