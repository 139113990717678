import React from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';
import Login from './Components/Login/Login';
import NotFound from './Components/NotFound/NotFound';
import Home from './Components/Home/Home';
import Compare from './Components/ComparisonTool/Compare/Compare';
import ExportTool from './Components/ExportTool/ExportTool';
import CompareHome from './Components/ComparisonTool/Compare/CompareHome';
import Roles from './Components/Roles/Roles';
import ProductDataHome from './Components/ProductData/ProductDataHome';
import ProductData from './Components/ProductData/ProductData';


function App() {
  return (
    <Routes>
      <Route 
        index
        element={<Login />}
      />
      <Route 
        path="/home" 
        element={<Home />}
      />
      <Route 
        path="/roles"
        element={<Roles />}
      />
      <Route path="/compare" element={<CompareHome />} />
      <Route path="/compare/:id" element={<Compare />} />
      <Route path="/export" element={<ExportTool />} />
      <Route path="/data" element={<ProductDataHome />} />
      <Route path="/data/:id" element={<ProductData />} />
      <Route path="*" element={<NotFound />}/>
    </Routes>
  )
}

export default App;