import React from "react";
import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Avatar, Divider, Box, Slide, Button, Stack, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InfoIcon from '@mui/icons-material/Info';
import * as XLSX from "xlsx";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import TranslationContent from "../../Translations/TranslationContent";
import { logger } from "../../services/logger";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DataManagementDialog = ({ showDialog, toggleDialogShow, comparisonData, role, uploadLocalData, uploadData }) => {
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const { addAlert } = React.useContext(SnackBarContext);

  const handleUploadDataClick = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      logger(d);
      uploadData(d);
    });


  }

  const handleDownloadExcelClick = () => {
    const worksheet = XLSX.utils.json_to_sheet(comparisonData.data)

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "products")

    XLSX.writeFile(workbook, "product-comparison-data.xlsx")
  }

  const handleShareFilterClick = () => {
    let linkToShare = window.location.href;
    let filterParameters;
    let discountParameters;
    if(comparisonData.filters.length > 0) {
      filterParameters = encodeURIComponent(JSON.stringify(comparisonData.filters));
      linkToShare = linkToShare + "?filters=" + filterParameters;
    }
    if(comparisonData.discounts.length > 0) {
      discountParameters = encodeURIComponent(JSON.stringify(comparisonData.discounts));
      if(comparisonData.filters.length > 0) {
        linkToShare = linkToShare + "&discounts=" + discountParameters;
      } 
      else {
        linkToShare = linkToShare + "?discounts=" + discountParameters;
      }
    }
    
    navigator.clipboard.writeText(linkToShare);
    addAlert({message: "snackbarLinkCopiedClipboard", type: "success"});

    return;
  }

  const downloadLocalExcelFile = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      logger(d);
      uploadLocalData(d);
    });

  }

  const toggleHelpDialogOpen = () => {
    toggleDialogShow()
    setHelpDialogOpen(!helpDialogOpen);
  }

  return(
    <>
      <Dialog
        fullScreen
        open={helpDialogOpen}
        onClose={toggleHelpDialogOpen}
        TransitionComponent={Transition}
      >
        <Stack direction="row">
          <DialogTitle><b><TranslationContent contentID="faqTitle" /></b></DialogTitle><Button onClick={() => toggleHelpDialogOpen()} sx={{marginRight: 0, marginLeft: "auto"}}><TranslationContent contentID="close" /></Button>
        </Stack>
        <Divider />
        <Box padding={"20px"}>
          <Typography fontSize={"1.1rem"}><b><TranslationContent contentID="whatFilesSupported" /></b></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="whatFilesSupportedAnswer" /></Typography>
          <br />
          <Typography fontSize={"1.1rem"}><b><TranslationContent contentID="whoCanUpdate" /></b></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="whoCanUpdateAnswer" /></Typography>
          <br />
          <Typography fontSize={"1.1rem"}><b><TranslationContent contentID="howShouldBeFormated" /></b></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer1" /></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer2" /></Typography>
          <br />
          <Typography fontSize={"0.95rem"}><b><TranslationContent contentID="howShouldBeFormatedAnswer3" /></b></Typography>
          <br />
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer4" /></Typography>
          <br />
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer5" /></Typography>
          <br />
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer6" /></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer7" /></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer8" /></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer9" /></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer10" /></Typography>
          <br />
          <Typography fontSize={"0.95rem"}><b><TranslationContent contentID="howShouldBeFormatedAnswer11" /></b></Typography>
          <Typography fontSize={"0.95rem"}><TranslationContent contentID="howShouldBeFormatedAnswer12" /></Typography>
          <br />
          <Typography fontSize={"1.1rem"}><b><TranslationContent contentID="howShouldBeFormatedAnswer13" /></b></Typography>
          <br />
        </Box>
        <Box textAlign={"center"} mb={0} mt="auto">
          <Divider />
          <Button onClick={() => toggleHelpDialogOpen()}><TranslationContent contentID="close" /></Button>
        </Box>
      </Dialog>
      <Dialog onClose={toggleDialogShow} open={showDialog} fullWidth maxWidth={"xs"}>
        <DialogTitle><b><TranslationContent contentID="manageData" /></b></DialogTitle>
        <Divider />
        <List sx={{pt: 0}}>
          {role === "admin" &&
            <ListItem disableGutters>
            <input
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers"
              style={{display: "none"}}
              id="raised-button-file"
              type="file"
              onChange={(e) => handleUploadDataClick(e.target.files[0])}
            />
            <label htmlFor="raised-button-file" style={{width: "100%"}}>
              <ListItemButton component="span">
                <ListItemAvatar>
                  <Avatar sx={{  color: "white" }}><CloudUploadIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={<TranslationContent contentID="uploadData" />}/>
              </ListItemButton>
            </label>
          </ListItem>
        }

        <ListItem disableGutters>
            <ListItemButton onClick={() => handleDownloadExcelClick()}>
              <ListItemAvatar>
                <Avatar sx={{  color: "white" }}><CloudDownloadIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText primary={<TranslationContent contentID="downloadData" />}/>
            </ListItemButton>
          </ListItem>

          <ListItem disableGutters>
            <input
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers"
              style={{display: "none"}}
              id="raised-button-file"
              type="file"
              onChange={(e) => downloadLocalExcelFile(e.target.files[0])}
            />
            <label htmlFor="raised-button-file" style={{width: "100%"}}>
              <ListItemButton component="span">
                <ListItemAvatar>
                  <Avatar sx={{  color: "white" }}><CloudOffIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={<TranslationContent contentID="useLocalData" />}/>
              </ListItemButton>
            </label>
          </ListItem>

          <ListItem disableGutters>
            <ListItemButton onClick={() => handleShareFilterClick()}>
              <ListItemAvatar>
                <Avatar sx={{  color: "white" }}><ShareIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText primary={<TranslationContent contentID="shareFilters" />}/>
            </ListItemButton>
          </ListItem>

          <ListItem disableGutters>
            <ListItemButton onClick={() => toggleHelpDialogOpen()}>
              <ListItemAvatar>
                <Avatar sx={{  color: "white" }}><InfoIcon color="white" /></Avatar>
              </ListItemAvatar>
              <ListItemText primary={<TranslationContent contentID="help" />}/>
            </ListItemButton>
          </ListItem>
          
        </List>
      </Dialog>
    </>
  )
}

export default DataManagementDialog;