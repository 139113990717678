import { useContext } from "react";
import { LanguageContext } from "./LanguageProvider";
import { translations } from "./translations.js";

/**
 * @param {React Component props} props destructured just for the contentID
 * @returns
 */
export default function PossibleTranslationContent({ contentID }) {
  const language  = useContext(LanguageContext);
  if(translations[language.lang][contentID]) {
    return translations[language.lang][contentID];
  }
  else return contentID;
}