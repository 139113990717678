import React from "react";
import { ResponsivePie } from '@nivo/pie';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/material";
import { createPieChartData } from "../../../../DataFactory/ChartData/createPieChartData";
import EmptyChart from "../EmptyChart";
import { ThemeModeStateContext } from "../../../../Theme/ThemeProvider";
import { darkPieTheme, lightPieTheme } from "../../../../Theme/ChartThemes/pieChartThemes";
import { useViewport } from "../../../../Context/ViewportProvider";
import PieValueSettings from "./PieChartSettings/PieValueSettings";
import PieIdentifierSettings from "./PieChartSettings/PieIdentifierSettings";
import PieDimensionMarginSettings from "./PieChartSettings/PieDimensionMarginSettings";
import PieLegendSettings from "./PieChartSettings/PieLegendSettings";
import PieStyleSettings from "./PieChartSettings/PieStyleSettings";
import PieInteractivitySettings from "./PieChartSettings/PieInteractivitySettings";
import PieChartTooltip from "./PieChartToolTip";
import { optimizePieChartSettings } from "../../../../Optimization/ChartStyleOptimizations/optimizePieChartSettings";


const PieChart = ({ chartData }) => {
  const [selectedValueButton, setSelectedValueButton] = React.useState("");
  const [selectedIdentificationButton, setSelectedIdentificationButton] = React.useState("");
  const [pieData, setPieData] = React.useState([/*{id: "", label: "", value: 0}*/]);
  const currentTheme = React.useContext(ThemeModeStateContext);
  const { width, height } = useViewport();

  // Legend settings
  const [legendSpacing, setLegendSpacing] = React.useState(5);
  const [legendWidth, setLegendWidth] = React.useState(200);
  const [legendHeight, setLegendHeigth] = React.useState(20);
  const [symbolSize, setSymbolSize] = React.useState(12);
  const [legendTranslateY, setLegendTranslateY] = React.useState(-50);
  const [legendTranslateX, setLegendTranslateX] = React.useState(0);
  const [showLegend, setShowLegend] = React.useState(true);
  const [justifyLegend, setJustifyLegend] = React.useState(false);
  const [legendDirection, setLegendDirection] = React.useState("left-to-right");
  const [legendPosition, setLegendPosition] = React.useState("top");
  const [legendLayout, setLegendLayout] = React.useState("row");

  // styles
  const [chartColors, setChartColors] = React.useState("nivo");
  const [startAngle, setStartAngle] = React.useState(0);
  const [endAngle, setEndAngle] = React.useState(360);
  const [fit, setFit] = React.useState(true);
  const [innerRadius, setInnerRadius] = React.useState(0);
  const [padAngle, setPadAngle] = React.useState(0);
  const [cornerRadius, setCornerRadius] = React.useState(0);
  const [sortByValue, setSortByValue] = React.useState(false);
  const [enableArcLabels, setEnableArcLabels] = React.useState(true);
  const [arcLabel, setArcLabel] = React.useState("value");
  const [arcLabelsRadiusOffset, setArcLabelsRadiusOffset] = React.useState(0.5);
  const [arcLabelsSkipAngle, setArcLabelsSkipAngle] = React.useState(0);
  const [enableArcLinkLabels, setEnableArcLinkLabels] = React.useState(true);
  const [arcLinkLabel, setArcLinkLabel] = React.useState("id");
  const [arcLinkLabelsOffset, setArcLinkLabelsOffset] = React.useState(0);
  const [arcLinkLabelsSkipAngle, setArcLinkLabelsSkipAngle] = React.useState(0);
  const [arcLinkLabelsDiagonalLength, setArcLinkLabelsDiagonalLength] = React.useState(16);
  const [arcLinkLabelsStraightLength, setArcLinkLabelsStraightLength] = React.useState(24);
  const [arcLinkLabelsTextOffset, setArcLinkLabelsTextOffset] = React.useState(6);
  const [arcLinkLabelsThickness, setArcLinkLabelsThickness] = React.useState(1);

  // Interactivity
  const [isInteractive, setIsInteractive] = React.useState(true);
  const [customTooltip, setCustomTooltip] = React.useState(true);
  const [activeInnerRadiusOffset, setActiveInnerRadiusOffset] = React.useState(0);
  const [activeOuterRadiusOffset, setActiveOuterRadiusOffset] = React.useState(0);

  // Dimensions & Margins
  const [chartTopMargin, setChartTopMargin] = React.useState(50);
  const [chartBottomMargin, setChartBottomMargin] = React.useState(50);
  const [chartRightMargin, setChartRightMargin] = React.useState(200);
  const [chartLeftMargin, setChartLeftMargin] = React.useState(200);
  const [chartWidth, setChartWidth] = React.useState(Math.floor(width * 0.7));
  const [chartHeight, setChartHeigth] = React.useState(500);
  const [containerWidth, setContainerWidth] = React.useState(100);
  const [containerHeight, setContainerHeight] = React.useState(500);
  
  const optimizeChartSettings = React.useRef(true);
  React.useEffect(() => {
    if(chartData.filteredData.length > 0 && chartData.stringFilters.length > 0 && chartData.numericFilters.length > 0) {
      let newPieData = createPieChartData(chartData.filteredData, chartData.preferredIdentifier, chartData.numericFilters[0].objectKey);
      setPieData(newPieData);
      if(selectedValueButton === "" || !chartData.numericFilters.some(filter => filter.objectKey === selectedValueButton)) {
        setSelectedValueButton(chartData.numericFilters[0].objectKey);
      }
      if(selectedIdentificationButton === "") {
        setSelectedIdentificationButton(chartData.preferredIdentifier);
      }
      if(optimizeChartSettings) {
        optimizeChartSettings.current = false;
        let optimizedSettings = optimizePieChartSettings(width, height, pieData.length);
        setContainerHeight(optimizedSettings.containerHeight);
        setContainerWidth(optimizedSettings.containerWidth);
        setChartHeigth(optimizedSettings.chartHeight);
        setChartWidth(optimizedSettings.chartWidth);
        setChartBottomMargin(optimizedSettings.marginBottom);
        setChartLeftMargin(optimizedSettings.marginLeft);
        setChartRightMargin(optimizedSettings.marginRight);
        setChartTopMargin(optimizedSettings.marginTop);
        setLegendLayout(optimizedSettings.legendLayout);
        setLegendPosition(optimizedSettings.legendPosition);
        setLegendTranslateY(optimizedSettings.legendTranslateY);
        setLegendTranslateX(optimizedSettings.legendTranslateX);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chartData])

  const updatePieValues = (value) => {
    setSelectedValueButton(value);
    let updatedPieData = [...pieData];
    updatedPieData = createPieChartData(chartData.filteredData, selectedIdentificationButton, value);
    setPieData(updatedPieData);
  }

  const updatePieIds = (value) => {
    setSelectedIdentificationButton(value);
    let updatedPieData = [...pieData];
    updatedPieData = createPieChartData(chartData.filteredData, value, selectedValueButton);
    setPieData(updatedPieData);
  };

  const updateDimensions = (value, type) => {
    switch(type) {
      case "chartWidth":
        if(value >= 0 && value <= width) {
          setChartWidth(value);
        }
        break;
      case "chartHeight":
        if(value >= 0 && value <= containerHeight) {
          setChartHeigth(value)
        }
        break;
      case "containerWidth":
        if(value >= 0 && value <= 100) {
          setContainerWidth(value);
        }
        break;
      case "containerHeight":
        if(value >= 0 && value <= 2000) {
          setContainerHeight(value)
        }
        break;
      default:
        break;
    }
  };

  const updateMargin = (value, type) => {
    switch(type) {
      case "top":
        if(value >= 0 && value <= containerHeight) {
          setChartTopMargin(Number(value));
        }
        break;
      case "bottom":
        if(value >= 0 && value <= containerHeight) {
          setChartBottomMargin(Number(value));
        }
        break;
      case "right":
        if(value >= 0 && value <= width) {
          setChartRightMargin(Number(value));
        }
        break;
      case "left":
        if(value >= 0 && value <= width) {
          setChartLeftMargin(Number(value));
        }
        break;
      default:
        break;
    }
  };
  
  const restoreDefaults = () => {
    let optimizedSettings = optimizePieChartSettings(width, height, pieData.length);
    setContainerHeight(optimizedSettings.containerHeight);
    setContainerWidth(optimizedSettings.containerWidth);
    setChartHeigth(optimizedSettings.chartHeight);
    setChartWidth(optimizedSettings.chartWidth);
    setChartBottomMargin(optimizedSettings.marginBottom);
    setChartLeftMargin(optimizedSettings.marginLeft);
    setChartRightMargin(optimizedSettings.marginRight);
    setChartTopMargin(optimizedSettings.marginTop);
    setLegendLayout(optimizedSettings.legendLayout);
    setLegendPosition(optimizedSettings.legendPosition);
    setLegendTranslateY(optimizedSettings.legendTranslateY);
    setLegendTranslateX(optimizedSettings.legendTranslateX);
  }
  

  if(pieData.length < 1 || chartData.stringFilters.length < 1 || chartData.numericFilters.length < 1) return <EmptyChart />
  if(pieData.length > 0) {
    return(
      <>
        <Grid xs={12} sx={{width: "100%"}} >
        <Box sx={{ width: `${containerWidth}%`, height: containerHeight }} id='comparison-chart-container' >
          <ResponsivePie
            height={chartHeight}
            width={chartWidth}
            theme={currentTheme.theme === "light" ? lightPieTheme : darkPieTheme}
            data={pieData}
            tooltip={
              customTooltip ? 
                ({datum}) => {
                  return <PieChartTooltip data={datum.data || {}} />
                }
              : 
              undefined
            }
            margin={{ top: chartTopMargin, right: chartRightMargin, bottom: chartBottomMargin, left: chartLeftMargin }}
            colors={{ scheme: chartColors }}
            startAngle={startAngle}
            endAngle={endAngle}
            fit={fit}
            sortByValue={sortByValue}
            innerRadius={innerRadius}
            padAngle={padAngle}
            cornerRadius={cornerRadius}
            isInteractive={isInteractive}
            activeInnerRadiusOffset={activeInnerRadiusOffset}
            activeOuterRadiusOffset={activeOuterRadiusOffset}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            enableArcLinkLabels={enableArcLinkLabels}
            arcLinkLabel={arcLinkLabel === "idAndValue" ? e=>e.id+" ("+e.value+")" : arcLinkLabel}
            arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
            arcLinkLabelsTextOffset={arcLinkLabelsTextOffset}
            arcLinkLabelsOffset={arcLinkLabelsOffset}
            arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
            arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
            arcLinkLabelsTextColor={currentTheme.theme === "light" ? "black" : "white"}
            arcLinkLabelsThickness={arcLinkLabelsThickness}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={enableArcLabels}
            arcLabel={arcLabel === "idAndValue" ? e=>e.id+" ("+e.value+")" : arcLabel}
            arcLabelsRadiusOffset={arcLabelsRadiusOffset}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            legends={showLegend ? 
              [
                {
                  anchor: legendPosition,
                  direction: legendLayout,
                  justify: justifyLegend,
                  translateX: legendTranslateX,
                  translateY: legendTranslateY,
                  itemsSpacing: legendSpacing,
                  itemWidth: legendWidth,
                  itemHeight: legendHeight,
                  itemTextColor: '#999',
                  itemDirection: legendDirection,
                  itemOpacity: 1,
                  symbolSize: symbolSize,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                          itemTextColor: currentTheme.theme === "dark" ? 'white' : "black",
                      }
                    }
                  ]
                }
              ]
            :
              []
            }
          />
          </Box>
        </Grid>
        <PieValueSettings
          numericFilters={chartData.numericFilters}
          selectedValueButton={selectedValueButton}
          updatePieValues={updatePieValues}
        />
        <PieIdentifierSettings
          labelButtons={chartData.stringFilters}
          selectedLabelButton={selectedIdentificationButton}
          updateSelectedLabel={updatePieIds}
          preferredIdentifier={chartData.preferredIdentifier}
        />
        <PieDimensionMarginSettings
          topMargin={chartTopMargin} 
          bottomMargin={chartBottomMargin}
          rightMargin={chartRightMargin}
          leftMargin={chartLeftMargin}
          updateMargin={updateMargin}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          updateDimensions={updateDimensions}
          restoreDefaults={restoreDefaults}
        />
        <PieLegendSettings
          showLegend={showLegend}
          setShowLegend={() => setShowLegend(!showLegend)}
          legendPosition={legendPosition}
          setLegendPosition={(value) => setLegendPosition(value)}
          legendLayout={legendLayout}
          setLegendLayout={(value) => setLegendLayout(value)}
          legendTranslateX={legendTranslateX}
          setLegendTranslateX={(value) => setLegendTranslateX(value)}
          legendTranslateY={legendTranslateY}
          setLegendTranslateY={(value) => setLegendTranslateY(value)}
          legendWidth={legendWidth}
          setLegendWidth={(value) => setLegendWidth(value)}
          legendHeight={legendHeight}
          setLegendHeigth={(value) => setLegendHeigth(value)}
          legendSpacing={legendSpacing}
          setLegendSpacing={(value) => setLegendSpacing(value)}
          symbolSize={symbolSize}
          setSymbolSize={(value) => setSymbolSize(value)}
          justifyLegend={justifyLegend}
          setJustifyLegend={() => setJustifyLegend(!justifyLegend)}
          legendDirection={legendDirection}
          setLegendDirection={(value) => setLegendDirection(value)}
        />
        <PieStyleSettings
          chartColors={chartColors}
          updateChartColors={(value) => setChartColors(value)}
          startAngle={startAngle} 
          updateStartAngle={(value) => setStartAngle(value)}
          endAngle={endAngle}
          updateEndAngle={(value) => setEndAngle(value)}
          fit={fit}
          updateFit={() => setFit(!fit)}
          innerRadius={innerRadius}
          updateInnerRadius={value => setInnerRadius(value)}
          padAngle={padAngle}
          updatePadAngle={value => setPadAngle(value)}
          cornerRadius={cornerRadius}
          updateCornerRadius={value => setCornerRadius(value)}
          sortByValue={sortByValue}
          updateSortByValue={() => setSortByValue(!sortByValue)}

          enableArcLabels={enableArcLabels}
          updateEnableArcLabels={() => setEnableArcLabels(!enableArcLabels)}
          arcLabel={arcLabel}
          updateArcLabel={value => setArcLabel(value)}
          arcLabelsRadiusOffset={arcLabelsRadiusOffset}
          updateArcLabelRadiusOffset={value => setArcLabelsRadiusOffset(value)}
          arcLabelsSkipAngle={arcLabelsSkipAngle}
          updateArcLabelSkipAngle={value => setArcLabelsSkipAngle(value)}
          enableArcLinkLabels={enableArcLinkLabels}
          updateEnableArcLinkLabels={() => setEnableArcLinkLabels(!enableArcLinkLabels)}
          arcLinkLabel={arcLinkLabel}
          updateArcLinkLabel={value => setArcLinkLabel(value)}
          arcLinkLabelsOffset={arcLinkLabelsOffset}
          updateArcLinkLabelsOffset={value => setArcLinkLabelsOffset(value)}
          arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
          updateArcLinkLabelsSkipAngle={value => setArcLinkLabelsSkipAngle(value)}
          arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
          updateArcLinkLabelsDiagonalLength={value => setArcLinkLabelsDiagonalLength(value)}
          arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
          updateLinkLabelsStraightLength={value => setArcLinkLabelsStraightLength(value)}
          arcLinkLabelsTextOffset={arcLinkLabelsTextOffset}
          updateArcLinkLabelsTextOffset={value => setArcLinkLabelsTextOffset(value)}
          arcLinkLabelsThickness={arcLinkLabelsThickness}
          updateArcLinkLabelsThickness={value => setArcLinkLabelsThickness(value)}
        />
        <PieInteractivitySettings
          isInteractive={isInteractive}
          updateIsInteractive={() => setIsInteractive(!isInteractive)}
          customTooltip={customTooltip}
          updateCustomTooltip={() => setCustomTooltip(!customTooltip)}
          activeInnerRadiusOffset={activeInnerRadiusOffset}
          updateActiveInnerRadiusOffset={value => setActiveInnerRadiusOffset(value)}
          activeOuterRadiusOffset={activeOuterRadiusOffset}
          updateActiveOuterRadiusOffset={value => setActiveOuterRadiusOffset(value)}
        />
      </>
    )
  }
}

export default PieChart;