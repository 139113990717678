import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const VALID_BOOLEAN_TRUE_VALUES = ["tak", "kyllä", "yes", "true", true];

const BooleanValue = ({ value }) => {
  if(VALID_BOOLEAN_TRUE_VALUES.includes(value)) {
    return <CheckCircleOutlineIcon fontSize="small" color="success" sx={{mt: 0.5}} />
  }
  else return <HighlightOffIcon fontSize='small' color="error" sx={{mt: 0.5}} />
}

export default BooleanValue;