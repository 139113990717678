import { excelColumns } from "./excelColumns";

const VALID_HEADER_TYPES = ['date', 'string', 'number', 'discount', 'boolean'];


export async function fileHeaderValidator(headers) {

  const newInvalidHeaders = [];
  for (let i = 0; i < headers.length; i++) {

    let invalidHeader = {
      location: `${excelColumns[i]}1`,
      entryType: "header",
      value: String(),
      errors: [],
      warnings: [],
    };

    if(i in headers) {
      invalidHeader.value = headers[i].split(':')[0];
      if(headers[i].split(':').length < 3){
        invalidHeader.errors.push("headerMissingLabelCategoryOrType");
      }
      if(!VALID_HEADER_TYPES.includes(headers[i]?.split(':')[2]?.trim())) {
        invalidHeader.errors.push("headerTypeInvalid");
      }
      if(headers[i].split(':').length > 3) {
        invalidHeader.errors.push("headerFormatInvalid");
      }
      if(headers[i].split(':')[0]?.length < 4) {
        invalidHeader.errors.push("headerNameTooShort");
      }
      if(headers[i].split(':')[1]?.length < 4) {
        invalidHeader.errors.push("headerCategoryTooShort");
      }
      if(headers[i].split(':')[0]?.length < 5) {
        invalidHeader.warnings.push("headerNameShort");
      }
      if(headers[i].split(':')[1]?.length < 5) {
        invalidHeader.warnings.push("headerCategoryShort");
      }
      if(headers.filter(header => header === headers[i]).length > 1) {
        invalidHeader.errors.push("headerConflict");
      }
    }
    else {
      invalidHeader.errors.push("headerMissing");
    }
    if(invalidHeader.errors.length > 0 || invalidHeader.warnings.length > 0) {
      newInvalidHeaders.push({...invalidHeader});
    }
  }
   return newInvalidHeaders;
};