import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Paper, Typography, Button, Stack } from "@mui/material";
import FilterSection from "../Filters/FilterSection";
import ChartSection from "../Charts/ChartSection";
import DiscountSection from "../Filters/DiscountSection";
import { useNavigate } from "react-router-dom";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "../loadingDots.css";
import { useViewport } from "../../../Context/ViewportProvider";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { LanguageContext } from "../../../Translations/LanguageProvider";


const CompareSuccess = 
  ({ 
    loading, 
    error, 
    toggleDialogShow, 
    comparisonData, 
    addFilter, 
    removeFilter, 
    removeAllFilters, 
    changeDiscount, 
    changeDiscountKey, 
    removeAllDiscounts,
    groupData
  }) => {

  const  { width, height } = useViewport();
  const currentLang = React.useContext(LanguageContext);
  const navigate = useNavigate();

  if(!loading && !error) {
    return(
      <>
        <Grid container mb={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px", display: "inline-flex", width: "100%"}} elevation={5}>
              {width > 750 && height > 500 ?
                <Typography color="primary" variant="body2" sx={{alignContent: "center"}}>
                  <b onClick={() => navigate("/compare")} style={{cursor: "pointer", marginTop: "auto", marginBottom: "auto"}}>
                    <TranslationContent contentID="compare" />
                  </b>
                  <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                </Typography>
              :
                <Stack direction="row" sx={{alignContent: "center", marginTop: "auto", marginBottom: "auto"}}>
                  <QueryStatsIcon color="primary" fontSize="small" sx={{cursor: "pointer"}} onClick={() => navigate("/compare")} />
                  <KeyboardArrowRightIcon color="primary" sx={{verticalAlign:"middle"}} />
                </Stack>
              }
              <Typography color="primary" variant="body2" sx={{marginTop: "auto", marginBottom: "auto"}}>
                {groupData.name[currentLang.lang]} (<TranslationContent contentID={groupData.country} />)
              </Typography>
              <Button size="small" onClick={() => toggleDialogShow()} variant="contained" sx={{marginRight: 0, marginLeft: "auto"}}><TranslationContent contentID="manageData" /></Button>
            </Paper>
          </Grid>
        </Grid>
        
        <Paper elevation={5} sx={{padding: "10px", marginTop: "5px"}}>
          <Grid container rowSpacing={2} columnSpacing={2} mt={0}>
            <FilterSection comparisonData={comparisonData} addFilter={addFilter} removeFilter={removeFilter} removeAllFilters={removeAllFilters} />
            <DiscountSection comparisonData={comparisonData} changeDiscount={changeDiscount} changeDiscountKey={changeDiscountKey} removeAllDiscounts={removeAllDiscounts} />
          </Grid>     
        </Paper>
        <ChartSection comparisonData={comparisonData} />
      </>
    )
  }
}

export default CompareSuccess;