import React, { useEffect } from "react"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { logger } from "../services/logger";

export const ThemeModeStateContext = React.createContext()
export const ThemeModeDispatchContext = React.createContext()

const initialState = {
  theme: "light"
}
   

function switchThemeReducer(state, action) {
  switch(action.type) {
    case "SWITCH_THEME_LIGHT": {
      return {
        ...state,
        theme: "light"
      }
    }
    case "SWITCH_THEME_DARK": {
      return {
        ...state,
        theme: "dark"
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}


 const checkLocalStorageTheme = (initialState)  => {
  const localTheme = localStorage.getItem("themeMode")
 
  if(localTheme === "light" || localTheme === "dark") {
    return {theme: localTheme};
  }
  else if(localTheme !== "light" || localTheme !== "dark"){
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
      if(prefersDarkMode) {
        return {theme: "dark"}
    }
  }
  return initialState;
}

const ThemeModeProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(switchThemeReducer, initialState, checkLocalStorageTheme)
  
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.theme,
          primary: {
            main: '#fc2516',
          },
          error: {
            main: '#ff9800',
          },
    
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                //backgroundColor: theme.palette.background.default,
                backgroundColor: state.theme === "dark" ? "#121212" : "rgb(255, 255, 255)",
                borderBottom: "2px solid #fc2516",
              }
            }
          },
          MuiAvatar: {
            styleOverrides: {
              colorDefault: {
                background: "#fc2516",
                //borderBottom: "2px solid #fc2516",
              }
            }
          },
        }
      }),
    [state],
  );

  useEffect(() => {
    localStorage.setItem("themeMode", state.theme);
    logger("Selected theme: " + state.theme);
  }, [state]);

  return(
    <ThemeModeStateContext.Provider value={state}>
      <ThemeModeDispatchContext.Provider value={dispatch}>
      <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </ThemeModeDispatchContext.Provider>
    </ThemeModeStateContext.Provider>
  )
}

export default ThemeModeProvider