import React from "react";
import { Typography } from "@mui/material";
import TranslationContent from "../../../../Translations/TranslationContent";


const HeaderMissingParts = () => {
  return(
    <>
      <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="headerMissingPartsDesc" /></Typography>
      
    </>
  )
}

export default HeaderMissingParts;