// Function to check duplicate values based on string filters
export async function duplicateChecker (data, filters, preferredIdentifier){

  if(filters.length > 0 && !filters.some(filter => filter.objectKey === preferredIdentifier)) {
    filters = [...filters, {category: preferredIdentifier.split(':')[1], name: preferredIdentifier.split(':')[0], type: preferredIdentifier.split(':')[2], objectKey: preferredIdentifier}];
  }

  let uniqueValues = [];
  for (const dataEntry of data) {

    if(uniqueValues.length < 1) {
      uniqueValues.push(dataEntry);
    }
    else {
    for(const filter of filters) {
      let countOfCurrentDataByFilter = uniqueValues.filter(value => value[filter.objectKey].includes(dataEntry[filter.objectKey]))?.length;

      if(countOfCurrentDataByFilter > 0) {
        dataEntry[filter.objectKey] = `${dataEntry[filter.objectKey]} (${countOfCurrentDataByFilter})`;
      }
    }
    uniqueValues.push(dataEntry);
    }
  }

  return uniqueValues;
}