import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { useLocation } from "react-router-dom";


const MenuIconHandler = ({ navigationItem}) => {
  const location = useLocation();

  if(navigationItem.section === "home") {
    if(location.pathname === navigationItem.path) {
      return <HomeIcon color="primary"/>
    }
    else {
      return <HomeOutlinedIcon color="primary"/>
    }
  }

  if(navigationItem.section === "compare") {
    if(location.pathname.includes("/compare")) {
      return <QueryStatsIcon color="primary" />
    }
    else {
      return <QueryStatsIcon color="primary" />
    }
  }

  if(navigationItem.section === "export") {
    if(location.pathname === navigationItem.path) {
      return <AutoFixHighIcon color="primary" />
    }
    else {
      return <AutoFixHighOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "userManagement") {
    if(location.pathname.includes("users")) {
      return <PeopleAltIcon color="primary" />
    }
    else {
      return <PeopleOutlineOutlinedIcon color="primary" />
    }
  }

  if(navigationItem.section === "data") {
    if(location.pathname.includes("data")) {
      return <CloudSyncIcon color="primary" />
    }
    else {
      return <CloudSyncOutlinedIcon color="primary" />
    }
  }
}

export default MenuIconHandler;