import React from "react";
import { Collapse, Typography, Paper, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TranslationContent from "../../../../Translations/TranslationContent";


const SettingContainer = ({ children, label, open, toggleOpen }) => {
  return(
    <Grid xs={12} md={6} xl={4}>
      <Paper orientation={10} sx={{p: 1}}>
        <Typography variant="caption" color="primary" sx={{pl: 1.5}}><b><TranslationContent contentID={label} /></b></Typography>
        <IconButton
          sx={{float: "right", verticalAlign: "middle", mt: -0.5}}
          color="primary"
          aria-label="expand settings"
          size="small"
          onClick={() => toggleOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <br />
        <Collapse in={open} unmountOnExit sx={{width: "100%"}}>
        <div style={{width: "100%", height: "10px"}} />
          {children}
        </Collapse>
      </Paper>
    </Grid>
  )
}

export default SettingContainer;