import React from "react";
import { Box, Button } from "@mui/material";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useMsal } from "@azure/msal-react";
import NotFound from "../NotFound/NotFound";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslationContent from "../../Translations/TranslationContent";
import { msalInstance } from "../..";
import RoleStatus from "./RoleStatus";
import AccountSelector from "./AccountSelector";
import LoginStatus from "./LoginStatus";
import { logger } from "../../services/logger";


const Roles = () => {
  const { instance, accounts } = useMsal();
  const [activeAccount, setActiveAccount] = React.useState({});
  const [activeAccountSelected, setActiveAccountSelected] = React.useState(false);
  
  logger(accounts);

  const handleLogout = () => {
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    });
    sessionStorage.clear();
  }

  
  const updateActiveAccount = (account) => {
    let updatedAccount = {...activeAccount};
    updatedAccount = account;
    setActiveAccount(updatedAccount);
    setActiveAccountSelected(true);
    msalInstance.setActiveAccount(account);
  }

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(accounts.length === 1 && fetchData.current) {
      fetchData.current = false;
      msalInstance.setActiveAccount(accounts[0]);
      setActiveAccount(accounts[0]);
      setActiveAccountSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(accounts.length < 1) return <NotFound />
  
  if(accounts.length > 0) {
    return (
      <Box width={"100vw"} height={"100vh"} sx={{padding: "5% 5% 5% 5%", textAlign: "center"}}>
        <Box mb={"60px"}>
          <CompanyLogo showAppLogo={true} width={270} />
        </Box>
        <LoginStatus accounts={accounts} activeAccount={activeAccount} activeAccountSelected={activeAccountSelected} />
        <RoleStatus activeAccount={activeAccount} activeAccountSelected={activeAccountSelected} />
        <AccountSelector accounts={accounts} activeAccountSelected={activeAccountSelected} updateActiveAccount={updateActiveAccount} />
        <br />
        <Button variant="outlined" size="small" sx={{marginTop: "25px"}} onClick={() => handleLogout()}><LogoutIcon fontSize="small"/>&nbsp;&nbsp;<TranslationContent contentID="logout"/></Button>
      </Box>
    )
  }
}

export default Roles;