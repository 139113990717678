import React from "react";
import { Box, Typography } from "@mui/material";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import TranslationContent from "../../../Translations/TranslationContent";


const ValidationError = ({ error }) => {
  if(error) {
    return(
      <Box sx={{textAlign: "center", height: "60vh", alignContent: "center"}}>
        <SyncProblemIcon 
          color="primary" 
          sx={{
            height: "150px",
            width: "150px",
            alignSelf: "center",
            animation: 'flash 2s infinite',
            '@keyframes flash': {
              '0%, 100%': { opacity: 1 },
              '50%': { opacity: 0.3 },
            },
          }}
        />
        <Typography><TranslationContent contentID="fileValidationFailed" /></Typography>
      </Box>
    )
  }
}

export default ValidationError;