import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Stack, Paper, Divider } from "@mui/material";
import { useViewport } from "../../../../Context/ViewportProvider";
import BooleanValue from "../../../Utils/GlobalComponents/BooleanValue";


const PieChartTooltip = ({ data }) => {
  const { width, height } = useViewport();
    
  const ValueTypography = ({value}) => {
    if(typeof value === "boolean") {
      return <BooleanValue value={value} />
    }
    if(value !== "No value" && value !== "Incorrect value type") {
      return(
        <Typography variant="caption" sx={{opacity: 0.7}}>{value}</Typography>
      )
    }
    if(value === "No value") {
      return(
        <Typography variant="caption" sx={{opacity: 0.5}}><i>{value}</i></Typography>
      )
    }
    if(value === "Incorrect value type") {
      return(
        <Typography  variant="caption" color="error">{value}</Typography>
      )
    }
    
  }

  return(
    <Paper sx={{padding: "10px", maxWidth: width * 0.6, maxHeight: height * 0.8, overflow: "hidden"}}>
      <Typography><b>{data.label}</b></Typography>
      <Divider />
      <Grid container spacing={0} sx={{minWidth: "100%", zIndex: 1301}}>
        {Object.keys(data.data).map(key => 
          <Grid xs={4} key={key}>
            <Stack direction="column">
              <Typography variant="caption"><b>{key.split(":")[0]}</b></Typography>
              <ValueTypography value={data.data[key]} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default PieChartTooltip;