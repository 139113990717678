import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import TranslationContent from '../../../Translations/TranslationContent';
import InfoContent from './InfoContent';
import ErrorContent from './ErrorContent';
import SuccessContent from './SuccessContent';
import WarningContent from './WarningContent';
import ValidationError from './ValidationError';
import { validateFile } from '../../../Validators/validateFile';


const ValidationDialog = ({ file, showDialog, closeDialog }) => {
  const [validating, setValidating] = React.useState(true);
  const [dataEntries, setDataEntries] = React.useState([]);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if(showDialog) {
      async function validate() {
        try {
        let invalidDataEntries = await validateFile(file);
        setDataEntries([...invalidDataEntries]);
        setValidating(false);
        setError(false);
        }
        catch(error) {
          setValidating(false);
          setError(true);
        }
      }
      validate();
    }
  },[file, showDialog]);

  const handleClose = () => {
    setDataEntries([]);
    closeDialog();
  }

  return (
    <Dialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <TranslationContent contentID="fileValidation" />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          color="primary"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{padding: 1, minHeight: "75vh"}}>
        <ContentManager error={error} dataEntries={dataEntries} validating={validating} />      
        </DialogContent>    
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} variant="contained">
            <TranslationContent contentID="done" />
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default ValidationDialog;


const ContentManager = ({ dataEntries, validating, error }) => {

  if(validating && !error) {
    return(
      <Box sx={{textAlign: "center", height: "60vh", alignContent: "center"}}>
        <FindInPageIcon 
          color="primary" 
          sx={{
            height: "150px",
            width: "150px",
            alignSelf: "center",
            animation: 'flash 2s infinite',
            '@keyframes flash': {
              '0%, 100%': { opacity: 1 },
              '50%': { opacity: 0.3 },
            },
          }}
        />
      </Box>
    )
  }
  
  if(!validating) {
    return(
      <>
        <ValidationError error={error} />
        <SuccessContent dataEntries={dataEntries} error={error} />
        <ErrorContent dataEntries={dataEntries} />
        <WarningContent dataEntries={dataEntries} />
        <div style={{height: "10px"}}/>
        <InfoContent error={error} />
      </>
    )
  }
}