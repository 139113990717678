import * as React from 'react';
import { Box, Stack, Collapse, Divider, Typography, IconButton } from '@mui/material';
import TranslationContent from '../../../Translations/TranslationContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ReportIcon from '@mui/icons-material/Report';
import HeaderMissingParts from './InfoTexts/HeaderMissingParts';
import HeaderTypeInvalid from './InfoTexts/HeaderTypeInvalid';
import HeaderIsEmpty from './InfoTexts/HeaderIsEmpty';
import HeaderFormatInvalid from './InfoTexts/HeaderFormatInvalid';
import HeaderNameTooShort from './InfoTexts/HeaderNameTooShort';
import HeaderCategoryTooShort from './InfoTexts/HeaderCategoryTooShort';
import HeaderDuplicate from './InfoTexts/HeaderDuplicate';
import HeaderNameShort from './InfoTexts/HeaderNameShort';
import HeaderCategoryShort from './InfoTexts/HeaderCategoryShort';
import EmptyCellValue from './InfoTexts/EmptyCellValue';
import InvalidString from './InfoTexts/InvalidString';
import InvalidNumber from './InfoTexts/InvalidNumber';
import InvalidDiscount from './InfoTexts/InvalidDiscount';
import InvalidBoolean from './InfoTexts/InvalidBoolean';
import InvalidDate from './InfoTexts/InvalidDate';
import NoMatchWithDataType from './InfoTexts/NoMatchWithDataType';


const InfoContent = ({ error }) => {
  let [showContent, setShowContent] = React.useState(false);
  let [showSection, setShowSection] = React.useState('');

  const toggleSection = (section) => {
    if(section === showSection) {
      setShowSection('');
    }
    else {
      setShowSection(section);
    }
  }

  if(error) {
    return null;
  }
  
  return(
    <Box sx={{ bgcolor: "#E3EAF3", border: "1px #668CBB solid", padding: "5px"}}>
      <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
        <InfoIcon sx={{color: "#668CBB" }}/>
        <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="guidelineToFixErrosAndWarnings" /></Typography>
        <IconButton
            aria-label="expand row"
            size="small"
            sx={{color: "#668CBB", marginRight: 0,marginLeft: "auto !important", display: "flex"}}
            onClick={() => setShowContent(!showContent)}
          >
            {showContent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
      </Stack>
      <Collapse in={showContent} timeout="auto" unmountOnExit>
        <Box p={1} pl={4}>

        <Stack direction="row" spacing={1}>
          <DangerousIcon sx={{color: "#FF1900" }}/>
          <Typography sx={{color: "black", mb: 1}} variant="body1"><b><TranslationContent contentID="errors" /></b></Typography>
          </Stack>
          <Typography sx={{color: "black", mt: 1}} variant="body2"><TranslationContent contentID="errorDesc" /></Typography>
          
          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerMissingLabelCategoryOrType" >
            <HeaderMissingParts />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerTypeInvalid" >
            <HeaderTypeInvalid />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerMissing" >
            <HeaderIsEmpty toggleSection={toggleSection} />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerFormatInvalid" >
            <HeaderFormatInvalid />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerNameTooShort" >
            <HeaderNameTooShort />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerCategoryTooShort" >
            <HeaderCategoryTooShort />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerConflict" >
            <HeaderDuplicate />
          </SectionContent>
          
          <SectionContent toggleSection={toggleSection} showSection={showSection} section="invalidString" >
            <InvalidString />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="invalidNumber" >
            <InvalidNumber />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="invalidDiscount" >
            <InvalidDiscount />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="invalidBoolean" >
            <InvalidBoolean />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="invalidDate" >
            <InvalidDate />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="noMatchWithDataType" >
            <NoMatchWithDataType />
          </SectionContent>

          <Divider sx={{bgcolor: "black", mt: 1, mb: 5}} />
          <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
            <ReportIcon color="error"/>
            <Typography sx={{color: "black", mb: 1}} variant="body1">
              <b><TranslationContent contentID="warnings" /></b>
            </Typography>
          </Stack>
          <Typography sx={{color: "black", mt: 1}} variant="body2"><TranslationContent contentID="warningsDesc" /></Typography>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerNameShort" >
            <HeaderNameShort />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="headerCategoryShort" >
            <HeaderCategoryShort />
          </SectionContent>

          <SectionContent toggleSection={toggleSection} showSection={showSection} section="emptyCellValue" >
            <EmptyCellValue />
          </SectionContent>
          <Divider sx={{bgcolor: "black", mt: 1, mb: 5}} />

        </Box>
      </Collapse>
    </Box>

  )
}

export default InfoContent;


const SectionContent = ({ children, toggleSection, showSection, section }) => {
  return(
    <>
      <Divider sx={{bgcolor: "black", mt: 1, mb: 2}}/>
      <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
        <Typography sx={{color: "black"}} variant="body2"><b><TranslationContent contentID={section} /></b></Typography>
        <IconButton
          aria-label="expand row"
          size="small"
          sx={{color: "#668CBB", marginRight: 0,marginLeft: "auto !important", display: "flex"}}
          onClick={() => toggleSection(section)}
        >
          {showSection === section ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
      <Collapse in={showSection === section} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
      <div style={{height: "10px"}}/>
    </>
  )
}