import { logger } from "../../services/logger";

export const createPieChartData = /*async*/ (chartData, stringFilter, numericFilter) => {
  logger("Starting to create pie chart data...");
  // Note to people: string and numeric filters input the filter objects objectKey for the function
  let pieChartData = [];

  pieChartData = chartData.map(data => {
    let pieChartDataObject = {
      id: data[stringFilter],
      label: data[stringFilter],
      value: data[numericFilter],
      data: {...data},
    }
    return pieChartDataObject;
  })

  logger(pieChartData);
  return pieChartData;
}