import React from "react";
import LandingHeader from "../Components/Headers/LandingHeader";
import GlobalFooter from "../Components/Footers/GlobalFooter";
import { Box, Container } from "@mui/material";


const LandingLayout = ({ children }) => {
  return(
    <>
      <LandingHeader />
      <Container>
        <Box>
          {children}
        </Box>
        <GlobalFooter />
      </Container>
    </>
  )
}

export default LandingLayout;