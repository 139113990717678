import { logger } from "../../services/logger"

export const discountCalculator = async ( data, discounts ) => {
  logger("Starting to calculate discounts for chart / table data...");
  
  let discountedData = [];

  try {
    let currentDiscountKey = discounts[0]?.key;

    if(!currentDiscountKey) {
      logger("No Discount Key provided, will return data as it is without calculating!");
      logger(console.trace());
      return data;
    }

    discountedData = data.map(dataEntry => {
      let discount = discounts.find(discountInList => discountInList.label === dataEntry[currentDiscountKey]);
      let dataEntryKeys = Object.keys(dataEntry);

      for(let i = 0; i < dataEntryKeys.length; i++) {
        let keyCategory = dataEntryKeys[i].split(':')[1];
        if(keyCategory === "price" && dataEntry[dataEntryKeys[i]] > 0 && dataEntryKeys[i].split(':')[2] !== "discount") {
          dataEntry[dataEntryKeys[i]] = parseFloat((dataEntry[dataEntryKeys[i]] * (1 - discount.discount / 100)).toFixed(2));
        }
      }
      return dataEntry;
    })

    logger("Discounts calculated:");
    logger(discountedData);
  }
  
  catch (error) {
    logger(error);
    logger(console.trace());
  }

  return discountedData;
}