import { logger } from "../../services/logger";

export const createFilters = async () => {
  logger("Starting to create filters...");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if(urlParams.has("filters")) {
    logger("Found shared filters!");

    let sharedFilters = urlParams.get("filters");
    sharedFilters = JSON.parse(sharedFilters);

    logger("Shared filters are:");
    logger(sharedFilters);
    
    return sharedFilters;
  }

  logger("No shared filters! Returning empty array");
  return [];
};