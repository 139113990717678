import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import { ViewportContext } from '../../Context/ViewportProvider';
import { ExportContext } from '../../Context/ExportProvider';
import { ThemeModeStateContext } from '../../Theme/ThemeProvider';


const PreviewPDF = ({ showPreview }) => {
  const { height } = React.useContext(ViewportContext);
  const theme = React.useContext(ThemeModeStateContext)
  const exportContent = React.useContext(ExportContext);
  
  if(showPreview && exportContent.length > 0) {
    return(
      <PDFViewer width={"100%"} height={height} >
        <PDFDocument exportContent={exportContent} theme={theme.theme} />
      </PDFViewer>
  )
  }
}

export default PreviewPDF;