import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SnackBarProvider } from './Context/SnackbarProvider';
import LanguageProvider from './Translations/LanguageProvider';
import ThemeModeProvider from './Theme/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import ViewportProvider from './Context/ViewportProvider';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './authConfig';
import ExportProvider from './Context/ExportProvider';
import SessionProvider from './Context/SessionProvider';


export const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ExportProvider>
      <ThemeModeProvider>
      <LanguageProvider>
        <CssBaseline />
        <SnackBarProvider>
          <ViewportProvider>
            <BrowserRouter>
              <SessionProvider>
                <App />
              </SessionProvider>
            </BrowserRouter>
          </ViewportProvider>
        </SnackBarProvider>
        </LanguageProvider>
      </ThemeModeProvider>
      </ExportProvider>
      </MsalProvider>
  </React.StrictMode>
);