import React from "react";
import MainHeader from "../Components/Headers/MainHeader";
import GlobalFooter from "../Components/Footers/GlobalFooter";
import { Box } from "@mui/material";


const GlobalLayout = ({ children }) => {
  return(
    <MainHeader>
      {children}
      <Box>
        <GlobalFooter />
      </Box>
    </MainHeader>
  )
}

export default GlobalLayout;