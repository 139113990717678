import React from "react";
import RoofingIcon from '@mui/icons-material/Roofing';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { Box } from "@mui/material";


const GroupIcon = ({ type }) => {
  switch(type) {
    case "roofing":
      return (
        <Box sx={{width: "20%"}}>
          <RoofingIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto" />
        </Box>
      )
    default:
      return (
        <Box sx={{width: "20%"}}>
          <SyncProblemIcon sx={{height: "60px", width: "60px"}} color="error" opacity={0.3} mr={0} ml="auto" />
        </Box>
      )
  }
}

export default GroupIcon;