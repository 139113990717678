import { logger } from "../../services/logger";

export const initializeFilters = async (filters) => {
  logger("Starting to initialize filters for chart...");

  let createdFilters = {
    stringFilters: [],
    numericFilters: [],
    booleanFilters: [],
    dateFilters: [],
  };

  if(filters.length < 1) {
    logger("0 filters, returning empty arrays");
    return createdFilters;
  }

  
  for (let i = 0; i < filters.length; i++) {
    const filterType = filters[i].objectKey.split(":")[2];

    switch(filterType) {
      case "string":
        if(filters[i].values.length > 0) {
          createdFilters.stringFilters.push({...filters[i]});
        }
        break;
      case "number":
        createdFilters.numericFilters.push({...filters[i]});
        break;
      case "discount":
        createdFilters.numericFilters.push({...filters[i]});
        break;
      case "boolean":
        createdFilters.booleanFilters.push({...filters[i]});
        break;
      case "date":
        createdFilters.dateFilters.push({...filters[i]});
        break;
      default:
        logger("Filter type didn't match with string, number, discount, boolean, or date.");
        break;
    }

  }

  logger("Filters created:");
  logger(createdFilters);

  return createdFilters;
}