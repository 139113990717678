import React from "react";
import RuukkiLogo from "../../../images/ruukki-header.png";
//import PiskoLogo from "../../../images/Piristeel-avainlippu-logo-300x104.png";

const CompanyLogo = ({ width, showAppLogo }) => {
  const domain = window.location.hostname;
  if(domain.includes("ruukki") && showAppLogo) {
    return(
      <img src={RuukkiLogo} alt="Ruukki's orange Logo with text Ruukki" style={{width: width, height:"auto", marginTop: "5px"}} />
    )
  }
  /*
  if (domain.includes("piristeel") && showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" />
    )
  }
  */
  if ((domain.includes("zealous") || domain.includes("agreeable")) && showAppLogo){
    return(
      <img src={RuukkiLogo} alt="Ruukki Company's orange logo with text Ruukki" width={width} height="auto" />
    )
  }
  if (domain.includes("localhost") && showAppLogo){
    return(
      <img src={RuukkiLogo} alt="Ruukki Company's orange logo with text Ruukki" style={{width: width, height:"auto", marginTop: "5px"}} />
    )
  }
  if(domain.includes("ruukki") && !showAppLogo) {
    return(
      <img src={RuukkiLogo} alt="Ruukki's orange Logo with text Ruukki" style={{marginRight: "20px", width: width, height:"auto", marginTop: "5px"}} />
    )
  }
  /*
  if (domain.includes("piristeel") && !showAppLogo){
    return(
      <img src={PiskoLogo} alt="Piristeel Company's blue logo with text Piristeel" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
  */
  if ((domain.includes("zealous") || domain.includes("agreeable")) && !showAppLogo){
    return(
      <img src={RuukkiLogo} alt="Ruukki Company's orange logo with text Ruukki" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
  if (domain.includes("localhost") && !showAppLogo){
    return(
      <img src={RuukkiLogo} alt="Ruukki Company's orange logo with text Ruukki" width={width} height="auto" style={{marginRight: "20px"}} />
    )
  }
}

export default CompanyLogo;