import React from "react"

export const ExportContext = React.createContext()
export const ExportDispatchContext = React.createContext()

const initialExportList = [];


function exportReducer(exportList, action) {
  switch(action.type) {
    case "UPDATE_EXPORT_LIST": {
      let exportListCopy = [...exportList];
      exportListCopy = action.value;
      //console.log(exportListCopy)

        return [
          ...exportListCopy,
        ]
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const ExportProvider = ({ children }) => {
  const [exportList, dispatch ] = React.useReducer(exportReducer, initialExportList)

  return(
    <ExportContext.Provider value={exportList}>
      <ExportDispatchContext.Provider value={dispatch}>
        {children}
      </ExportDispatchContext.Provider>
    </ExportContext.Provider>
  )
}

export default ExportProvider;
