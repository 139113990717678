import React from "react";
import { styled, useTheme } from '@mui/material/styles';
import { List, Typography, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Tooltip, Badge} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer';
import MenuIconHandler from "./MenuIconHandler";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslationContent from "../../Translations/TranslationContent";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useLocation } from "react-router-dom";
//import { SessionContext } from "../../Context/SessionProvider";
//import { DrawerHeader } from "./MainHeader";
import { DrawerHeader } from "./DrawerHeader";
import { ExportContext } from "../../Context/ExportProvider";



const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  //overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerDesktop = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, mobileOpen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...((open || mobileOpen) && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DesktopDrawer = ({ open, navigationList, handleLogout, handleNavigation, handleDrawerClose }) => {
  const theme = useTheme();
  const location = useLocation();
  const exportContent = React.useContext(ExportContext);

  return(
    <DrawerDesktop variant="permanent" open={open}>
      <DrawerHeader>
        <CompanyLogo width={150} showAppLogo={false}/>
        <IconButton onClick={handleDrawerClose} color="primary">
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navigationList.basic.map((navigationItem, index) => (
          <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
            <Tooltip title={!open ? <TranslationContent contentID={navigationItem.section} /> : ""} arrow placement="right">
              <ListItemButton
                onClick={() => handleNavigation(navigationItem.path)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  color="primary"
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <MenuIconHandler navigationItem={navigationItem} /> 
                </ListItemIcon>      
                <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: open ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : ""}} />
              </ListItemButton>
            </Tooltip>
          </ListItem> 
        ))}
      </List>    
      {navigationList.tools.some(entry => entry !== null) && 
        <>
          <Divider />
          <Collapse in={open}>
            <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="tools" /></b></Typography>
          </Collapse>
          <List>
            {navigationList.tools.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem + index} disablePadding sx={{ display: 'block', }} color="primary">
                <Tooltip title={!open ? <TranslationContent contentID={navigationItem.section} /> : ""} arrow placement="right">
                  <ListItemButton
                    onClick={() => handleNavigation(navigationItem.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      color="primary"
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIconHandler navigationItem={navigationItem} />
                      {navigationItem.section === "export" && !open && 
                        <Badge badgeContent={exportContent.length} variant="standard" color="primary" />
                      }
                    </ListItemIcon>
                    {navigationItem.section === "export" ? 
                      <ListItemText primary={<><TranslationContent contentID={navigationItem.section} /><Badge badgeContent={exportContent.length} variant="standard" color="primary" sx={{position: "relative", top: -2, right: -15}} /></>} sx={{ opacity: open ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : ""}} />
                    :
                      <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: open ? 1 : 0, color: location.pathname === navigationItem.path || (navigationItem.path.includes("compare") && location.pathname.includes("compare")) ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : ""}} />
                    }
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </>
      }
      {navigationList?.management?.some(entry => entry !== null) && 
        <>
          <Divider />
          <Collapse in={open}>
            <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="management" /></b></Typography>
          </Collapse>
          <List>
            {navigationList.management.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem + index} disablePadding sx={{ display: 'block', }} color="primary">
                <Tooltip title={!open ? <TranslationContent contentID={navigationItem.section} /> : ""} arrow placement="right">
                  <ListItemButton
                    onClick={() => handleNavigation(navigationItem.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      color="primary"
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIconHandler navigationItem={navigationItem} />
                    </ListItemIcon>
                      <ListItemText 
                        primary={
                          <TranslationContent contentID={navigationItem.section} />
                        } 
                        sx={{ 
                          opacity: open ? 1 : 0, 
                          color: location.pathname === navigationItem.path || 
                          (location.pathname.includes("data") && navigationItem.path.includes("data")) ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : ""}} />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </>
      }
      <Divider />
      <List>
        <ListItem key="logout-button-desktop" disablePadding sx={{ display: 'block', }}>
          <Tooltip title={!open ? <TranslationContent contentID="logout" /> : ""} arrow placement="right">
            <ListItemButton
              color="primary"
              onClick={handleLogout}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                color="primary"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={<TranslationContent contentID="logout" />} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </List>
    </DrawerDesktop>
  )
}

export default DesktopDrawer;