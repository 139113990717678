import React from "react";
import { ResponsiveBar } from '@nivo/bar';
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EmptyChart from "../EmptyChart";
import { patternLinesDef } from '@nivo/core'
import { darkTheme } from "../../../../Theme/ChartDarkTheme";
import { ligthTheme } from "../../../../Theme/ChartLightTheme";
import { ThemeModeStateContext } from "../../../../Theme/ThemeProvider";
import BarChartTooltip from "./BarChartTooltip";
import BarValueSettings from "./BarChartSettings/BarValueSettings";
import BarIdentifierSettings from "./BarChartSettings/BarIdentifierSettings";
import BarDimensionMarginSettings from "./BarChartSettings/BarDimensionMarginSettings";
import { useViewport } from "../../../../Context/ViewportProvider";
import BarLegendSettings from "./BarChartSettings/BarLegendSettings";
import BarAxisSettings from "./BarChartSettings/BarAxisSettings";
import BarInteractivitySettings from "./BarChartSettings/BarInteractivitySettings";
import BarChartScaleSettings from "./BarChartSettings/BarScaleSettings";
import BarStyleSettings from "./BarChartSettings/BarStyleSettings";
import BarLabelSettings from "./BarChartSettings/BarLabelSettings";
import { optimizeBarChartSettings } from "../../../../Optimization/ChartStyleOptimizations/optimizeBarChartSettings";


const BarChart = ({ chartData }) => {
  const { width, height } = useViewport();
  const [showRuukki, setShowRuukki] = React.useState(true);
  const currentTheme = React.useContext(ThemeModeStateContext);

  // Dimensions & margins
  const [chartTopMargin, setChartTopMargin] = React.useState(50);
  const [chartBottomMargin, setChartBottomMargin] = React.useState(50);
  const [chartRightMargin, setChartRightMargin] = React.useState(10);
  const [chartLeftMargin, setChartLeftMargin] = React.useState(50);
  const [chartWidth, setChartWidth] = React.useState(Math.floor(width * 0.9));
  const [chartHeight, setChartHeigth] = React.useState(500);

  // Legend settings
  const [legendSpacing, setLegendSpacing] = React.useState(5);
  const [legendWidth, setLegendWidth] = React.useState(150);
  const [legendHeight, setLegendHeigth] = React.useState(20);
  const [symbolSize, setSymbolSize] = React.useState(12);
  const [legendTranslateY, setLegendTranslateY] = React.useState(-50);
  const [legendTranslateX, setLegendTranslateX] = React.useState(0);
  const [showLegend, setShowLegend] = React.useState(true);
  const [justifyLegend, setJustifyLegend] = React.useState(false);
  const [legendDirection, setLegendDirection] = React.useState("left-to-right");
  const [legendPosition, setLegendPosition] = React.useState("top");
  const [legendLayout, setLegendLayout] = React.useState("row");
  const [containerWidth, setContainerWidth] = React.useState(100);
  const [containerHeight, setContainerHeight] = React.useState(500);

  // Axis settings
  const [showGridX, setShowGridX] = React.useState(false);
  const [showGridY, setShowGridY] = React.useState(true);
  const [showTopAxis, setShowTopAxis] = React.useState(false);
  const [showRightAxis, setShowRightAxis] = React.useState(false);
  const [showBottomAxis, setShowBottomAxis] = React.useState(true);
  const [showLeftAxis, setShowLeftAxis] = React.useState(true);
  const [topAxisLabel, setTopAxisLabel] = React.useState("");
  const [rightAxisLabel, setRightAxisLabel] = React.useState("");
  const [bottomAxisLabel, setBottomAxisLabel] = React.useState("");
  const [leftAxisLabel, setLeftAxisLabel] = React.useState("");

  const [topAxisTickSize, setTopAxisTickSize] = React.useState(5);
  const [topAxisTickPadding, setTopAxisTickPadding] = React.useState(5);
  const [topAxisTickRotation, setTopAxisTickRotation] = React.useState(0);
  const [topAxisTruncateTickAt, setTopAxisTruncateTickAt] = React.useState(0);
  const [topAxisLabelOffSet, setTopAxisLabelOffSet] = React.useState(36);

  const [rightAxisTickSize, setRightAxisTickSize] = React.useState(5);
  const [rightAxisTickPadding, setRightAxisTickPadding] = React.useState(5);
  const [rightAxisTickRotation, setRightAxisTickRotation] = React.useState(0);
  const [rightAxisTruncateTickAt, setRightAxisTruncateTickAt] = React.useState(0);
  const [rightAxisLabelOffSet, setRightAxisLabelOffSet] = React.useState(36);

  const [bottomAxisTickSize, setBottomAxisTickSize] = React.useState(5);
  const [bottomAxisTickPadding, setBottomAxisTickPadding] = React.useState(5);
  const [bottomAxisTickRotation, setBottomAxisTickRotation] = React.useState(0);
  const [bottomAxisTruncateTickAt, setBottomAxisTruncateTickAt] = React.useState(0);
  const [bottomAxisLabelOffSet, setBottomAxisLabelOffSet] = React.useState(36);

  const [leftAxisTickSize, setLeftAxisTickSize] = React.useState(5);
  const [leftAxisTickPadding, setLeftAxisTickPadding] = React.useState(5);
  const [leftAxisTickRotation, setLeftAxisTickRotation] = React.useState(0);
  const [leftAxisTruncateTickAt, setLeftAxisTruncateTickAt] = React.useState(0);
  const [leftAxisLabelOffSet, setLeftAxisLabelOffSet] = React.useState(-40);

  // Y scale
  const [valueScale, setValueScale] = React.useState("linear");
  const [minValue, setMinValue] = React.useState("auto");
  const [maxValue, setMaxValue] = React.useState("auto");

  // Labels
  const [enableLabel, setEnableLabel] = React.useState(true);
  const [labelSkipWidth, setLabelSkipWidth] = React.useState(0);
  const [labelSkipHeight, setLabelSkipHeight] = React.useState(0);
  const [enableTotals, setEnableTotals] = React.useState(false);
  const [totalsOffset, setTotalsOffset] = React.useState(10); 

  // styles
  const [chartColors, setChartColors] = React.useState("nivo");
  const [groupMode, setGroupMode] = React.useState("grouped"); // grouped or stacked
  const [barLayout, setBarLayout] = React.useState("vertical"); // horizontal or vertical
  const [reverse, setReverse] = React.useState(false);
  const [padding, setPadding] = React.useState(0.1);
  const [innerPadding, setInnerPadding] = React.useState(0);
  const [borderRadius, setBorderRadius] = React.useState(0);
  const [borderWidth, setBorderWidth] = React.useState(0);

  // Interactivity
  const [isInteractive, setIsInteractive] = React.useState(true);
  const [customTooltip, setCustomTooltip] = React.useState(true);

  // can this just be chartData.numericFilters ??
  //const [valueButtons, setValueButtons] = React.useState([]);
  const [selectedValueButtons, setSelectedValueButtons] = React.useState([]);
  const [identifier, setIdentifier] = React.useState("");

  const optimizeChartSettings = React.useRef(true);
  React.useEffect(() => {
    initializeBarChartData();

    if(optimizeChartSettings) {
      optimizeChartSettings.current = false;
      let optimizedSettings = optimizeBarChartSettings(width, height, chartData.filteredData.length);
      setContainerHeight(optimizedSettings.containerHeight);
      setContainerWidth(optimizedSettings.containerWidth);
      setChartHeigth(optimizedSettings.chartHeight);
      setChartWidth(optimizedSettings.chartWidth);
      setChartBottomMargin(optimizedSettings.marginBottom);
      setChartLeftMargin(optimizedSettings.marginLeft);
      setChartRightMargin(optimizedSettings.marginRight);
      setChartTopMargin(optimizedSettings.marginTop);
      setLegendLayout(optimizedSettings.legendLayout);
      setLegendPosition(optimizedSettings.legendPosition);
      setLegendTranslateY(optimizedSettings.legendTranslateY);
      setLegendTranslateX(optimizedSettings.legendTranslateX);
      setBarLayout(optimizedSettings.layout);
      setLeftAxisTickRotation(optimizedSettings.leftAxisTickRotation);
      setLeftAxisTruncateTickAt(optimizedSettings.leftAxisTruncateTickAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chartData]);

  const restoreDefaults = () => {
    let optimizedSettings = optimizeBarChartSettings(width, height, chartData.filteredData.length);
    setContainerHeight(optimizedSettings.containerHeight);
    setContainerWidth(optimizedSettings.containerWidth);
    setChartHeigth(optimizedSettings.chartHeight);
    setChartWidth(optimizedSettings.chartWidth);
    setChartBottomMargin(optimizedSettings.marginBottom);
    setChartLeftMargin(optimizedSettings.marginLeft);
    setChartRightMargin(optimizedSettings.marginRight);
    setChartTopMargin(optimizedSettings.marginTop);
    setLegendLayout(optimizedSettings.legendLayout);
    setLegendPosition(optimizedSettings.legendPosition);
    setLegendTranslateY(optimizedSettings.legendTranslateY);
    setLegendTranslateX(optimizedSettings.legendTranslateX);
    setBarLayout(optimizedSettings.layout);
    setLeftAxisTickRotation(optimizedSettings.leftAxisTickRotation);
    setLeftAxisTruncateTickAt(optimizedSettings.leftAxisTruncateTickAt);
  }

  const initializeBarChartData = () => {
    if(chartData.numericFilters.length === 0) {
      setSelectedValueButtons([]);
      return;
    }
    if(identifier === "") {
      setIdentifier(chartData.preferredIdentifier);
    }
    if(selectedValueButtons.length > 0) {
      let updatedSelectedValueButtons = selectedValueButtons.map(button => {
        if(chartData.numericFilters.some(filter => filter.objectKey === button)) {
          return button;
        }
        return null;
      })

      let filteredButtons = updatedSelectedValueButtons.filter(button => button !== null);
      if(filteredButtons.length === 0) {
        filteredButtons = chartData.numericFilters.map(filter => {
          return filter.objectKey;
        })
      }
      setSelectedValueButtons(filteredButtons)
    }
    if(selectedValueButtons.length === 0) {
      let updatedSelectedValueButtons = [...selectedValueButtons];
      updatedSelectedValueButtons = chartData.numericFilters.map(filter =>  filter.objectKey);
      setSelectedValueButtons(updatedSelectedValueButtons);
    }
  }

  const updateValueButtons = (value) => {
    if(selectedValueButtons.length === 1 && selectedValueButtons[0] === value) {
      return;
    }

    let updatedSelectedValueButtons = [...selectedValueButtons];

    if(updatedSelectedValueButtons.some(button => button === value)) {
      updatedSelectedValueButtons = updatedSelectedValueButtons.filter(button => button !== value);
    }
    else {
      updatedSelectedValueButtons = [...updatedSelectedValueButtons, chartData.numericFilters.find(filter => filter.objectKey === value).objectKey];
    }
    setSelectedValueButtons(updatedSelectedValueButtons);
  }
  
  // Not yet removed, leave here for possible future needs
  // Function not needed after adding axis and grid settings
  // Maybe add button to include legends to axis or something?
  // 30.6.2024 by SK
  /*
  const constructLegend = () => {
    let legend = "Legend not available";
    if(selectedValueButtons.length > 1) {
      selectedValueButtons.forEach((filter, index) => {
        if(index === 0) {
          legend = selectedValueButtons[0].split(":")[0];
        }
        else {
          legend = legend + " - " + filter.split(":")[0]
        }
      })
    }
    else {
      legend = selectedValueButtons[0].split(":")[0];
    }
    return legend;
  }
    */

  const updateDimensions = (value, type) => {
    switch(type) {
      case "chartWidth":
        if(value >= 0 && value <= width) {
          setChartWidth(value);
        }
        break;
      case "chartHeight":
        if(value >= 0 && value <= containerHeight) {
          setChartHeigth(value)
        }
        break;
      case "containerWidth":
        if(value >= 0 && value <= 100) {
          setContainerWidth(value);
        }
        break;
      case "containerHeight":
        if(value >= 0 && value <= 2000) {
          setContainerHeight(value)
        }
        break;
      default:
        break;
    }
  }

  const updateMargin = (value, type) => {
    switch(type) {
      case "top":
        if(value >= 0 && value <= containerHeight) {
          setChartTopMargin(Number(value));
        }
        break;
      case "bottom":
        if(value >= 0 && value <= containerHeight) {
          setChartBottomMargin(Number(value));
        }
        break;
      case "right":
        if(value >= 0 && value <= width) {
          setChartRightMargin(Number(value));
        }
        break;
      case "left":
        if(value >= 0 && value <= width) {
          setChartLeftMargin(Number(value));
        }
        break;
      default:
        break;
    }
  }

  if(selectedValueButtons.length < 1) return <EmptyChart />
  if(selectedValueButtons.length > 0) {
    return(
      <>
        <Grid xs={12} sx={{width: "100%"}}>
          <Box sx={{ width: `${containerWidth}%`, height: containerHeight }} id='comparison-chart-container'>
            <ResponsiveBar 
              theme={currentTheme.theme === "light" ? ligthTheme : darkTheme}
              data={[...new Set(chartData.filteredData)]} 
              keys={[...new Set(selectedValueButtons)]}
              indexBy={identifier}
              groupMode={groupMode}
              layout={barLayout}
              height={chartHeight}
              width={chartWidth}
              margin={{ top: chartTopMargin, right: chartRightMargin, bottom: chartBottomMargin, left: chartLeftMargin }}
              colors={{ scheme: chartColors }}
              padding={padding}
              innerPadding={innerPadding}
              borderRadius={borderRadius}
              borderWidth={borderWidth}
              reverse={reverse}
              valueScale={{ type: valueScale }}
              minValue={minValue}
              maxValue={maxValue}
              isInteractive={isInteractive}
              tooltip={
                customTooltip ? 
                  ({id, value, index, indexValue, data, label}) => 
                    <BarChartTooltip id={id} value={value} index={index} indexValue={indexValue} data={data} label={label} />
                : 
                  undefined  
              }
              //colors="#3182CE"
              animate={true}
              enableGridX={showGridX}
              enableGridY={showGridY}
              axisTop={
                showTopAxis ? 
                  {
                    tickSize: topAxisTickSize,
                    tickPadding: topAxisTickPadding,
                    tickRotation: topAxisTickRotation,
                    legend: topAxisLabel,
                    legendOffset: topAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: topAxisTruncateTickAt
                  } 
                : null
              }
              axisRight={
                showRightAxis ? 
                  {
                    tickSize: rightAxisTickSize,
                    tickPadding: rightAxisTickPadding,
                    tickRotation: rightAxisTickRotation,
                    legend: rightAxisLabel,
                    legendOffset: rightAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: rightAxisTruncateTickAt
                  } 
                : null
              }
              axisBottom={
                showBottomAxis ? 
                  {
                    tickSize: bottomAxisTickSize,
                    tickPadding: bottomAxisTickPadding,
                    tickRotation: bottomAxisTickRotation,
                    legend: bottomAxisLabel,
                    legendOffset: bottomAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: bottomAxisTruncateTickAt
                  }
                : null
              }
              axisLeft={
                showLeftAxis ? 
                  {
                    tickSize: leftAxisTickSize,
                    tickPadding: leftAxisTickPadding,
                    tickRotation: leftAxisTickRotation,
                    legend: leftAxisLabel,
                    legendOffset: leftAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: leftAxisTruncateTickAt
                  }
                : null
              }
              enableLabel={enableLabel}
              labelSkipWidth={labelSkipWidth}
              labelSkipHeight={labelSkipHeight}
              enableTotals={enableTotals}
              totalsOffset={totalsOffset}
              legends={showLegend ? [
                {
                  //dataFrom: 'keys',
                  //legend: (label => label.split(":")[0]),
                  //dataFrom: 'legendKeys',
                  /*
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: -50,
                  itemsSpacing: 2,
                  itemWidth: 250,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  */
                  anchor: legendPosition,
                  direction: legendLayout, // it can be row || column
                  justify: justifyLegend,
                  translateX: legendTranslateX,
                  translateY: legendTranslateY,
                  itemWidth: legendWidth,
                  itemHeight: legendHeight,
                  itemsSpacing: legendSpacing,
                  itemDirection: legendDirection,
                  symbolSize: symbolSize,
                  itemOpacity: 0.85,
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemOpacity: 1
                          }
                      }
                  ]
                    }
                ]
              : []}
              legendLabel={(legend) => (legend.id.split(":")[0])}
              defs={[
                patternLinesDef('lines-pattern', {
                  "spacing": 5,
                  "rotation": -137,
                  "lineWidth": 4,
                  //"background": "#ffffff",
                  "background": "#fc2516",
                  "color": "inherit",
                })
              ]}
              fill={[
                {match: d => (d.data?.data["Valmistaja:basic:string"]?.split(" ")[0] === "Ruukki" || d.data?.data["Marka:basic:string"]?.split(" ")[0] === "Ruukki") && showRuukki, id: "lines-pattern"}
              ]}
            />
          </Box> 
        </Grid>
        <BarValueSettings 
          numericFilters={chartData.numericFilters}
          selectedValueButtons={selectedValueButtons}
          updateValueButtons={updateValueButtons}
        />
        <BarIdentifierSettings
          labelButtons={chartData.stringFilters}
          selectedLabelButton={identifier}
          updateSelectedLabel={(value) => setIdentifier(value)}
          preferredIdentifier={chartData.preferredIdentifier}
        />
        <BarDimensionMarginSettings
          topMargin={chartTopMargin} 
          bottomMargin={chartBottomMargin}
          rightMargin={chartRightMargin}
          leftMargin={chartLeftMargin}
          updateMargin={updateMargin}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          updateDimensions={updateDimensions}
          restoreDefaults={restoreDefaults}
        />
        <BarLegendSettings
          showLegend={showLegend}
          setShowLegend={() => setShowLegend(!showLegend)}
          legendPosition={legendPosition}
          setLegendPosition={(value) => setLegendPosition(value)}
          legendLayout={legendLayout}
          setLegendLayout={(value) => setLegendLayout(value)}
          legendTranslateX={legendTranslateX}
          setLegendTranslateX={(value) => setLegendTranslateX(value)}
          legendTranslateY={legendTranslateY}
          setLegendTranslateY={(value) => setLegendTranslateY(value)}
          legendWidth={legendWidth}
          setLegendWidth={(value) => setLegendWidth(value)}
          legendHeight={legendHeight}
          setLegendHeigth={(value) => setLegendHeigth(value)}
          legendSpacing={legendSpacing}
          setLegendSpacing={(value) => setLegendSpacing(value)}
          symbolSize={symbolSize}
          setSymbolSize={(value) => setSymbolSize(value)}
          justifyLegend={justifyLegend}
          setJustifyLegend={() => setJustifyLegend(!justifyLegend)}
          legendDirection={legendDirection}
          setLegendDirection={(value) => setLegendDirection(value)}
        />
        <BarAxisSettings
          showGridX={showGridX}
          setShowGridX={() => setShowGridX(!showGridX)}
          showGridY={showGridY}
          setShowGridY={() => setShowGridY(!showGridY)}

          topAxisLabel={topAxisLabel}
          setTopAxisLabel={(value) => setTopAxisLabel(value)}
          rightAxisLabel={rightAxisLabel}
          setRightAxisLabel={(value) => setRightAxisLabel(value)}
          bottomAxisLabel={bottomAxisLabel}
          setBottomAxisLabel={(value) => setBottomAxisLabel(value)}
          leftAxisLabel={leftAxisLabel}
          setLeftAxisLabel={(value) => setLeftAxisLabel(value)}

          showTopAxis={showTopAxis}
          setShowTopAxis={() => setShowTopAxis(!showTopAxis)}
          showRightAxis={showRightAxis}
          setShowRightAxis={() => setShowRightAxis(!showRightAxis)}
          showBottomAxis={showBottomAxis}
          setShowBottomAxis={() => setShowBottomAxis(!showBottomAxis)}
          showLeftAxis={showLeftAxis}
          setShowLeftAxis={() => setShowLeftAxis(!showLeftAxis)}

          topAxisTickSize={topAxisTickSize}
          setTopAxisTickSize={(value) => setTopAxisTickSize(value)}
          topAxisTickPadding={topAxisTickPadding}
          setTopAxisTickPadding={(value) => setTopAxisTickPadding(value)}
          topAxisTickRotation={topAxisTickRotation}
          setTopAxisTickRotation={(value) => setTopAxisTickRotation(value)}
          topAxisTruncateTickAt={topAxisTruncateTickAt}
          setTopAxisTruncateTickAt={(value) => setTopAxisTruncateTickAt(value)}
          topAxisLabelOffSet={topAxisLabelOffSet}
          setTopAxisLabelOffSet={(value) => setTopAxisLabelOffSet(value)}

          rightAxisTickSize={rightAxisTickSize}
          setRightAxisTickSize={(value) => setRightAxisTickSize(value)}
          rightAxisTickPadding={rightAxisTickPadding}
          setRightAxisTickPadding={(value) => setRightAxisTickPadding(value)}
          rightAxisTickRotation={rightAxisTickRotation}
          setRightAxisTickRotation={(value) => setRightAxisTickRotation(value)}
          rightAxisTruncateTickAt={rightAxisTruncateTickAt}
          setRightAxisTruncateTickAt={(value) => setRightAxisTruncateTickAt(value)}
          rightAxisLabelOffSet={rightAxisLabelOffSet}
          setRightAxisLabelOffSet={(value) => setRightAxisLabelOffSet(value)}

          bottomAxisTickSize={bottomAxisTickSize}
          setBottomAxisTickSize={(value) => setBottomAxisTickSize(value)}
          bottomAxisTickPadding={bottomAxisTickPadding}
          setBottomAxisTickPadding={(value) => setBottomAxisTickPadding(value)}
          bottomAxisTickRotation={bottomAxisTickRotation}
          setBottomAxisTickRotation={(value) => setBottomAxisTickRotation(value)}
          bottomAxisTruncateTickAt={bottomAxisTruncateTickAt}
          setBottomAxisTruncateTickAt={(value) => setBottomAxisTruncateTickAt(value)}
          bottomAxisLabelOffSet={bottomAxisLabelOffSet}
          setBottomAxisLabelOffSet={(value) => setBottomAxisLabelOffSet(value)}

          leftAxisTickSize={leftAxisTickSize}
          setLeftAxisTickSize={(value) => setLeftAxisTickSize(value)}
          leftAxisTickPadding={leftAxisTickPadding}
          setLeftAxisTickPadding={(value) => setLeftAxisTickPadding(value)}
          leftAxisTickRotation={leftAxisTickRotation}
          setLeftAxisTickRotation={(value) => setLeftAxisTickRotation(value)}
          leftAxisTruncateTickAt={leftAxisTruncateTickAt}
          setLeftAxisTruncateTickAt={(value) => setLeftAxisTruncateTickAt(value)}
          leftAxisLabelOffSet={leftAxisLabelOffSet}
          setLeftAxisLabelOffSet={(value) => setLeftAxisLabelOffSet(value)}
        />
        <BarChartScaleSettings
          valueScale={valueScale}
          updateValueScale={(value) => setValueScale(value)}
          minValue={minValue}
          updateMinValue={(value) => setMinValue(value)}
          maxValue={maxValue}
          updateMaxValue={(value) => setMaxValue(value)}
          data={[...chartData.filteredData]}
          selectedValueButtons={selectedValueButtons}
        />
        <BarLabelSettings
          enableLabel={enableLabel}
          updateEnableLabel={() => setEnableLabel(!enableLabel)}
          labelSkipWidth={labelSkipWidth}
          updateLabelSkipWidth={value => setLabelSkipWidth(value)}
          labelSkipHeight={labelSkipHeight}
          updateLabelSkipHeight={value => setLabelSkipHeight(value)}
          enableTotals={enableTotals}
          updateEnableTotals={() => setEnableTotals(!enableTotals)}
          totalsOffset={totalsOffset}
          updateTotalsOffset={value => setTotalsOffset(value)}
        />
        <BarStyleSettings
          chartColors={chartColors}
          updateChartColors={(value) => setChartColors(value)}
          hightLightRuukki={showRuukki}
          updateHighlightRuukki={() => setShowRuukki(!showRuukki)}
          groupMode={groupMode}
          updateGroupMode={(value) => setGroupMode(value)}
          barLayout={barLayout}
          updateBarLayout={(value) => setBarLayout(value)}
          reverse={reverse}
          updateReverse={() => setReverse(!reverse)}
          padding={padding} 
          updatePadding={value => setPadding(value)}
          innerPadding={innerPadding}
          updateInnerPadding={value => setInnerPadding(value)}
          borderRadius={borderRadius}
          updateBorderRadius={value => setBorderRadius(value)}
          borderWidth={borderWidth}
          updateBorderWidth={value => setBorderWidth(value)}
        />
        <BarInteractivitySettings
          isInteractive={isInteractive}
          updateIsInteractive={() => setIsInteractive(!isInteractive)}
          customTooltip={customTooltip}
          updateCustomTooltip={() => setCustomTooltip(!customTooltip)}
        />
      </>
    )
  }
}

export default BarChart;