import { logger } from "../../services/logger";

export const optimizePieChartSettings = ( screenWidth, screenHeight, contentCount ) => {
  let optimizedSettings = {
    containerHeight: Math.floor(screenHeight * 0.5),
    containerWidth: 100,

    chartWidth: Math.floor(screenWidth * 0.90),
    chartHeight: Math.floor(screenHeight * 0.45),

    marginTop: 20,
    marginBottom: 20,
    marginLeft: 50,
    marginRight: 30,

    legendLayout: "column",
    legendPosition: "bottom-right",

    legendTranslateX: 200,
    legendTranslateY: 0,
  }

  // lets calculate every setting
  // This can and should be fulfilled more after proper testing (note by S 25.6.2024)
  if(screenWidth <= 1000) {
    optimizedSettings.legendLayout = "column";
    optimizedSettings.legendPosition = "top";
    optimizedSettings.marginTop = 50 + contentCount * 25;
    optimizedSettings.containerHeight = Math.floor(optimizedSettings.containerHeight + optimizedSettings.marginBottom);
    optimizedSettings.chartHeight = Math.floor(optimizedSettings.containerHeight * 0.9 - optimizedSettings.marginBottom);
    optimizedSettings.legendTranslateX = 0;
    optimizedSettings.legendTranslateY = -(optimizedSettings.marginTop);
  }
  if(screenWidth > 1000) {
    optimizedSettings.legendLayout = "column";
    optimizedSettings.legendPosition = "bottom-right";
    optimizedSettings.marginRight = Math.floor(optimizedSettings.chartWidth * 0.3);
    optimizedSettings.legendTranslateX = 200;
    optimizedSettings.legendTranslateY = 0;
    optimizedSettings.chartWidth = Math.floor(screenWidth - 256);
  }

  logger(optimizedSettings);

  return optimizedSettings;
}