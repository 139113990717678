import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Stack, Paper, Divider, Collapse } from "@mui/material";
import { useViewport } from "../../../../Context/ViewportProvider";
import BooleanValue from "../../../Utils/GlobalComponents/BooleanValue";
import AdsClickIcon from '@mui/icons-material/AdsClick';


const LineChartTooltip = ({ point, data, showExtra }) => {
  const { width } = useViewport();

  if(!point || !data || !showExtra) {
    return null;
  }
    
  const ValueTypography = ({value}) => {
    if(typeof value === "boolean") {
      return <BooleanValue value={value} />
    }
    if(value !== "No value" && value !== "Incorrect value type") {
      return(
        <Typography variant="caption" sx={{opacity: 0.7}}>{value}</Typography>
      )
    }
    if(value === "No value") {
      return(
        <Typography variant="caption" sx={{opacity: 0.5}}><i>{value}</i></Typography>
      )
    }
    if(value === "Incorrect value type") {
      return(
        <Typography  variant="caption" color="error">{value}</Typography>
      )
    }
    
  }

  return(
    
      <Paper sx={{padding: "10px", maxWidth: width < 500 ? "60vw" : "500px" }}> 
        <Stack direction="row">
        <Typography variant="body2" sx={{color: point.serieColor}}><b>{point.serieId}</b></Typography>
      
        <Stack sx={{alignItems: "center", verticalAlign: "middle", marginLeft: "50px", marginRight: 0, display: "flex"}} direction="row" spacing={1}>
        </Stack>
        <AdsClickIcon 
          fontSize="small" 
          sx={{ 
            marginLeft: 'auto',
            color: point.serieColor,
            '@keyframes growShrink': {
              '0%, 100%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(1.2)' },
            },
            animation: point.serieId !== showExtra && 'growShrink 1.5s infinite ease-in-out',
            opacity: point.serieId === showExtra && 0.5
          }}
        />
        </Stack>
      <Stack direction="row" mb={1} sx={{alignItems: "center", width: "100%"}} spacing={1}>
        <div style={{height: "10px", width: "10px", background: point.serieColor}}/>
        <Typography sx={{color: point.serieColor}} variant="caption">x: <b>{point.data.x}</b></Typography>
        <Typography sx={{color: point.serieColor}} variant="caption">y: <b>{point.data.y}</b></Typography>
        <div style={{marginLeft: "auto"}} />
        </Stack>
        <Collapse in={showExtra === point.serieId}>
          <Divider />
        <Grid container spacing={0} mt={1} mb={1}>
          {data.data.map((entry, index) => 
          point.data.x !== entry.x &&
             <Grid xs={12} sm={6} md={4} key={entry.x + entry.y + index}>
              <Stack direction="column">
                <Typography sx={{color: point.data.x === entry.x ? "green" : ""}} variant="caption"><b>{entry.x}</b></Typography>
                <ValueTypography value={entry.y} />
              </Stack>
              </Grid>
          )}
          <Grid xs={12} mb={1} mt={1}><Divider /></Grid>
          {Object.keys(data.otherData).map(key => 
            <Grid xs={12} sm={6} md={4} key={key}>
              <Stack direction="column">
                <Typography variant="caption"><b>{key.split(":")[0]}</b></Typography>
                <ValueTypography value={data.otherData[key]} />
              </Stack>
            </Grid>
          )}
        </Grid>
        </Collapse>
      </Paper>
  )
};

export default LineChartTooltip;