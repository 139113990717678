import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;


const getData = async (id, accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/compare/${id}`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const getGroups = async (accessToken, idToken) => {
  const request = axios.get(`${baseUrl}/compare`, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const updateData = async (id, data, accessToken, idToken) => {
  const request = axios.put(`${baseUrl}/compare/${id}`, data, { headers: { Authorization: "Bearer " + accessToken, "x-id-token": "Bearer " + idToken, "x-api-key": apiKey } });

  return request.then(response => response.data);
}

const compareServices = { getData, getGroups, updateData };
export default compareServices;