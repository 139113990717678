import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SettingDivider from "../../Settings/SettingDivider";
import { Typography, Stack, Collapse } from "@mui/material";
import TranslationContent from "../../../../../Translations/TranslationContent";
import SwitchSetting from "../../Settings/SwitchSetting";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SliderSetting from "../../Settings/SliderSetting";
import RadioSetting from "../../Settings/RadioSetting";


const LineChartScaleSettings = 
  ({ 
    yScaleStacked,
    setScaleStacked,
    yScaleReverse,
    setYScaleReverse,
    yScaleMin,
    setYScaleMin,
    yScaleMax,
    setYScaleMax,
    yScaleType,
    setYScaleType,
    yData
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  
  // yMin and yMax in state so that controlled slider would always have number state
  // Chart itself uses the yScaleMin and yScaleMax values that can be string "auto" or number
  const [yMin, setYMin] = React.useState(0);
  const [yMax, setYMax] = React.useState(0);

  // calculated range of values
  const [minYScaleOptionValue, setMinYScaleOptionValue] = React.useState(0);
  const [maxYScaleOptionValue, setMaxYScaleOptionValue] = React.useState(0);
  const { width } = useViewport();

  React.useEffect(() => {
    calculateHighestValue(yData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const calculateHighestValue = (lineData) => {
    let allValues = [];

    for(let i = 0; i < lineData.length; i++) {
      for(let x = 0; x < lineData[i].data.length; x++) {
        allValues.push(Math.round(lineData[i].data[x].y));
      }
    }

    let largestNumber = Math.max(...allValues);
    setYMax(largestNumber);

    let smallestNumber = Math.min(...allValues);
    if(smallestNumber < 0) {
      setYMin(smallestNumber);
    }
    else {
      setYMin(0);
    }

    let maxOptionValue = Math.round(largestNumber * 1.2)
    let minOptionValue = -(maxOptionValue);

    setMaxYScaleOptionValue(maxOptionValue);
    setMinYScaleOptionValue(minOptionValue);
  }

  
  const updateYMin = () => {
    calculateHighestValue(yData)
    if(yScaleMin === "auto") {
      setYScaleMin(minYScaleOptionValue);
    }
    else {
      setYScaleMin("auto");
    }
  }

  const updateYMax = () => {
    calculateHighestValue(yData)
    if(yScaleMax === "auto") {
      setYScaleMax(maxYScaleOptionValue);
    }
    else {
      setYScaleMax("auto");
    }
  }

  const updateYMinNumber = (value) => {
    setYScaleMin(value);
    setYMin(value);
  }

  const updateYMaxNumber = (value) => {
    setYScaleMax(value);
    setYMax(value);
  }

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="scales">
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="yScale" /></b></Typography>
        <SwitchSetting 
          label="stacked"
          value={yScaleStacked}
          handleChange={setScaleStacked}  
        />
        <SwitchSetting 
          label="reverse"
          value={yScaleReverse}
          handleChange={setYScaleReverse}  
        />
        <RadioSetting 
          label="typeSetting"
          value={yScaleType}
          handleChange={(value) => {setYScaleType(value)}}
          optionKey="yScaleTypeOptions"
        />
      </Stack>
      <SettingDivider />

      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="yMin" /></b></Typography>
        <SwitchSetting 
          label="auto"
          value={yScaleMin === "auto"}
          handleChange={updateYMin}  
        />
      </Stack>
      <Collapse in={yScaleMin !== "auto"}>
        <SliderSetting 
          label="number"
          icon="min"
          value={yMin}
          updateValue={(value) => updateYMinNumber(value)}
          minValue={minYScaleOptionValue}
          maxValue={yMax}
        />
        </Collapse>

      <SettingDivider />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="yMax" /></b></Typography>
        <SwitchSetting 
          label="auto"
          value={yScaleMax === "auto"}
          handleChange={updateYMax}  
        />
      </Stack>
      <Collapse in={yScaleMax !== "auto"}>
        <SliderSetting 
          label="number"
          icon="max"
          value={yMax}
          updateValue={(value) => updateYMaxNumber(value)}
          minValue={yMin}
          maxValue={maxYScaleOptionValue}
        />
        </Collapse>

    </SettingContainer>
  )
}

export default LineChartScaleSettings;