import React from "react";
import TranslationContent from "../../../../Translations/TranslationContent";
import { Typography, FormControlLabel, Switch, FormGroup } from "@mui/material";


const SwitchSetting = ({ label, value, handleChange, disabled }) => {
  return(
    <FormGroup aria-label="position" row >
      <FormControlLabel
        sx={{ml: 1}}
        size="small"
        value={value}
        disableTypography
        disabled={disabled || false}
        control={
          <Switch 
            size="small"
            color="primary"
            onChange={() => handleChange(!value)}
            checked={value}
          />
        }
        label={<Typography variant="caption" sx={{opacity: 0.7, p: 0, m: 0, mt: -0.2}}><TranslationContent contentID={label} /></Typography>}
        labelPlacement="top"
      />
    </FormGroup>
  )
}

export default SwitchSetting;