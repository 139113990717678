import React from "react";
import AddchartIcon from '@mui/icons-material/Addchart';
import { Typography } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";
import Grid from "@mui/material/Unstable_Grid2/Grid2";


const EmptyChart = () => {
  return(
    <Grid sx={{width: "100%", textAlign: "center", margin: 5}}>
      <AddchartIcon fontSize="large" color="primary" sx={{opacity: 0.5}} />
      <Typography sx={{opacity: 0.5}}><TranslationContent contentID="emptyChart1" /> <b><TranslationContent contentID="emptyChart2" /></b> <TranslationContent contentID="emptyChart3" /></Typography>
    </Grid>
  )
}

export default EmptyChart;