import React from "react";
import { Box, Button, TextField, Stack, Typography, IconButton, Collapse } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import { ExportContext, ExportDispatchContext } from "../../Context/ExportProvider";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import TranslationContent from "../../Translations/TranslationContent";


const ExportContent = ({ activeBottomNavigation, mode }) => {
  const exportContent = React.useContext(ExportContext);
  const updateExportContent = React.useContext(ExportDispatchContext);
  const [animationParent] = useAutoAnimate()

  const handleDeleteClick = ( index ) => {
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: [
      ...exportContent.slice(0,index),
      ...exportContent.slice(index+1)
    ]})
  }
  const handleTypeChange = ( index, type ) => {
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: 
      exportContent.map((content, contentIndex) => {
        if(contentIndex === index) {
          content.type = type;
          return content;
        }
        else { return content;}
      })
    })
  }

  const upgradeIndex = (index) => {
    if(exportContent.length > 1 && index + 1 < exportContent.length) {
      let updatedExportContent = [...exportContent];
      let itemToMove = updatedExportContent[index];
      updatedExportContent.splice(index, 1);
      updatedExportContent.splice(index + 1, 0, itemToMove);
      updateExportContent({type: "UPDATE_EXPORT_LIST", value: updatedExportContent});
    }
  }
  const downgradeIndex = (index) => {
    if(exportContent.length > 1 && index > 0) {
      let updatedExportContent = [...exportContent];
      let itemToMove = updatedExportContent[index];
      updatedExportContent.splice(index, 1);
      updatedExportContent.splice(index - 1, 0, itemToMove);
      updateExportContent({type: "UPDATE_EXPORT_LIST", value: updatedExportContent});
    }
  }

  const handleTextInputChange = (event, index) => {
    let updatedExportContent = [...exportContent];
    updatedExportContent[index].content = event.target.value;
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: updatedExportContent});
  }

  if(exportContent.length > 0 && mode === "tool") {
    return(
      <Box sx={{width: "100%"}} ref={animationParent}>
        {exportContent.map((content, index) => 
          <ListItemManager 
            key={content.type + index} 
            content={content} 
            index={index} 
            handleDeleteClick={handleDeleteClick} 
            handleTypeChange={handleTypeChange} 
            activeBottomNavigation={activeBottomNavigation} 
            upgradeIndex={upgradeIndex} 
            downgradeIndex={downgradeIndex} 
            handleTextInputChange={handleTextInputChange} 
            exportContent={exportContent}
          />
        )}
      </Box>
    )
  }
}

export default ExportContent;


const ListItemManager = ({ content, index, handleDeleteClick, exportContent, handleTypeChange, activeBottomNavigation, upgradeIndex, downgradeIndex, handleTextInputChange }) => {

  if(content.type === "") {
    return(
      <Box sx={{margin: "25px", padding: "15px", textAlign: "center", border: "1px solid"}}>
        <Typography><TranslationContent contentID="selectType" />:</Typography>
        <Button variant="contained" onClick={() => handleTypeChange(index, "header")}><TranslationContent contentID="header" /></Button>&nbsp;&nbsp;
        <Button variant="contained" onClick={() => handleTypeChange(index, "text")}><TranslationContent contentID="text" /></Button>&nbsp;&nbsp;
        <Button variant="outlined" onClick={() => handleDeleteClick(index)}><TranslationContent contentID="cancel" /></Button>
      </Box>
    )
  }
  if(content.type === "header") {
    return(
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Collapse orientation="horizontal" in={activeBottomNavigation === "order"} sx={{textAlign: "-webkit-center"}}>
      <Stack sx={{width: "5%"}}>
        <IconButton onClick={() => downgradeIndex(index)} disabled={index === 0} color="primary"><ArrowDropUpIcon fontSize="large"/></IconButton>
        <IconButton onClick={() => upgradeIndex(index)} disabled={index + 1 >= exportContent.length} color="primary"><ArrowDropDownIcon fontSize="large"/></IconButton>
      </Stack>
      </Collapse>
      <Box sx={{margin: "25px", padding: "15px", textAlign: "center", width: "90%", borderLeft: "2px solid #fc2516"}}>
        <TextField fullWidth label={<TranslationContent contentID="header" />} id="outlined-size-normal" variant="standard" value={content.content} size="large" onChange={(event) => handleTextInputChange(event, index)}/>
      </Box>
      <IconButton onClick={() => handleDeleteClick(index)}><DeleteIcon color="error" fontSize="large"/></IconButton>
      </Box>
    )
  }
  if(content.type === "text") {
    return(
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Collapse orientation="horizontal" in={activeBottomNavigation === "order"} sx={{textAlign: "-webkit-center",}}>
      <Stack sx={{width: "5%"}}>
        <IconButton onClick={() => downgradeIndex(index)} disabled={index === 0} color="primary"><ArrowDropUpIcon fontSize="large"/></IconButton>
        <IconButton onClick={() => upgradeIndex(index)} disabled={index + 1 >= exportContent.length} color="primary"><ArrowDropDownIcon fontSize="large"/></IconButton>
      </Stack>
      </Collapse>
    <Box sx={{margin: "25px", padding: "15px", textAlign: "center", width: "90%", borderLeft: "2px solid #fc2516"}}>
      <TextField fullWidth label={<TranslationContent contentID="text" />} id="outlined-size-normal" multiline variant="standard" value={content.content} size="small" onChange={(event) => handleTextInputChange(event, index)} />
      </Box>
      <IconButton onClick={() => handleDeleteClick(index)}><DeleteIcon color="error" fontSize="large"/></IconButton>
      </Box>
    )
  }
  if(content.type === "chart" || content.type === "table") {
    return(
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Collapse orientation="horizontal" in={activeBottomNavigation === "order"} sx={{textAlign: "-webkit-center",}}>
          <Stack sx={{width: "5%"}}>
            <IconButton onClick={() => downgradeIndex(index)} disabled={index === 0} color="primary"><ArrowDropUpIcon fontSize="large"/></IconButton>
            <IconButton onClick={() => upgradeIndex(index)} disabled={index + 1 >= exportContent.length} color="primary"><ArrowDropDownIcon fontSize="large"/></IconButton>
          </Stack>
        </Collapse>
        <Box sx={{margin: "25px", padding: "15px", textAlign: "center", width: "90%", borderLeft: "2px solid #fc2516"}}>
          <img src={content.content} alt="Chart content" style={{width: "-webkit-fill-available", maxWidth: "90%"}}/>
        </Box>
        <IconButton onClick={() => handleDeleteClick(index)}><DeleteIcon color="error" fontSize="large"/></IconButton>
      </Box>
    )
  }
}