import React from "react"
import { ResponsiveLine } from '@nivo/line';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/material";
import { ThemeModeStateContext } from "../../../../Theme/ThemeProvider";
import { lightLineTheme, darkLineTheme } from "../../../../Theme/ChartThemes/lineChartThemes";
import LineChartTooltip from "./LineChartTooltip";
import { createLineChartData } from "../../../../DataFactory/ChartData/createLineChartData";
import EmptyChart from "../EmptyChart";
import { useViewport } from "../../../../Context/ViewportProvider";
import LineLegendSettings from "./LineChartSettings/LineLegendSettings";
import LineAxisSettings from "./LineChartSettings/LineAxisSettings";
import LineDimensionMarginSettings from "./LineChartSettings/LineDimensionMarginSettings";
import LineValueSettings from "./LineChartSettings/LineValueSettings";
import LineIdentifierSettings from "./LineChartSettings/LineIdentifierSettings";
import LineScaleSettings from "./LineChartSettings/LineScaleSettings";
import LineStyleSettings from "./LineChartSettings/LineStyleSettings";
import LineInteractivitySettings from "./LineChartSettings/LineInteractivitySettings";
import { optimizeLineChartSettings } from "../../../../Optimization/ChartStyleOptimizations/optimizeLineChartSettings";


const LineChart = ({ chartData }) => {
  const currentTheme = React.useContext(ThemeModeStateContext);
  const { width, height } = useViewport();

  // Legend settings
  const [legendSpacing, setLegendSpacing] = React.useState(5);
  const [legendWidth, setLegendWidth] = React.useState(150);
  const [legendHeight, setLegendHeigth] = React.useState(20);
  const [symbolSize, setSymbolSize] = React.useState(12);
  const [legendTranslateY, setLegendTranslateY] = React.useState(0);
  const [legendTranslateX, setLegendTranslateX] = React.useState(200);
  const [showLegend, setShowLegend] = React.useState(true);
  const [justifyLegend, setJustifyLegend] = React.useState(false);
  const [legendDirection, setLegendDirection] = React.useState("left-to-right");
  const [legendPosition, setLegendPosition] = React.useState("bottom-right");
  const [legendLayout, setLegendLayout] = React.useState("column");

  // Axis settings
  const [showGridX, setShowGridX] = React.useState(true);
  const [showGridY, setShowGridY] = React.useState(true);
  const [showTopAxis, setShowTopAxis] = React.useState(false);
  const [showRightAxis, setShowRightAxis] = React.useState(false);
  const [showBottomAxis, setShowBottomAxis] = React.useState(true);
  const [showLeftAxis, setShowLeftAxis] = React.useState(true);
  const [topAxisLabel, setTopAxisLabel] = React.useState("");
  const [rightAxisLabel, setRightAxisLabel] = React.useState("");
  const [bottomAxisLabel, setBottomAxisLabel] = React.useState("");
  const [leftAxisLabel, setLeftAxisLabel] = React.useState("");

  const [topAxisTickSize, setTopAxisTickSize] = React.useState(5);
  const [topAxisTickPadding, setTopAxisTickPadding] = React.useState(5);
  const [topAxisTickRotation, setTopAxisTickRotation] = React.useState(0);
  const [topAxisTruncateTickAt, setTopAxisTruncateTickAt] = React.useState(0);
  const [topAxisLabelOffSet, setTopAxisLabelOffSet] = React.useState(36);

  const [rightAxisTickSize, setRightAxisTickSize] = React.useState(5);
  const [rightAxisTickPadding, setRightAxisTickPadding] = React.useState(5);
  const [rightAxisTickRotation, setRightAxisTickRotation] = React.useState(0);
  const [rightAxisTruncateTickAt, setRightAxisTruncateTickAt] = React.useState(0);
  const [rightAxisLabelOffSet, setRightAxisLabelOffSet] = React.useState(36);

  const [bottomAxisTickSize, setBottomAxisTickSize] = React.useState(5);
  const [bottomAxisTickPadding, setBottomAxisTickPadding] = React.useState(5);
  const [bottomAxisTickRotation, setBottomAxisTickRotation] = React.useState(0);
  const [bottomAxisTruncateTickAt, setBottomAxisTruncateTickAt] = React.useState(0);
  const [bottomAxisLabelOffSet, setBottomAxisLabelOffSet] = React.useState(36);

  const [leftAxisTickSize, setLeftAxisTickSize] = React.useState(5);
  const [leftAxisTickPadding, setLeftAxisTickPadding] = React.useState(5);
  const [leftAxisTickRotation, setLeftAxisTickRotation] = React.useState(0);
  const [leftAxisTruncateTickAt, setLeftAxisTruncateTickAt] = React.useState(0);
  const [leftAxisLabelOffSet, setLeftAxisLabelOffSet] = React.useState(-40);

  const [chartTopMargin, setChartTopMargin] = React.useState(50);
  const [chartBottomMargin, setChartBottomMargin] = React.useState(50);
  const [chartRightMargin, setChartRightMargin] = React.useState(200);
  const [chartLeftMargin, setChartLeftMargin] = React.useState(50);
  const [chartWidth, setChartWidth] = React.useState(Math.floor(width * 0.7));
  const [chartHeight, setChartHeigth] = React.useState(500);

  const [containerWidth, setContainerWidth] = React.useState(100);
  const [containerHeight, setContainerHeight] = React.useState(500);

  // Y scale
  const [yScaleStacked, setScaleStacked] = React.useState(false);
  const [yScaleReverse, setYScaleReverse] = React.useState(false);
  const [yScaleMin, setYScaleMin] = React.useState("auto");
  const [yScaleMax, setYScaleMax] = React.useState("auto");
  const [yScaleType, setYScaleType] = React.useState("linear");

  // Style
  const [curve, setCurve] = React.useState("linear");
  const [curveThickness, setCurveThickness] = React.useState(2);
  const [chartColors, setChartColors] = React.useState("nivo");
  const [enablePoints, setEnablePoints] = React.useState(true);
  const [pointSize, setPointSize] = React.useState(6);
  const [enablePointLabels, setEnablePointLabels] = React.useState(false);
  const [pointLabelYOffset, setPointLabelYOffset] = React.useState(-12);
  const [enableArea, setEnableArea] = React.useState(false);
  const [areaBaselineValue, setAreaBaselineValue] = React.useState(0);
  const [areaOpacity, setAreaOpacity] = React.useState(0.2);

  // Interactivity
  const [isInteractive, setIsInteractive] = React.useState(true);
  const [useMesh, setUseMesh] = React.useState(true);
  const [debugMesh, setDebugMesh] = React.useState(false);
  const [debugSlices, setDebugSlices] = React.useState(false);
  const [enableSlices, setEnableSlices] = React.useState("x");
  const [enableCrosshair, setEnableCrosshair] = React.useState(true);
  const [enableTouchCrosshair, setEnableTouchCrosshair] = React.useState(false);
  const [crosshairType, setCrosshairType] = React.useState("bottom-left");

  const [lineData, setLineData] = React.useState([/* {id: "", data: [{x: "", y: 0}]} */])
  const [valueButtons, setValueButtons] = React.useState([]);
  const [labelButtons, setLabelButtons] = React.useState([]);
  const [selectedLabelButton, setSelectedLabelButton] = React.useState("");
  const [showExtra, setShowExtra] = React.useState("");

  const optimizeChartSettings = React.useRef(true);
  React.useEffect(() => {
    let count = 0;
    //if(chartData.filteredData.length > 0 && chartData.stringFilters.length > 0 && chartData.numericFilters.length > 0) {
      let newLineData = createLineChartData(chartData.filteredData, chartData.preferredIdentifier, chartData.numericFilters);
      count = newLineData.length;
      setLineData(newLineData);
      setValueButtons([...chartData.numericFilters]);
      setLabelButtons([...chartData.stringFilters]);
      setSelectedLabelButton(chartData.stringFilters[0].objectKey);
    //}
    if(selectedLabelButton === "") {
      setSelectedLabelButton(chartData.preferredIdentifier);
    }
    if(optimizeChartSettings) {
      optimizeChartSettings.current = false;
      let optimizedSettings = optimizeLineChartSettings(width, height, count);
      setContainerHeight(optimizedSettings.containerHeight);
      setContainerWidth(optimizedSettings.containerWidth);
      setChartHeigth(optimizedSettings.chartHeight);
      setChartWidth(optimizedSettings.chartWidth);
      setChartBottomMargin(optimizedSettings.marginBottom);
      setChartLeftMargin(optimizedSettings.marginLeft);
      setChartRightMargin(optimizedSettings.marginRight);
      setChartTopMargin(optimizedSettings.marginTop);
      setLegendLayout(optimizedSettings.legendLayout);
      setLegendPosition(optimizedSettings.legendPosition);
      setLegendTranslateY(optimizedSettings.legendTranslateY);
      setLegendTranslateX(optimizedSettings.legendTranslateX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chartData]);
  

  const updateDimensions = (value, type) => {
    switch(type) {
      case "chartWidth":
        if(value >= 0 && value <= width) {
          setChartWidth(value);
        }
        break;
      case "chartHeight":
        if(value >= 0 && value <= containerHeight) {
          setChartHeigth(value)
        }
        break;
      case "containerWidth":
        if(value >= 0 && value <= 100) {
          setContainerWidth(value);
        }
        break;
      case "containerHeight":
        if(value >= 0 && value <= 2000) {
          setContainerHeight(value)
        }
        break;
      default:
        break;
    }
  }

  const restoreDefaults = () => {
    let optimizedSettings = optimizeLineChartSettings(width, height, lineData.length);
    setContainerHeight(optimizedSettings.containerHeight);
    setContainerWidth(optimizedSettings.containerWidth);
    setChartHeigth(optimizedSettings.chartHeight);
    setChartWidth(optimizedSettings.chartWidth);
    setChartBottomMargin(optimizedSettings.marginBottom);
    setChartLeftMargin(optimizedSettings.marginLeft);
    setChartRightMargin(optimizedSettings.marginRight);
    setChartTopMargin(optimizedSettings.marginTop);
    setLegendLayout(optimizedSettings.legendLayout);
    setLegendPosition(optimizedSettings.legendPosition);
    setLegendTranslateY(optimizedSettings.legendTranslateY);
    setLegendTranslateX(optimizedSettings.legendTranslateX);
  }

  const updateMargin = (value, type) => {
    switch(type) {
      case "top":
        if(value >= 0 && value <= containerHeight) {
          setChartTopMargin(Number(value));
        }
        break;
      case "bottom":
        if(value >= 0 && value <= containerHeight) {
          setChartBottomMargin(Number(value));
        }
        break;
      case "right":
        if(value >= 0 && value <= width) {
          setChartRightMargin(Number(value));
        }
        break;
      case "left":
        if(value >= 0 && value <= width) {
          setChartLeftMargin(Number(value));
        }
        break;
      default:
        break;
    }
  }

  const updateLineValues = (value) => {
    if(valueButtons.length === 1 && valueButtons[0].objectKey === value) {
      return;
    }

    let updatedValueButtons = [...valueButtons];

    if(updatedValueButtons.some(button => button.objectKey === value)) {
      updatedValueButtons = updatedValueButtons.filter(button => button.objectKey !== value);
    }
    else {
      updatedValueButtons = [...updatedValueButtons, {...chartData.numericFilters.find(filter => filter.objectKey === value)}];
    }

    let newLineData = [...lineData];
    newLineData = createLineChartData(chartData.filteredData, selectedLabelButton, updatedValueButtons);
    setLineData(newLineData);
    setValueButtons(updatedValueButtons);
  }

  const updateSelectedLabel = (value) => {
    setSelectedLabelButton(value);
    let newLineData = [...lineData]
    newLineData = createLineChartData(chartData.filteredData, value, valueButtons);
    setLineData(newLineData);
  }

  const updateShowExtra = (value) => {
    if(value === showExtra) {
      setShowExtra("")
      return;
    }
    setShowExtra(value);
  }

  if(lineData.length < 1 || valueButtons.length < 1 || labelButtons.length < 1) return <EmptyChart />
  if(lineData.length > 0 && valueButtons.length > 0 && labelButtons.length > 0) {
    return(
      <>
        <Grid xs={12} sx={{width: "100%"}}>
          <Box sx={{ width: `${containerWidth}%`, height: containerHeight }} id='comparison-chart-container'>
            <ResponsiveLine
              height={chartHeight}
              width={chartWidth}
              data={lineData}
              theme={currentTheme.theme === "light" ? lightLineTheme : darkLineTheme}
              enableGridX={showGridX}
              enableGridY={showGridY}
              tooltip={({ point }) => {
                if(!enableSlices) {
                  const seriesData = lineData?.find(series => series?.id === point?.serieId);
                  return <LineChartTooltip point={point} data={seriesData} showExtra={showExtra} /> || null
                }
              }}
              onClick={(point, event) => updateShowExtra(point.serieId)}
              margin={{ top: chartTopMargin, right: chartRightMargin, bottom: chartBottomMargin, left: chartLeftMargin }}
              xScale={{ type: 'point' }}
              yScale={{
                type: yScaleType,
                min: yScaleMin,
                max: yScaleMax,
                stacked: yScaleStacked,
                reverse: yScaleReverse
              }}
              yFormat=" >-.2f"
              curve={curve}
              axisTop={
                showTopAxis ? 
                  {
                    tickSize: topAxisTickSize,
                    tickPadding: topAxisTickPadding,
                    tickRotation: topAxisTickRotation,
                    legend: topAxisLabel,
                    //legend: <TranslationContent contentID="row" />, // This also works but I don't no need as this input is only for user and his audience
                    legendOffset: topAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: topAxisTruncateTickAt
                  } 
                : null
              }
              axisRight={
                showRightAxis ? 
                  {
                    tickSize: rightAxisTickSize,
                    tickPadding: rightAxisTickPadding,
                    tickRotation: rightAxisTickRotation,
                    legend: rightAxisLabel,
                    legendOffset: rightAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: rightAxisTruncateTickAt
                  } 
                : null
              }
              axisBottom={
                showBottomAxis ? 
                  {
                    tickSize: bottomAxisTickSize,
                    tickPadding: bottomAxisTickPadding,
                    tickRotation: bottomAxisTickRotation,
                    legend: bottomAxisLabel,
                    legendOffset: bottomAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: bottomAxisTruncateTickAt
                  }
                : null
              }
              axisLeft={
                showLeftAxis ? 
                  {
                    tickSize: leftAxisTickSize,
                    tickPadding: leftAxisTickPadding,
                    tickRotation: leftAxisTickRotation,
                    legend: leftAxisLabel,
                    legendOffset: leftAxisLabelOffSet,
                    legendPosition: 'middle',
                    truncateTickAt: leftAxisTruncateTickAt
                  }
                : null
              }
              colors={{ scheme: chartColors }}
              lineWidth={curveThickness}
              enablePoints={enablePoints}
              pointSize={pointSize}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              enablePointLabel={enablePointLabels}
              pointLabel="data.yFormatted"
              pointLabelYOffset={pointLabelYOffset}
              enableArea={enableArea}
              areaBaselineValue={areaBaselineValue}
              areaOpacity={areaOpacity}
              isInteractive={isInteractive}
              useMesh={useMesh}
              debugMesh={debugMesh}
              enableSlices={enableSlices}
              debugSlices={debugSlices}
              enableCrosshair={enableCrosshair}
              enableTouchCrosshair={enableTouchCrosshair}
              crosshairType={crosshairType}
              legends={showLegend ?
                [
                  {
                    toggleSerie: true,
                    anchor: legendPosition,
                    direction: legendLayout, // it can be row || column
                    justify: justifyLegend,
                    translateX: legendTranslateX,
                    translateY: legendTranslateY,
                    itemWidth: legendWidth,
                    itemHeight: legendHeight,
                    itemsSpacing: legendSpacing,
                    itemDirection: legendDirection,
                    symbolSize: symbolSize,
                    itemOpacity: 0.75,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemTextColor: currentTheme.theme === "dark" ? 'white' : "black", 
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]
                : 
                []
              }
            />
          </Box>
        </Grid>

        <LineValueSettings
          numericFilters={chartData.numericFilters}
          valueButtons={valueButtons}
          updateLineValues={updateLineValues}
        />

        <LineIdentifierSettings
          labelButtons={labelButtons}
          selectedLabelButton={selectedLabelButton}
          updateSelectedLabel={updateSelectedLabel}
          preferredIdentifier={chartData.preferredIdentifier}
        />

        <LineDimensionMarginSettings
          topMargin={chartTopMargin} 
          bottomMargin={chartBottomMargin}
          rightMargin={chartRightMargin}
          leftMargin={chartLeftMargin}
          updateMargin={updateMargin}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          updateDimensions={updateDimensions}
          restoreDefaults={restoreDefaults}
        />
    
        <LineLegendSettings 
          showLegend={showLegend}
          setShowLegend={() => setShowLegend(!showLegend)}
          legendPosition={legendPosition}
          setLegendPosition={(value) => setLegendPosition(value)}
          legendLayout={legendLayout}
          setLegendLayout={(value) => setLegendLayout(value)}
          legendTranslateX={legendTranslateX}
          setLegendTranslateX={(value) => setLegendTranslateX(value)}
          legendTranslateY={legendTranslateY}
          setLegendTranslateY={(value) => setLegendTranslateY(value)}
          legendWidth={legendWidth}
          setLegendWidth={(value) => setLegendWidth(value)}
          legendHeight={legendHeight}
          setLegendHeigth={(value) => setLegendHeigth(value)}
          legendSpacing={legendSpacing}
          setLegendSpacing={(value) => setLegendSpacing(value)}
          symbolSize={symbolSize}
          setSymbolSize={(value) => setSymbolSize(value)}
          justifyLegend={justifyLegend}
          setJustifyLegend={() => setJustifyLegend(!justifyLegend)}
          legendDirection={legendDirection}
          setLegendDirection={(value) => setLegendDirection(value)}
        />

        <LineAxisSettings
          showGridX={showGridX}
          setShowGridX={() => setShowGridX(!showGridX)}
          showGridY={showGridY}
          setShowGridY={() => setShowGridY(!showGridY)}

          topAxisLabel={topAxisLabel}
          setTopAxisLabel={(value) => setTopAxisLabel(value)}
          rightAxisLabel={rightAxisLabel}
          setRightAxisLabel={(value) => setRightAxisLabel(value)}
          bottomAxisLabel={bottomAxisLabel}
          setBottomAxisLabel={(value) => setBottomAxisLabel(value)}
          leftAxisLabel={leftAxisLabel}
          setLeftAxisLabel={(value) => setLeftAxisLabel(value)}

          showTopAxis={showTopAxis}
          setShowTopAxis={() => setShowTopAxis(!showTopAxis)}
          showRightAxis={showRightAxis}
          setShowRightAxis={() => setShowRightAxis(!showRightAxis)}
          showBottomAxis={showBottomAxis}
          setShowBottomAxis={() => setShowBottomAxis(!showBottomAxis)}
          showLeftAxis={showLeftAxis}
          setShowLeftAxis={() => setShowLeftAxis(!showLeftAxis)}

          topAxisTickSize={topAxisTickSize}
          setTopAxisTickSize={(value) => setTopAxisTickSize(value)}
          topAxisTickPadding={topAxisTickPadding}
          setTopAxisTickPadding={(value) => setTopAxisTickPadding(value)}
          topAxisTickRotation={topAxisTickRotation}
          setTopAxisTickRotation={(value) => setTopAxisTickRotation(value)}
          topAxisTruncateTickAt={topAxisTruncateTickAt}
          setTopAxisTruncateTickAt={(value) => setTopAxisTruncateTickAt(value)}
          topAxisLabelOffSet={topAxisLabelOffSet}
          setTopAxisLabelOffSet={(value) => setTopAxisLabelOffSet(value)}

          rightAxisTickSize={rightAxisTickSize}
          setRightAxisTickSize={(value) => setRightAxisTickSize(value)}
          rightAxisTickPadding={rightAxisTickPadding}
          setRightAxisTickPadding={(value) => setRightAxisTickPadding(value)}
          rightAxisTickRotation={rightAxisTickRotation}
          setRightAxisTickRotation={(value) => setRightAxisTickRotation(value)}
          rightAxisTruncateTickAt={rightAxisTruncateTickAt}
          setRightAxisTruncateTickAt={(value) => setRightAxisTruncateTickAt(value)}
          rightAxisLabelOffSet={rightAxisLabelOffSet}
          setRightAxisLabelOffSet={(value) => setRightAxisLabelOffSet(value)}

          bottomAxisTickSize={bottomAxisTickSize}
          setBottomAxisTickSize={(value) => setBottomAxisTickSize(value)}
          bottomAxisTickPadding={bottomAxisTickPadding}
          setBottomAxisTickPadding={(value) => setBottomAxisTickPadding(value)}
          bottomAxisTickRotation={bottomAxisTickRotation}
          setBottomAxisTickRotation={(value) => setBottomAxisTickRotation(value)}
          bottomAxisTruncateTickAt={bottomAxisTruncateTickAt}
          setBottomAxisTruncateTickAt={(value) => setBottomAxisTruncateTickAt(value)}
          bottomAxisLabelOffSet={bottomAxisLabelOffSet}
          setBottomAxisLabelOffSet={(value) => setBottomAxisLabelOffSet(value)}

          leftAxisTickSize={leftAxisTickSize}
          setLeftAxisTickSize={(value) => setLeftAxisTickSize(value)}
          leftAxisTickPadding={leftAxisTickPadding}
          setLeftAxisTickPadding={(value) => setLeftAxisTickPadding(value)}
          leftAxisTickRotation={leftAxisTickRotation}
          setLeftAxisTickRotation={(value) => setLeftAxisTickRotation(value)}
          leftAxisTruncateTickAt={leftAxisTruncateTickAt}
          setLeftAxisTruncateTickAt={(value) => setLeftAxisTruncateTickAt(value)}
          leftAxisLabelOffSet={leftAxisLabelOffSet}
          setLeftAxisLabelOffSet={(value) => setLeftAxisLabelOffSet(value)}
        />

        <LineScaleSettings
          yScaleStacked={yScaleStacked}
          setScaleStacked={(value) => setScaleStacked(value)}
          yScaleReverse={yScaleReverse}
          setYScaleReverse={(value) => setYScaleReverse(value)}
          yScaleMin={yScaleMin}
          setYScaleMin={(value) => setYScaleMin(value)}
          yScaleMax={yScaleMax}
          setYScaleMax={(value) => setYScaleMax(value)}
          yScaleType={yScaleType}
          setYScaleType={(value) => setYScaleType(value)}
          yData={[...lineData]}
        />

        <LineStyleSettings
          curve={curve}
          updateCurve={(value) => setCurve(value)}
          curveThickness={curveThickness}
          updateCurveThickness={(value) => setCurveThickness(value)}
          chartColors={chartColors}
          updateChartColors={(value) => setChartColors(value)}

          enableArea={enableArea}
          updateEnableArea={() => setEnableArea(!enableArea)}
          areaBaselineValue={areaBaselineValue}
          updateAreaBaselineValue={(value) => setAreaBaselineValue(value)}
          areaOpacity={areaOpacity}
          updateAreaOpacity={(value) => setAreaOpacity(value)}

          enablePoints={enablePoints}
          updateEnablePoints={() => setEnablePoints(!enablePoints)}
          pointSize={pointSize}
          updatePointSize={(value) => setPointSize(value)}
          enablePointLabels={enablePointLabels}
          updateEnablePointLabels={() => setEnablePointLabels(!enablePointLabels)}
          pointLabelYOffset={pointLabelYOffset}
          updatePointLabelYOffset={(value) => setPointLabelYOffset(value)}
          yData={[...lineData]}
        />

        <LineInteractivitySettings
          isInteractive={isInteractive}
          updateIsInteractive={() => setIsInteractive(!isInteractive)}
          useMesh={useMesh}
          updateUseMesh={() => setUseMesh(!useMesh)}
          debugMesh={debugMesh}
          updateDebugMesh={() => setDebugMesh(!debugMesh)}
          debugSlices={debugSlices}
          updateDebugSlices={() => setDebugSlices(!debugSlices)}
          enableSlices={enableSlices}
          updateEnableSlices={(value) => setEnableSlices(value)}
          enableCrosshair={enableCrosshair}
          updateEnableCrosshair={() => setEnableCrosshair(!enableCrosshair)}
          enableTouchCrosshair={enableTouchCrosshair}
          updateEnableTouchCrosshair={() => setEnableTouchCrosshair(!enableTouchCrosshair)}
          crosshairType={crosshairType}
          updateCrosshairType={(value) => setCrosshairType(value)}
        />
      </>
    )
  }
}


export default LineChart;