import React from "react";
import { BottomNavigationAction, BottomNavigation, Paper, Stack, Typography } from "@mui/material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import { ExportContext } from "../../Context/ExportProvider";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from "../PDF/PDFDocument";
import { ThemeModeStateContext } from "../../Theme/ThemeProvider";
import TranslationContent from "../../Translations/TranslationContent";


const ButtonToolBar = ({ activeBottomNavigation, handleBottomNavigationChange, position }) => {
  const exportContent = React.useContext(ExportContext);
  const theme = React.useContext(ThemeModeStateContext);

  return(
    <Paper sx={{ bottom: 0, left: 0, right: 0, mx: "-10px", mb: position === "top" ? "10px" : "-10px", mt: position === "top" ? "-10px" : "10px" }} elevation={3}>
      <BottomNavigation 
        sx={{ width: "100%", borderBottom: position === "top" ? "1px solid" : "", borderTop: position === "bottom" ? "1px solid" : ""}}
        value={activeBottomNavigation} 
        onChange={(event, newValue) => handleBottomNavigationChange(event, newValue)} 
        showLabels
      >
        <BottomNavigationAction
          disabled={exportContent.length < 2}
          sx={{opacity: exportContent.length < 2 ? 0.3 : 1}}
          label={<TranslationContent contentID="order" />}
          value="order"
          icon={<ImportExportIcon 
          />}
        />
        <BottomNavigationAction
          label={<TranslationContent contentID="add" />}
          value="add"
          icon={<PostAddIcon />}
        />
        <BottomNavigationAction
          disabled={exportContent.length < 1}
          sx={{opacity: exportContent.length < 1 ? 0.3 : 1}}
          label={<TranslationContent contentID="preview" />}
          value="preview"
          icon={<PreviewIcon />}
        />
        <Stack spacing={0} sx={{cursor: exportContent.length < 1 ? "default" : "pointer", pointerEvents: exportContent.length < 1 ? "none" : "", minWidth: "80px", maxWidth: "168px", padding: "6px 12px", textAlign: "center", opacity: exportContent.length < 1 ? 0.3 : 1}}>
          <PDFDownloadLink document={<PDFDocument exportContent={exportContent} theme={theme.theme} />} fileName="product-comparison-export.pdf" style={{textDecoration:"none", cursor: exportContent.length < 1 ? "default" : "pointer", pointerEvents: exportContent.length < 1 ? "none" : ""}}>
            <BottomNavigationAction
              disabled={exportContent.length < 1}
              sx={{opacity: exportContent.length < 1 ? 0.4 : 1}}
              value="download"
              icon={<FileDownloadIcon />}
            />
          </PDFDownloadLink>
          <Typography variant="caption" sx={{opacity: exportContent.length < 1 ? 0.5 : 1}}><TranslationContent contentID="download" /></Typography>
        </Stack>
      </BottomNavigation>
    </Paper>
  )
}

export default ButtonToolBar;