import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import { Stack, Typography, Box, Button } from "@mui/material";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingDivider from "../../Settings/SettingDivider";
import { ThemeModeStateContext } from "../../../../../Theme/ThemeProvider";
import SliderSetting from "../../Settings/SliderSetting";
import ChartMarginSettings from "../../Settings/ChartMarginSettings";


const BarDimensionMarginSettings = 
  ({
    // Chart margins
    topMargin, 
    bottomMargin, 
    rightMargin, 
    leftMargin, 
    updateMargin,

    // Chart dimensions
    chartWidth,
    chartHeight,
    // Container dimensions
    containerWidth,
    containerHeight,
    // Update function for chart and container dimensions
    // event => updateDimensions(evet.target.value, <type>)
    updateDimensions,

    restoreDefaults
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const { width } = useViewport();
  const currentTheme = React.useContext(ThemeModeStateContext);
 
  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="dimensionsAndMargins">
      <Box sx={{ml: 1.5}}>
        <Typography variant="caption"><TranslationContent contentID="marginDimensionDesc" /></Typography>
        <Button size="small" onClick={() => restoreDefaults()}><TranslationContent contentID="restore" /></Button>
      </Box>
      <SettingDivider />
      <div style={{height: "15px"}}/>
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="container" /></b></Typography>
        <Box sx={{height: "40px", minWidth: "80px", border: "1px solid #fc2516", background: "#fc2516", textAlign: "center", alignContent: "center"}}>
          <InsertChartIcon />
        </Box>
        <Typography variant="caption" sx={{maxWidth: "60%", opacity: 0.8, lineHeight: 1.2}}><TranslationContent contentID="dimensionsDesc" /></Typography>
        </Stack>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="dimensions" /></b></Typography>
        <SliderSetting
          label="generalWidth"
          icon="itemWidth"
          minValue={0}
          maxValue={100}
          value={containerWidth}
          updateWithTwo={true}
          type="containerWidth"
          updateValue={updateDimensions}
          variableType="percentageMark"
        />
        <SliderSetting
          label="generalHeight"
          icon="itemHeight"
          minValue={chartHeight}
          maxValue={2000}
          value={containerHeight}
          updateWithTwo={true}
          type="containerHeight"
          updateValue={updateDimensions}
          variableType="px"
        />

        <SettingDivider />
        <div style={{height: "15px"}}/>

        <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>

        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="chart" /></b></Typography>
        <Box sx={{height: "40px", minWidth: "80px", border: currentTheme.theme === "light" ? "1px solid black" : "1px solid white", textAlign: "center", alignContent: "center"}}>
        <InsertChartIcon color="primary" />
        </Box>
        <Typography variant="caption" sx={{maxWidth: "60%", opacity: 0.8, lineHeight: 1.2}}><TranslationContent contentID="chartDesc" /></Typography>


      </Stack>
      <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="dimensions" /></b></Typography>

      <SliderSetting
          label="generalWidth"
          icon="itemWidth"
          minValue={0}
          maxValue={width}
          value={chartWidth}
          updateWithTwo={true}
          type="chartWidth"
          updateValue={updateDimensions}
          variableType="px"
        />
        <SliderSetting
          label="generalHeight"
          icon="itemHeight"
          minValue={0}
          maxValue={containerHeight}
          value={chartHeight}
          updateWithTwo={true}
          type="chartHeight"
          updateValue={updateDimensions}
          variableType="px"
        />
      <div style={{height: "15px"}}/>
       <ChartMarginSettings
        topMargin={topMargin}
        bottomMargin={bottomMargin}
        rightMargin={rightMargin}
        leftMargin={leftMargin}
        updateMargin={updateMargin}
       />
    </SettingContainer>
  )
}


export default BarDimensionMarginSettings;