import React from "react";
import { Typography } from "@mui/material";
import TranslationContent from "../../../../Translations/TranslationContent";


const HeaderNameShort = () => {
  return(
    <>
      <Typography sx={{color: "black", mb: 1}} variant="body2"><b style={{color: "#ff9800"}}><TranslationContent contentID="name" /></b>:<TranslationContent contentID="category" />:<TranslationContent contentID="type" /></Typography>
      <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="headerNameShortDesc" /></Typography>
    </>
  )
}

export default HeaderNameShort;