import React from "react";
import { IconButton, Typography, Box, Stack, Collapse }from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TranslationContent from '../../../Translations/TranslationContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { VariableSizeList as List } from 'react-window';
import { useViewport } from "../../../Context/ViewportProvider";


const ErrorContent = ({ dataEntries }) => {
  let [showContent, setShowContent] = React.useState(false);
  const { height } = useViewport();
  
  const errorCount = () => {
    let count = 0;
    dataEntries.forEach(data => {
      count = count + data.errors.length;
    })
    return count;
  }

  if(errorCount() > 0) {
    return(
      <Box sx={{ bgcolor: "#FFD5D1", border: "1px #FF1900 solid", padding: "5px",}}>
        <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
          <DangerousIcon sx={{color: "#FF1900" }}/>
          <Typography sx={{color: "black"}} variant="body2">{errorCount()} <TranslationContent contentID="errorsFound" /></Typography>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{color: "#FF1900", marginRight: 0,marginLeft: "auto !important", display: "flex"}}
            onClick={() => setShowContent(!showContent)}
          >
            {showContent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Stack>
        <Collapse in={showContent} timeout="auto" unmountOnExit>
        <List
            height={height * 0.5} // This needs to be a number, otherwise error!
            itemCount={dataEntries.length}
            itemSize={(index) => getItemSize(index, dataEntries)} // This needs to be a function, otherwise error!
            width={"100%"} // This can be %
            itemData={dataEntries} // The way to pass data entries to Row component
          >
            {ErrorRow}
          </List>
          <br />
        </Collapse>
      </Box>
    )
  }
}

export default ErrorContent;


const getItemSize = (index, data) => {
  const dataEntry = data[index];
  return dataEntry.errors.length > 0 ? 50 + dataEntry.errors.length * 20 : 0;
};


const ErrorRow = ({ index, style, data }) => {
  const dataEntry = data[index];

  if (dataEntry.errors.length === 0) {
    return null;
  }

  return (
    <div style={{ ...style, paddingTop: '20px' }} key={`{${dataEntry?.value} - ${index} error}`}>
      <Stack direction={"row"} spacing={1} sx={{paddingLeft: "5px"}}>
        <WrongLocationIcon fontSize='small' sx={{color: "#FF1900", alignSelf: "center"}} />
        <Typography sx={{color: "black"}}>{dataEntry.location} (<TranslationContent contentID={dataEntry.entryType} />): {dataEntry?.value?.toString()}</Typography>
      </Stack>
      {dataEntry.errors.map((error, errorIndex) =>
        <Stack key={error+index+errorIndex} direction={"row"} spacing={1} sx={{paddingLeft: "30px"}}>
          <PriorityHighIcon fontSize='small' sx={{color: "#FF1900"}}/><Typography sx={{color: "black"}} variant="caption"><TranslationContent contentID={error} /></Typography>
        </Stack>
      )}
    </div>
  );
};