export const  darkPieTheme = {
  //"background": "#ffffff",
  "text": {
    "fontSize": 12,
    "fill": "#333333",
    "outlineWidth": 0,
    "outlineColor": "transparent"
},
  "axis": {
    "domain": {
        "line": {
            "stroke": "#777777",
            "strokeWidth": 1
        }
    },
    "legend": {
        "text": {
            "fontSize": 12,
            "fill": "#333333",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        }
    },
    "ticks": {
        "line": {
            "stroke": "#777777",
            "strokeWidth": 1
        },
        "text": {
            "fontSize": 11,
            "fill": "#333333",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        }
    }
},
  "tooltip": {
    "wrapper": {
      "zIndex": 1400,
    },
    "container": {
        "background": "#ffffff",
        "color": "#333333",
        "fontSize": 12
    },
    "basic": {},
    "chip": {},
    "table": {},
    "tableCell": {},
    "tableCellValue": {}
}
}


export const lightPieTheme = {
  //"background": "#ffffff",
  "tooltip": {
    "wrapper": {
      "zIndex": 1400,
    },
    "container": {
        "background": "#ffffff",
        "color": "#333333",
        "fontSize": 12
    },
    "basic": {},
    "chip": {},
    "table": {},
    "tableCell": {},
    "tableCellValue": {}
}
}