import React from "react";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import { InputAdornment, Typography, Box, Slider } from "@mui/material";
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
//import SquareFootIcon from '@mui/icons-material/SquareFoot';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HeightIcon from '@mui/icons-material/Height';
import AdjustIcon from '@mui/icons-material/Adjust';
import PaddingIcon from '@mui/icons-material/Padding';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import StraightenIcon from '@mui/icons-material/Straighten';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import TranslationContent from "../../../../Translations/TranslationContent";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import OpacityIcon from '@mui/icons-material/Opacity';
import StartIcon from '@mui/icons-material/Start';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PieChartIcon from '@mui/icons-material/PieChart';
import LooksIcon from '@mui/icons-material/Looks';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import TextRotateVerticalIcon from '@mui/icons-material/TextRotateVertical';


const Input = styled(MuiInput)`
  width: 82px;
`;

export default function SliderSetting({ label, icon, minValue, maxValue, value, updateValue, variableType, updateWithTwo, type, step }) {

  const handleSliderChange = (event, newValue) => {
    if(!updateWithTwo) {
      updateValue(newValue);
    }
    if(updateWithTwo) {
      updateValue(newValue, type);
    }
  };

  const handleInputChange = (event) => {
    if(!updateWithTwo) {
      updateValue(event.target.value === '' ? 0 : Number(event.target.value));
    }
    if(updateWithTwo) {
      updateValue(event.target.value === '' ? 0 : Number(event.target.value), type);
    }
  };

  const handleBlur = () => {
    if (value < minValue) {
      updateValue(minValue);
    } else if (value > maxValue) {
      updateValue(maxValue);
    }
  };

  return (
    <Box sx={{ width: "98%", pl: 1.5 }}>
      <Typography id="input-slider" gutterBottom variant="caption" sx={{opacity: 0.8}}>
        <TranslationContent contentID={label} />
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <IconManager icon={icon} />
        </Grid>
        <Grid item xs>
          <Slider
            size="small"
            value={typeof value === 'number' ? value : minValue}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={step || 10}
            min={minValue}
            max={maxValue}
          />
        </Grid>
        <Grid item sx={{alignSelf: "flex-start"}}>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            endAdornment={<InputAdornment position="end"><TranslationContent contentID={variableType} /></InputAdornment>}
            inputProps={{
              step: step || 10,
              min: minValue,
              max: maxValue,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}


const IconManager = ({ icon }) => {
  if(icon) {
    switch(icon) {
      case "translateX":
        return <AlignHorizontalCenterIcon color="primary" fontSize="small" />
      case "translateY":
        return <AlignVerticalCenterIcon color="primary" fontSize="small" />
      case "itemWidth":
        return <SyncAltIcon color="primary" fontSize="small" />
      case "itemHeight":
        return <HeightIcon color="primary" fontSize="small" />
      case "itemSpacing":
        return <DensityMediumIcon color="primary" fontSize="small" />
      case "symbolSize":
        return <AdjustIcon color="primary" fontSize="small" />
      case "padding":
        return <PaddingIcon color="primary" fontSize="small" />
      case "lineWeight":
        return <LineWeightIcon color="primary" fontSize="small" />
      case "truncate":
        return <TextDecreaseIcon color="primary" fontSize="small" />
      case "straigten":
        return <StraightenIcon color="primary" fontSize="small" />
      case "textRotate":
        return <TextRotationAngledownIcon color="primary" fontSize="small" />
      case "min":
        return <TrendingDownIcon color="primary" fontSize="small" />
      case "max":
        return <TrendingUpIcon color="primary" fontSize="small" />
      case "opacity":
        return <OpacityIcon color="primary" fontSize="small" />;
      case "baseline":
        return <StartIcon color="primary" fontSize="small" />
      case "yOffset":
        return <VerticalAlignCenterIcon color="primary" fontSize="small" />
      case "innerPieRadius":
        return <DonutLargeIcon color="primary" fontSize="small" />
      case "pieCornerRadius":
        return <DonutSmallIcon color="primary" fontSize="small" />
      case "padAngle":
        return <PieChartIcon color="primary" fontSize="small" />
      case "startAngle":
        return <LooksIcon color="primary" fontSize="small" />
      case "endAngle":
        return <TrackChangesIcon color="primary" fontSize="small" />
      case "angleToShowLabel":
        return <SpellcheckIcon color="primary" fontSize="small" />
      case "textOffsetX":
        return <TextRotationNoneIcon color="primary" fontSize="small" />
      case "barPadding":
        return <SignalCellularAltIcon color="primary" fontSize="small" />
      case "barInnerPadding":
        return <StackedBarChartIcon color="primary" fontSize="small" />
      case "barBorderRadius":
        return <BorderStyleIcon color="primary" fontSize="small" />
      case "barBorderWidth":
        return <BorderOuterIcon color="primary" fontSize="small" />
      case "labelSkipHeight":
        return <TextRotateVerticalIcon color="primary" fontSize="small" />
      default:
        break;
    }
  }
}