import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Paper, Typography, Button, Stack } from "@mui/material";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "../loadingDots.css";
import { useViewport } from "../../../Context/ViewportProvider";
import QueryStatsIcon from '@mui/icons-material/QueryStats';


const CompareLoading = ({ loading, error }) => {
  const  { width, height } = useViewport();
  const navigate = useNavigate();

  if(loading && !error) {
    return(
      <Grid container mb={2}>
        <Grid xs={12}>
          <Paper sx={{padding: "7px", display: "inline-flex", width: "100%"}} elevation={5}>
            {width > 750 && height > 500 ?
              <Typography color="primary" variant="body2" sx={{alignContent: "center"}}>
                <b onClick={() => navigate("/compare")} style={{cursor: "pointer", marginTop: "auto", marginBottom: "auto"}}>
                  <TranslationContent contentID="compare" />
                </b>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
              </Typography>
            :
              <Stack direction="row" sx={{alignContent: "center", marginTop: "auto", marginBottom: "auto"}}>
                <QueryStatsIcon color="primary" fontSize="small" sx={{cursor: "pointer"}} onClick={() => navigate("/compare")} />
                <KeyboardArrowRightIcon color="primary" sx={{verticalAlign:"middle"}} />
              </Stack>
            }
            <div style={{padding: "5px", alignContent: "center"}}>
              <div className="loader" />
            </div>
            <Button size="small" disabled variant="contained" sx={{marginRight: 0, marginLeft: "auto"}}><TranslationContent contentID="manageData" /></Button>
          </Paper>
        </Grid>
        <Grid xs={12}  mt={10} textAlign={"center"}>
          <LoadingSpinner />
        </Grid>
      </Grid>
    )
  }
}

export default CompareLoading;