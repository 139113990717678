import React from "react";
import { useTheme } from '@mui/material/styles';
import { Drawer, List, Typography, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material'
import MenuIconHandler from "./MenuIconHandler";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslationContent from "../../Translations/TranslationContent";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "./DrawerHeader";
import { ExportContext } from "../../Context/ExportProvider";


const MobileDrawer = ({ mobileOpen, navigationList, handleLogout, handleNavigation, handleDrawerClose }) => {
  const exportContent = React.useContext(ExportContext);
  const theme = useTheme();
  const location = useLocation();

  return(
    <Drawer open={mobileOpen} variant="temporary" sx={{zIndex: 5000}} onClose={handleDrawerClose}>
      <DrawerHeader>
        <CompanyLogo width={100} showAppLogo={false}/>
        <IconButton onClick={handleDrawerClose} color="primary">
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navigationList.basic.map((navigationItem, index) => (
          <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
            <ListItemButton
              onClick={() => handleNavigation(navigationItem.path)}
              sx={{
                minHeight: 48,
                justifyContent: mobileOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                color="primary"
                sx={{
                  minWidth: 0,
                  mr: mobileOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MenuIconHandler navigationItem={navigationItem}/>
              </ListItemIcon>
              <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : "" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {navigationList.tools.some(entry => entry !== null) && 
        <>
          <Divider />
          <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="tools" /></b></Typography>
          <List>
            {navigationList.tools.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
                <ListItemButton
                  onClick={() => handleNavigation(navigationItem.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: mobileOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      color="primary"
                      sx={{
                        minWidth: 0,
                        mr: mobileOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIconHandler navigationItem={navigationItem}/>
                    </ListItemIcon>
                    {navigationItem.section === "export" ?
                      <ListItemText primary={<><TranslationContent contentID={navigationItem.section} /><Badge badgeContent={exportContent.length} variant="standard" color="primary" sx={{position: "relative", top: -2, right: -15}}/></>} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("ruukki") ? "#fc2516" : "#009fe3" : "" }} />
                    :
                      <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path || (navigationItem.path.includes("compare") && location.pathname.includes("compare")) ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : "" }} />
                    }
                  </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      }
      {navigationList.management.some(entry => entry !== null) && 
        <>
          <Divider />
          <Typography variant="caption" sx={{padding: "5px 0 0 20px"}}><b><TranslationContent contentID="management" /></b></Typography>
          <List>
            {navigationList.management.map((navigationItem, index) => ( navigationItem !== null &&
              <ListItem key={navigationItem.section + index} disablePadding sx={{ display: 'block', }} color="primary">
                <ListItemButton
                  onClick={() => handleNavigation(navigationItem.path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: mobileOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    color="primary"
                    sx={{
                      minWidth: 0,
                      mr: mobileOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <MenuIconHandler navigationItem={navigationItem}/>
                  </ListItemIcon>
                    <ListItemText primary={<TranslationContent contentID={navigationItem.section} />} sx={{ opacity: mobileOpen ? 1 : 0, color: location.pathname === navigationItem.path ? window.location.hostname.includes("piristeel") ? "#009fe3" : "#fc2516" : "" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      }
      <Divider />
      <List>
        <ListItem key="logout-button-mobile" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            color="primary"
            onClick={handleLogout}
            sx={{
              minHeight: 48,
              justifyContent: mobileOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              color="primary"
              sx={{
                minWidth: 0,
                mr: mobileOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LogoutIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={<TranslationContent contentID="logout" />} sx={{ opacity: mobileOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem> 
      </List>
    </Drawer>
  )
}

export default MobileDrawer;