import React from "react";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TranslationContent from "../../Translations/TranslationContent";


const LoginStatus = ({ accounts, activeAccount, activeAccountSelected }) => {
  if(accounts?.length > 0) {
    return(
      <>
        <CheckCircleIcon color="success" fontSize="large"/>
        <Typography><b><TranslationContent contentID="loginSuccessful" /></b></Typography>
        {activeAccountSelected ? <Typography mt={3}><TranslationContent contentID="welcomeback" />, {activeAccount?.name}</Typography> :null}
        <Typography variant="caption"><TranslationContent contentID="loginSuccessDesc1" /> <b>{accounts.length}</b> <TranslationContent contentID="loginSuccessDesc2" /></Typography>
      </>
    )
  }
  if(!accounts || accounts?.length === 0 || accounts === null) {
    return(
      <>
        <ErrorIcon color="error" fontSize="large"/>
        <Typography><b><TranslationContent contentID="loginFailed" /></b></Typography>
        <Typography><TranslationContent contentID="loginFailedDesc" /></Typography>
      </>
    )
  }
}

export default LoginStatus;