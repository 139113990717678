import React, { useEffect } from "react"
import { logger } from "../services/logger"

export const LanguageContext = React.createContext()
export const LanguageDispatchContext = React.createContext()

const initialLanguage = {
  lang: "fi"
}

function switchLanguageReducer(language, action) {
  switch(action.type) {
    case "SWITCH_LANGUAGE_FIN": {
      return {
        ...language,
        lang: "fi"
      }
    }
    case "SWITCH_LANGUAGE_ENG": {
      return {
        ...language,
        lang: "en"
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const checkLocalStorageLang = (initialLanguage)  => {
  const localLang = localStorage.getItem("langMode")
 
  if(localLang === "fi" || localLang === "en") {
    return {lang: localLang};
  }
  else if(localLang !== "fi" || localLang !== "en" || localLang === null || typeof localLang === "undefined"){
    const preferredLanguage = navigator.languages[0] || window.navigator.userLanguage || window.navigator.language;
      if(preferredLanguage === 'en-US' || preferredLanguage === 'en-us' || preferredLanguage === 'en-gb' || preferredLanguage === 'en-au') {
        return {lang: "en"}
    }
  }
  return initialLanguage;
}

const LanguageProvider = ({ children }) => {
  const [language, dispatch ] = React.useReducer(switchLanguageReducer, initialLanguage, checkLocalStorageLang);

  useEffect(() => {
    localStorage.setItem("langMode", language.lang);
    logger("Selected language: " + language.lang)
  }, [language]);
  
  return(
    <LanguageContext.Provider value={language}>
      <LanguageDispatchContext.Provider value={dispatch}>
        {children}
      </LanguageDispatchContext.Provider>
    </LanguageContext.Provider>
  )
}

export default LanguageProvider;