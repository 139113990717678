import { toPng, toJpeg, /*toBlob, toPixelData,*/ toSvg } from 'html-to-image';
//import * as htmlToImage from 'html-to-image';



export function downloadImage(domElement, type) {
  if(type === "jpeg") {
    toJpeg(document.getElementById(domElement), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `product-comparison.${type}`;
        link.href = dataUrl;
        link.click();
      });
  }
  if(type === "png") {
    toPng(document.getElementById(domElement), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `product-comparison.${type}`;
        link.href = dataUrl;
        link.click();
      });
  }
  if(type === "svg") {
    function filter (node) {
      return (node.tagName !== 'i');
    }
    toSvg(document.getElementById(domElement), { filter: filter })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `product-comparison.${type}`;
        link.href = dataUrl;
        link.click();
      });
  }
}