import React from "react"
import Snackbar from '@mui/material/Snackbar';
import { Typography, Stack } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TranslationContent from "../Translations/TranslationContent";
import { logger } from "../services/logger";


export const SnackBarContext = React.createContext()

export const useSnackBars = () => React.useContext(SnackBarContext)

const AUTO_DISMISS = 5000

export function SnackBarProvider({ children }) {
  const [alerts, setAlerts] = React.useState([])
  
  const activeAlertIds = alerts.join(',')
  React.useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS)
      return () => clearTimeout(timer)
    }
    if(alerts.length === 0 ) {
      logger("Snackbar: No alerts in queue");
    }
    if(alerts.length > 0 ) {
      logger(`Snackbar has ${alerts.length} alerts in queue`);
      logger(alerts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlertIds])

  const addAlert = (alert) => setAlerts((alerts) => [alert, ...alerts])

  const value = { addAlert }
    
  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert, index) => <SnackbarHandler key={alert.message + index} message={alert.message} type={alert.type} open={true} />)}
    </SnackBarContext.Provider>
  )
}

const SnackbarHandler = ({ message, type }) => {
  console.log(message)
  if(type === "error") {
    return(
      <Snackbar 
        open={true} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message=
          {
            <Stack direction="row" spacing={1}>
              <ErrorIcon color="error"/>
              <Typography><TranslationContent contentID={message}/></Typography>
            </Stack>
          } 
      />        
    )
  }
  if(type === "success") {
    return(
      <Snackbar 
        open={true} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message=
          {
            <Stack direction="row" spacing={1}>
              <CheckCircleIcon color="success"/>
              <Typography><TranslationContent contentID={message}/></Typography>
            </Stack>
          } 
      />         
    )
  }
  if(type === "info") {
    return(
      <Snackbar 
        open={true} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message=
          {
            <Stack direction="row" spacing={1}>
              <InfoIcon color="primary"/>
              <Typography><TranslationContent contentID={message}/></Typography>
            </Stack>
          } 
      />         
    )
  }
  return;
}