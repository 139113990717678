import React from "react";
import { Box, Paper, Card, CardActionArea, CardContent, Button, Typography, Stack, CircularProgress } from "@mui/material";
import GlobalLayout from "../../Layouts/GlobalLayout";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate, useParams } from "react-router-dom";
import TranslationContent from "../../Translations/TranslationContent";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Tokens } from "../../services/Tokens";
import compareServices from "../../services/compare";
import { logger } from "../../services/logger";
import { LanguageContext } from "../../Translations/LanguageProvider";
import ReplayIcon from '@mui/icons-material/Replay';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import * as XLSX from "xlsx";
import { useViewport } from "../../Context/ViewportProvider";
import ValidationDialog from "./ValidationDialog/ValidationDialog";
import "../ComparisonTool/loadingDots.css";


const ProductData = () => {
  const [file, setFile] = React.useState({});
  const [groupData, setGroupData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [showValidationDialog, setShowValidationDialog] = React.useState(false);
  const currentLang = React.useContext(LanguageContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const { id } = useParams();
  const { width } = useViewport();
  const navigate = useNavigate();
  const fileInputRef = React.useRef(null);

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current) {
      fetchData.current = false;
      fetchComparisonData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchComparisonData = async () => {
    setLoading(true);
    setError(false);
    Tokens().then((tokens) => {
      compareServices
      .getData(id, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedGroupData = {...groupData};
        updatedGroupData.data = response.data;
        updatedGroupData.country = response.country;
        updatedGroupData.saved = response.saved;
        updatedGroupData.lastModifier = response.lastModifier;
        updatedGroupData.name = {...response.name};
        updatedGroupData.type = response.type;
        updatedGroupData.role = response.role;
        setGroupData(updatedGroupData);
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingUsersFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const uploadClick = async (event) => {
    setUploading(true);
    try {
      await formatFile(event);
    } catch (error) {
      logger(error);
      setError(true);
      addAlert({ message: "snackbarFailedToUploadData", type: "error" });
      setUploading(false);
    }
  }

  /*
  const formatFile = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      uploadDataToServer(d);
    });
  };
  */

  const formatFile = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = async (e) => {
        try {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
  
          await uploadDataToServer(data);
          resolve();
        } catch (error) {
          reject(error);
        }
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadDataToServer = async (data) => {
    Tokens().then((tokens) => {
      compareServices
      .updateData(id, data, tokens.accessToken, tokens.idToken)
      .then(response => {
        logger(response);
        let updatedGroupData = {...groupData};
        updatedGroupData.country = response.country;
        updatedGroupData.saved = response.data.saved;
        updatedGroupData.lastModifier = response.data.lastModifier;
        updatedGroupData.name = {...response.name};
        updatedGroupData.type = response.type;
        updatedGroupData.role = response.role;
        setGroupData(updatedGroupData);
        setUploading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        addAlert({message: "snackbarFailedToUploadData", type: "error"});
        setUploading(false);
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      addAlert({message: "snackbarSessionExpired", type: "error"});
      setUploading(false);
    })
  }

  const downloadClick = async () => {
    setDownloading(true);
    await handleDownloadExcelClick(groupData.data);
    setDownloading(false);
  }

  const handleDownloadExcelClick = async (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "products");

    const now = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let currentDate =  new Intl.DateTimeFormat('fi-FI', options).format(now);
    const dateParts = currentDate.split('.');
    let formattedDate =  dateParts.join('_').trim();

    XLSX.writeFile(workbook, `product-comparison-data_${formattedDate}.xlsx`);
  }

  const validateClick = async (file) => {
    setFile(file);
    setShowValidationDialog(true);
    resetFileInput();
  }

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.type = 'text';
      fileInputRef.current.type = 'file';
    }
  };


  if(loading && !error) {
    return(
      <GlobalLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px", display: "flex"}} elevation={5}>
              <Typography color="primary" variant="body2">
                <b style={{marginTop: "auto", marginBottom: "auto"}}>
                  <TranslationContent contentID="management" />
                </b>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
              </Typography>
              <Typography color="primary" variant="body2" sx={{cursor: "pointer", marginTop: "auto", marginBottom: "auto"}} onClick={() => navigate("/data")}>
                <TranslationContent contentID="data" sx={{cursor: "pointer"}}/>
              </Typography>
              <Typography color="primary" variant="body2">
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                </Typography>

                <div style={{padding: "5px", alignContent: "center"}}>
                  <div className="loader" />
                </div>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{width: "100%", textAlign: "center", mt: 5}}>
          <LoadingSpinner />
        </Box>
      </GlobalLayout>
    )
  }

  if(!loading && error) {
    return(
      <GlobalLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px", display: "flex"}} elevation={5}>
              <Typography color="primary" variant="body2">
                <b style={{marginTop: "auto", marginBottom: "auto"}}>
                  <TranslationContent contentID="management" />
                </b>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
              </Typography>
              <Typography color="primary" variant="body2" sx={{cursor: "pointer", marginTop: "auto", marginBottom: "auto"}} onClick={() => navigate("/data")}>
                <TranslationContent contentID="data" sx={{cursor: "pointer"}}/>
              </Typography>
              <Typography color="primary" variant="body2">
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                  <i style={{marginTop: "auto", marginBottom: "auto"}}>
                    <TranslationContent contentID="noData" />
                  </i>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{width: "100%", textAlign: "center", mt: 5}}>
          <Typography mb={5}><TranslationContent contentID="homeErrorOccured" /></Typography>
          <Button variant="outlined" onClick={() => {fetchComparisonData()}}><TranslationContent contentID="retry" /> <ReplayIcon /></Button>
        </Box>
      </GlobalLayout>
    )
  }

  if(!loading && !error) {
    return(
      <GlobalLayout>
        <ValidationDialog file={file} showDialog={showValidationDialog} closeDialog={() => setShowValidationDialog(false)}/>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px", display: "flex"}} elevation={5}>
              <Typography color="primary" variant="body2">
                <b style={{marginTop: "auto", marginBottom: "auto"}}>
                  <TranslationContent contentID="management" />
                </b>
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
              </Typography>
              <Typography color="primary" variant="body2" sx={{cursor: "pointer", marginTop: "auto", marginBottom: "auto"}} onClick={() => navigate("/data")}>
                <TranslationContent contentID="data" sx={{cursor: "pointer"}}/>
              </Typography>
              <Typography color="primary" variant="body2">
                <KeyboardArrowRightIcon sx={{verticalAlign:"middle"}} />
                  <i style={{marginTop: "auto", marginBottom: "auto"}}>
                    {groupData.name[currentLang.lang]} (<TranslationContent contentID={groupData.country} />)
                  </i>
              </Typography>
            </Paper>
          </Grid>
          <Grid xs={12} md={6} xl={4}>
          <input
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers"
              style={{display: "none"}}
              id="upload-button-file"
              type="file"
              onChange={(e) => uploadClick(e.target.files[0])}

              />
              <label htmlFor="upload-button-file" style={{width: "100%"}}>
            <Card sx={{opacity: downloading || uploading ? 0.5 : 1}}>
              <CardActionArea 
                onClick={() => document.getElementById('upload-button-file').click()}
                disabled={downloading || uploading} sx={{minHeight: "100px", maxHeight: "100px"}}>
                <CardContent sx={{display: "flex"}}>
                  <Box sx={{width: "80%"}}>
                    <Stack>
                      <Typography><b><TranslationContent contentID="uploadData" /></b></Typography>
                      <Typography variant="caption" sx={{opacity: 0.6}}><TranslationContent contentID="uploadDesc" /></Typography>
                      <Typography variant="caption" sx={{opacity: 0.4, mt: width > 400 ? 1 : ""}}><i><TranslationContent contentID="lastEdit" /> {new Intl.DateTimeFormat('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(groupData.saved))} ({groupData.lastModifier})</i></Typography>
                    </Stack>
                  </Box>
                  <Box sx={{width: "20%"}}>
                  {uploading ?
                    <CircularProgress size={50} color="primary" />
                    :
                    <CloudUploadIcon sx={{height: "60px", width: "60px"}} color="primary" opacity={0.3} mr={0} ml="auto" />
                  }
                  </Box> 
                  <br />
                </CardContent>
              </CardActionArea>
              
            </Card>
            </label>
          </Grid>
          <Grid xs={12} md={6} xl={4}>
            <Card sx={{opacity: downloading || uploading ? 0.5 : 1, minHeight: "100px"}}>
              <CardActionArea onClick={() => downloadClick()} disabled={downloading || uploading} sx={{minHeight: "100px", maxHeight: "100px"}}>
                <CardContent sx={{display: "flex"}}>
                  <Box sx={{width: "80%"}}>
                    <Stack>
                      <Typography><b><TranslationContent contentID="downloadData" /></b></Typography>
                      <Typography variant="caption" sx={{opacity: 0.6}}><TranslationContent contentID="downloadDesc" /></Typography>
                    </Stack>
                  </Box>
                  <Box sx={{width: "20%"}}>
                  {downloading ?
                    <CircularProgress size={50} color="success" />
                    :
                    <CloudDownloadIcon sx={{height: "60px", width: "60px"}} color="success" opacity={0.3} mr={0} ml="auto" />
                  }
                  </Box>  
                  <br />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} md={6} xl={4}>
          <input
              ref={fileInputRef}
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers"
              style={{display: "none"}}
              id="validate-button-file"
              type="file"
              onChange={(e) => validateClick(e.target.files[0])}
              />
              <label htmlFor="validate-button-file" style={{width: "100%"}}>
            <Card sx={{opacity: downloading || uploading ? 0.5 : 1, minHeight: "100px"}}>
              <CardActionArea 
                onClick={() => document.getElementById('validate-button-file').click()}
                disabled={downloading || uploading} sx={{minHeight: "100px", maxHeight: "100px"}}>
                <CardContent sx={{display: "flex"}}>
                  <Box sx={{width: "80%"}}>
                    <Stack>
                      <Typography><b><TranslationContent contentID="validateData" /></b></Typography>
                      <Typography variant="caption" sx={{opacity: 0.6}}><TranslationContent contentID="validateDesc" /></Typography>
                    </Stack>
                  </Box>
                  <Box sx={{width: "20%"}}>
                    <CloudDoneIcon sx={{height: "60px", width: "60px"}} color="error" opacity={0.3} mr={0} ml="auto" />
                  </Box>  
                  <br />
                </CardContent>
              </CardActionArea>
            </Card>
            </label>
          </Grid>
        </Grid>
      </GlobalLayout>
    )
  }
}

export default ProductData;