import React from "react";
import { Typography } from "@mui/material";
import TranslationContent from "../../../../Translations/TranslationContent";


const HeaderCategoryShort = () => {
  return(
    <>
      <Typography sx={{color: "black", mb: 1,}} variant="body2"><TranslationContent contentID="name" />:<b style={{color: "#ff9800"}}><TranslationContent contentID="category" /></b>:<TranslationContent contentID="type" /></Typography>
      <Typography sx={{color: "black"}} variant="body2"><TranslationContent contentID="headerCategoryShortDesc" /></Typography>
    </>
  )
}

export default HeaderCategoryShort;