import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Divider, Typography } from "@mui/material";
import Filter from "./Filter";
import PossibleTranslationContent from "../../../Translations/PossibleTranslationContent";


const Category = ({ category, removeFilter, comparisonData, addFilter }) => {
  return(
    <>
      <Grid xs={12} >
        <Typography><b><PossibleTranslationContent contentID={category}/></b></Typography>
      </Grid>
      {comparisonData.headers.map((entry, index) => entry.category === category && 
        <Grid xs={12} sm={6} md={4} xl={3} key={category + index}>
          <Filter
            objectKey={entry.uniqueKey}
            entry={entry}
            removeFilter={removeFilter}
            comparisonData={comparisonData}
            addFilter={addFilter}
          />
        </Grid>
      )}
      <Grid xs={12}><Divider /></Grid>
    </>
  )
}

export default Category;