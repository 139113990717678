import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import { logger } from "./logger";


export async function Tokens(accessToken, idToken) {
  let tokens = {
    accessToken: String(),
    idToken: String(),
    userId: String(),
    tokens: [],
  }

    if (!accessToken || !idToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        tokens.accessToken = response.accessToken;
        tokens.idToken = response.idToken;
        tokens.userId = response.idTokenClaims.oid;
        tokens.groups = response.idTokenClaims.groups;
    }
    logger(tokens);
    return tokens;
  }