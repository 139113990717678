import { excelColumns } from "./excelColumns";
import { isValidBoolean, isValidDate, isValidDiscount, isValidNumber } from "./helperFunctions";
import { logger } from "../services/logger";


export const fileDataValidator = async (headers, dataRows) => {
  let invalidDataRows = [];

  for (let rowIndex = 0; rowIndex < dataRows.length; rowIndex++) {
    const row = dataRows[rowIndex];

    for (let colIndex = 0; colIndex < headers.length; colIndex++) {
      let dataEntry = {
        value: row[colIndex] || null,
        entryType: "productData",
        location: excelColumns[colIndex] + (rowIndex + 2),
        errors: [],
        warnings: []
      }

      dataEntry.character = excelColumns[colIndex];
      const header = headers[colIndex];
      const cellValue = row[colIndex];
      dataEntry.value = cellValue;

      if(!cellValue) {
        dataEntry.warnings.push("emptyCellValue");
      }
      else {
        let expectedValueType = header?.split(':')[2];

        if(expectedValueType) {
          switch(expectedValueType) {

            case  "string":
              if(!typeof cellValue === "string") {
                dataEntry.errors.push("invalidString");
              }
              break;

            case "number":
              if(!isValidNumber(cellValue)) {
                dataEntry.errors.push("invalidNumber");
              }
              break;

            case "boolean":
              if(!isValidBoolean(cellValue)) {
                dataEntry.errors.push("invalidBoolean");
              }
              break;

            case "discount":
              if(!isValidDiscount(cellValue)) {
                dataEntry.errors.push("invalidDiscount");
              }
              break;

            case "date":
              if(!isValidDate(cellValue)) {
                dataEntry.errors.push("invalidDate");
              }
              break;

            default:
              logger("Data type not allowed!")
              break;

          }
        }
        else {
          dataEntry.errors.push("noMatchWithDataType");
        }
      }

      if(dataEntry.errors.length > 0 || dataEntry.warnings.length > 0) {
        invalidDataRows.push({...dataEntry});
      }

    }
  }
  return invalidDataRows;
};