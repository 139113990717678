import { logger } from "../../services/logger";

export const optimizeBarChartSettings = ( screenWidth, screenHeight, contentCount ) => {
  let optimizedSettings = {
    containerHeight: Math.floor(screenHeight * 0.5),
    containerWidth: 100,

    chartWidth: Math.floor(screenWidth * 0.9),
    chartHeight: Math.floor(screenHeight * 0.45),

    marginTop: 50,
    marginBottom: 50,
    marginLeft: 50,
    marginRight: 25,

    legendLayout: "column",
    legendPosition: "bottom-right",

    layout: "vertical",

    leftAxisTickRotation: 0,
    leftAxisTruncateTickAt: 0,

    legendTranslateX: 0,
    legendTranslateY: -50,
  }

  // lets calculate every setting
  // This can and should be fulfilled more after proper testing (note by S 25.6.2024)
  if(screenWidth <= 800) {
    optimizedSettings.legendLayout = "column";
    optimizedSettings.legendPosition = "top";
    optimizedSettings.layout= "horizontal";
    optimizedSettings.marginTop = 50 + contentCount * 25;
    optimizedSettings.marginLeft = 50;
    optimizedSettings.marginRight = 10;
    optimizedSettings.marginBottom = 50;
    optimizedSettings.leftAxisTickRotation = 90;
    optimizedSettings.leftAxisTruncateTickAt = 20;
    optimizedSettings.containerHeight = Math.floor(optimizedSettings.containerHeight + optimizedSettings.marginBottom);
    optimizedSettings.chartHeight = Math.floor(optimizedSettings.containerHeight * 0.9 - optimizedSettings.marginBottom);
    optimizedSettings.chartWidth = screenWidth * 0.98
    optimizedSettings.legendTranslateX = 0;
    optimizedSettings.legendTranslateY = -(optimizedSettings.marginTop);
  }
  if(screenWidth > 800) {
    optimizedSettings.legendLayout = "row";
    optimizedSettings.legendPosition = "top";
    optimizedSettings.layout= "vertical";
    //optimizedSettings.marginRight = Math.floor(optimizedSettings.chartWidth * 0.3);
    optimizedSettings.legendTranslateX = 0;
    optimizedSettings.legendTranslateY = -50;
    //optimizedSettings.chartWidth = Math.floor(screenWidth * 0.8);
    optimizedSettings.chartWidth = Math.floor(screenWidth - 256);
  }

  logger(optimizedSettings);

  return optimizedSettings;
}