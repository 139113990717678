import { isValidDiscount } from "../../Validators/helperFunctions";
import { logger } from "../../services/logger"
import { formatDiscount } from "../FormatData/formatDiscount";

export const createDiscounts = async (data, headers) => {
  logger("Starting to create discounts...");

  try {
    if(headers.length < 1) {
      logger("Creating discounts failed. Reason: Length of data is too short.");
      logger(console.trace());
      return [];
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.has("discounts")) {
      logger("Shared discounts found!");

      let sharedDiscounts = urlParams.get("discounts");
      sharedDiscounts = JSON.parse(sharedDiscounts);

      logger("Shared discounts are:");
      logger(sharedDiscounts);
      
      return sharedDiscounts;
    }

    let foundDiscount = headers.filter(header => header.type === "discount").length > 0;
    logger("Discount involved in excel sheet: " + foundDiscount);

    let discounts = [];

    if(foundDiscount) {

      let indexZeroHeaderValues = data
        .map(dataEntry => dataEntry[headers[0]?.uniqueKey])
        .filter(value => value !== undefined);

      let uniqueIndexZeroHeaderValues = [...new Set(indexZeroHeaderValues)];

      for (const uniqueValue of uniqueIndexZeroHeaderValues) {
        let percentage = 0;
        let matchingRows = data.filter(dataEntry => dataEntry[headers[0].uniqueKey] === uniqueValue);

        for (const possibleObjectWithDiscount of matchingRows) {
          let discountKeys = Object.keys(possibleObjectWithDiscount).filter(objectKey => objectKey.includes("discount"));
          
          if(discountKeys.length > 0) {
            let discountKey = Object.keys(possibleObjectWithDiscount).filter(objectKey => objectKey.includes("discount"))[0];
            let possibleRowWithPercentage = matchingRows.find(includesDiscount => includesDiscount[discountKey] !== undefined);
            
            if(possibleRowWithPercentage && isValidDiscount(possibleRowWithPercentage[discountKey])) {
              percentage = await formatDiscount(possibleRowWithPercentage[discountKey]);
            };
          }
        }
        
        let discountObject = {
          label: uniqueValue,
          discount: percentage,
          key: headers[0].uniqueKey,
        }

        discounts.push(discountObject);
      }
    }

    let cleanedDiscounts = discounts.map((discount) => {
      if(discount.label.trim() === "") {
        discount.label = "No value";
      }
      return discount;
    })
    
    logger("Discounts created and cleaned! List of discounts:");
    logger(cleanedDiscounts);

    return cleanedDiscounts;
  }
  catch(error) {
    logger("Catched error when creating discounts!");
    logger(error);
    logger(console.trace());
    return [];
  }
};