import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, IconButton, Collapse } from "@mui/material";
import Category from "./Category";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const FilterSection = ({ comparisonData, addFilter, removeFilter, removeAllFilters }) => {
  const [showFiltersSection, setShowFiltersSection] = React.useState(true);

  const toggleFilterShow = () => {
    setShowFiltersSection(!showFiltersSection);
  }

  return(
    <>
      <Grid xs={12} mt={0}>
        <Stack direction={"row"}>
          <IconButton color="primary" onClick={() => toggleFilterShow()}>
            {showFiltersSection ? 
              <KeyboardArrowUpIcon />
            :
              <KeyboardArrowDownIcon />
            }
          </IconButton>
          <Typography sx={{marginTop: "auto", marginBottom: "auto"}}><b><TranslationContent contentID="filters" /></b></Typography>
          <Button onClick={() => removeAllFilters()} size="small" variant="text" sx={{marginRight: 0, marginLeft: "auto"}}><TranslationContent contentID="removeFilters" /></Button>
        </Stack>
      </Grid>

      <Grid xs={12}>
        <Collapse in={showFiltersSection}>
          <Grid container columnSpacing={2} rowSpacing={2} sx={{borderBottom: "solid"}}>
            {comparisonData.uniqueCategories.map((category, index) => 
              <Category category={category} key={category+index} comparisonData={comparisonData} removeFilter={removeFilter} addFilter={addFilter} />
            )}
          </Grid>
        </Collapse>
      </Grid>
    </>
  )
}

export default FilterSection;