import React from "react"

export const SessionContext = React.createContext()
export const SessionDispatchContext = React.createContext()

const initialSession = {
  name: "",
  email: "", 
  activeGroup: "",
  showAdminTools: false,
};


function sessionReducer(session, action) {
  switch(action.type) {
    case "UPDATE_SESSION": {
      let sessionCopy = {...session};
      sessionCopy = action.value;
      sessionStorage.setItem("session", JSON.stringify(sessionCopy));
      return {
        ...sessionCopy,
      }
    }
    case "REMOVE_SESSION": {
      sessionStorage.clear();
      let sessionCopy = {...session};
      sessionCopy.name = "";
      sessionCopy.email = "";
      sessionCopy.activeGroup = "";
      sessionCopy.showAdminTools = false;
      return {
        ...session,
        ...sessionCopy,
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const checkSessionStorageSession = (initialSession)  => {
  const session = JSON.parse(sessionStorage.getItem("session"));
  if(session !== null) {
    initialSession = session;
  }
  return initialSession;
}

const SessionProvider = ({ children }) => {
  const [session, dispatch ] = React.useReducer(sessionReducer, initialSession, checkSessionStorageSession);

  return(
    <SessionContext.Provider value={session}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionContext.Provider>
  )
}

export default SessionProvider;