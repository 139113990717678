import { logger } from "../../services/logger";

export const createCategories = async (headers) => {
  logger("Starting to create categories...");

  if(headers.length < 1) {
    logger("Creating categories failed. Reason: Length of data is too short.");
    return [];
  };

  let categories = [];

  categories = headers.map(header => {
    return header?.category;
  })

  let uniqueCategories = [...new Set(categories)];

  logger("Categories created. The list of categories:");
  logger(uniqueCategories);

  return uniqueCategories;
};