import { createHeaders } from "./CreateData/createHeaders";
import { createCategories } from "./CreateData/createCategories";
import { createDiscounts } from "./CreateData/createDiscounts";
import { createDataValues } from "./CreateData/createDataValues";
import { createFilters } from "./CreateData/createFilters";
import { logger } from "../services/logger";


 export const initializeData = async (data) => {

  let headers         = await createHeaders(data);
  let categories      = await createCategories(headers);
  let discounts       = await createDiscounts(data, headers);
  let initDataValues  = await createDataValues(data, headers, discounts);
  let filters         = await createFilters();

  logger(initDataValues)

  let initData = {
    headers: [...headers],
    categories: [...categories],
    discounts: [...discounts],
    data: [...initDataValues],
    filters: [...filters],
  }

  return initData;
}