import React from "react";
import { Box, Stack, Typography, IconButton, Collapse,  } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import TranslationContent from "../../../Translations/TranslationContent";
import { VariableSizeList as List } from 'react-window';
import { useViewport } from "../../../Context/ViewportProvider";


const WarningContent = ({ dataEntries }) => {
  let [showContent, setShowContent] = React.useState(false);
  const { height } = useViewport();

  const countWarnings = () => {
    let warningCount = 0;
    dataEntries.forEach(data => {
      warningCount = warningCount + data.warnings.length;
    })
    return warningCount;
  }

  React.useEffect(() => {
    countWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if(dataEntries.length > 0 && countWarnings() > 0) {
    return(
      <Box sx={{bgcolor: "#FFF6E8", border: "1px #FF9800 solid", padding: "5px", mt: "10px"}}>
        <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
          <ReportIcon color="error"/>
          <Typography sx={{color: "black"}} variant="body2">{countWarnings()} <TranslationContent contentID="warningsFound" /></Typography>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{color: "#FF9800", marginRight: 0,marginLeft: "auto !important", display: "flex"}}
            onClick={() => setShowContent(!showContent)}
          >
            {showContent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Stack>
        <Collapse in={showContent} timeout="auto" unmountOnExit> 
          <List
            height={height * 0.5} // This needs to be a number, otherwise error!
            itemCount={dataEntries.length}
            itemSize={(index) => getItemSize(index, dataEntries)} // This needs to be a function, otherwise error!
            width={"100%"} // This can be %
            itemData={dataEntries} // The way to pass data entries to Row component
          >
            {WarningRow}
          </List>
          <br />
        </Collapse>
      </Box>
    )
  }
}

export default WarningContent;


const getItemSize = (index, data) => {
  const dataEntry = data[index];
  return dataEntry.warnings.length > 0 ? 50 + dataEntry.warnings.length * 20 : 0;
};


const WarningRow = ({ index, style, data }) => {
  const dataEntry = data[index];

  if (dataEntry.warnings.length === 0) {
    return null;
  }

  return (
    <div style={{ ...style, paddingTop: '20px' }} key={`${dataEntry?.value}-${index}-warning`}>
      <Stack direction="row" spacing={1} sx={{ paddingLeft: '5px' }}>
        <FmdBadIcon fontSize="small" color="error" sx={{ alignSelf: 'center' }} />
        <Typography sx={{ color: 'black' }}>
          {dataEntry.location} (<TranslationContent contentID={dataEntry.entryType} />): {dataEntry.value}
        </Typography>
      </Stack>
      {dataEntry.warnings.map((warning, warningIndex) => (
        <Stack key={`${warning}-${index}-${warningIndex}`} direction="row" spacing={1} sx={{ paddingLeft: '30px' }}>
          <PriorityHighIcon fontSize="small" color="error" />
          <Typography sx={{ color: 'black' }} variant="caption">
            <TranslationContent contentID={warning} />
          </Typography>
        </Stack>
      ))}
    </div>
  );
};