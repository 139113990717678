import React from "react";
import { useNavigate } from "react-router-dom";
import LandingLayout from "../../Layouts/LandingLayout";
import { Paper, Button, Typography, Box } from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { logger } from "../../services/logger";


const Login = () => {
  const [error, setError] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    if(isAuthenticated) {
      setShowLoader(true);
      navigate("/roles")
    }
    else {
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAuthenticated])

  const handleLogin = () => {
    setShowLoader(true)
    logger("User is authenticated: " + isAuthenticated);
    instance.loginRedirect(loginRequest)
      .then(response => {
        logger(response);
      })
      .catch(error => {
        logger(error);
        setError(error);
      })
  }

  if(showLoader) {
    return(
      <LandingLayout>
        <Box sx={{textAlign: "center", marginTop: "25vh", marginBottom: "25vh"}}>
          <LoadingSpinner />
        </Box>
      </LandingLayout>
    )
  }
  else {
    return(
      <LandingLayout>
        <Paper sx={{maxWidth: "500px", margin: "auto", textAlign: "center", marginTop: "25px"}}>
          <br />
          <Typography variant="h5"><TranslationContent contentID="loginHeader" /></Typography>
          <Typography variant="caption"><TranslationContent contentID="loginCaption" /></Typography>
          <br />
          <br />
          {error && 
            <>
              <Typography variant="caption" color="error"><TranslationContent contentID="loginFailed"/></Typography>
              <br />
              <br />
            </>
          }
          <Button variant="contained" sx={{width: "225px"}} onClick={() => handleLogin()}><TranslationContent contentID="loginButton"/></Button>
          <br />
          <br />
          <br />
        </Paper>
      </LandingLayout>
    )
  }
}

export default Login;