import React from "react";
import { FormGroup, FormControlLabel, Radio } from "@mui/material";
import SettingContainer from "../../Settings/SettingContainer";


const PieValueSettings = ({ numericFilters, selectedValueButton, updatePieValues }) => {
  const [showSettings, setShowSettings] = React.useState(false);

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="value">
      <FormGroup sx={{ ml: 1.5 }}>
        {numericFilters.map((numericFilter, index) => 
          <FormControlLabel
            key={numericFilter.objectKey + index} 
            label={numericFilter.objectKey.split(":")[0]}
            control={
              <Radio 
                checked={selectedValueButton === numericFilter.objectKey} 
                onChange={() => updatePieValues(numericFilter.objectKey)}
                size="small"
              />
            }
          />
        )}
      </FormGroup>
    </SettingContainer>
  )
}

export default PieValueSettings;