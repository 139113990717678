import React from "react";

export const ViewportContext = React.createContext();


//export const useViewport = () => useContext(ViewportContext);

export const useViewport = () => {
  const { width, height } = React.useContext(ViewportContext);
  //console.log(width)
  return { width, height };
}

 const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowRezise = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowRezise);
    return () => window.removeEventListener("resize", handleWindowRezise);
  }, []);

  //console.log(width, height)
 
  
  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
}

export default ViewportProvider