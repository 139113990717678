import React from "react";
import TranslationContent from "../../../../Translations/TranslationContent";
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";


const RadioSetting = ({ label, value, handleChange, optionKey, disabled }) => {
  return(
    <FormControl size="small">
      <FormLabel id={`radio-buttons-group-label-for-${label}`} sx={{marginTop: "-5px"}}><Typography variant="caption"><TranslationContent contentID={label} /></Typography></FormLabel>
      <RadioGroup
        row
        aria-labelledby={`radio-buttons-group-label-for-${label}`}
        name={`radio-buttons-group-for-${label}`}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
      >
        {allOptions[optionKey].map(option =>
          <FormControlLabel 
            disabled={disabled || false}
            key={option.value}
            disableTypography
            value={option.value}
            size="small"
            control={<Radio size="small" />} 
            label={<Typography variant="body2"><TranslationContent contentID={option.label} /></Typography>}
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioSetting;

const allOptions = {

  legendLayoutOptions: [
    {value: "row", label: "row"},
    {value: "column", label: "column"},
  ],

  yScaleTypeOptions: [
    {value: "linear", label: "linear"},
    {value: "point", label: "point"},

  ],

  barGroupModeOptions: [
    {value: "grouped", label: "grouped"},
    {value: "stacked", label: "stacked"},
  ],

  barLayoutOptions: [
    {value: "horizontal", label: "horizontal"},
    {value: "vertical", label: "vertical"},
  ],

}