// ************ HELPERS FUNCTIONS FOR VALIDATION ************


// The date needs to be in the format of year-month-day i.e. 2024-7-21
// This can be changed in the excel type settings!

export const isValidDate = (dateValue) => {
  /*
  if(typeof dateString !== "string") {
    return false;
  }

  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }
  const date = new Date(dateString);
  return !isNaN(date.getTime()) && date.toISOString().startsWith(dateString);
  */
 return isDateObject(dateValue)
};

const isDateObject = (value) => {
  return value instanceof Date && !isNaN(value);
};


// *************************************************************************************************************


const VALID_BOOLEAN_VALUES = ["nie", "tak", "kyllä", "ei", "yes", "no", "true", "false"];

export const isValidBoolean = (booleanString) => {

  if(typeof booleanString !== "string") {
    return false;
  }

  return VALID_BOOLEAN_VALUES.includes(booleanString.toLowerCase());
}


// *************************************************************************************************************


export const isValidDiscount = (discountString) => {

  let initDiscountString = discountString.toString();

  if(typeof initDiscountString !== "string") {
    return false;
  }

  if(hasMultipleCommas(initDiscountString)) {
    return false;
  }


  if(initDiscountString.includes(",")) {
    initDiscountString = initDiscountString.replace(",",".");
  }

  return Number(initDiscountString) >= 0 && Number(initDiscountString) <= 100;
}


// *************************************************************************************************************


export const isValidNumber = (numberString) => {
  let initNumberString = numberString.toString().trim();

  if (typeof initNumberString !== "string") {
    return false;
  }

  if (hasMultipleCommas(initNumberString)) {
    return false;
  }

  if (initNumberString.includes(",")) {
    initNumberString = initNumberString.replace(",", ".");
  }

  const numberValue = Number(initNumberString);
  return !Number.isNaN(numberValue) && Number.isFinite(numberValue) && numberValue > 0;
}


// *************************************************************************************************************


const hasMultipleCommas = (str) => {
  const matches = str.match(/,/g);
  return matches && matches.length > 1;
};


// *************************************************************************************************************