import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, Divider, Box, IconButton, Collapse, ButtonGroup, Tooltip, Backdrop } from "@mui/material";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import TableManager from "./DataTable";
import DownloadMenu from "../Charts/DownloadMenu";
import TranslationContent from "../../../Translations/TranslationContent";
import { ExportContext, ExportDispatchContext } from "../../../Context/ExportProvider";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { toPng } from 'html-to-image';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/system';
import { logger } from "../../../services/logger";
import { useViewport } from "../../../Context/ViewportProvider";


const GridContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  width: "99%",
  //border: '1px solid #ccc',
  //padding: theme.spacing(1),
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  //zIndex: theme.zIndex.drawer + 1,
  zIndex: theme.zIndex.drawer,
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //border: '1px solid #ccc',
}));


const TableSection = ({ comparisonData, chartData, createChartData, isOverlayVisible }) => {
  const [showTableSection, setShowTableSection] = React.useState(true);
  const [expandAll, setExpandAll] = React.useState(false);
  const exportContent = React.useContext(ExportContext);
  const updateExportContent = React.useContext(ExportDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const { width } = useViewport();

  const toggleTableShow = () => {
    setShowTableSection(!showTableSection);
  }

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  }

  let addImageToExportContent = () => {
    let updatedExportContent = [...exportContent];
    let newContent = {
      type: "text",
      content: "Oops! Error when adding data table to export list!"
    }
    var node = document.getElementById('table-container');
    toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        newContent = {
          type: "table",
          content: dataUrl
        }
        updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]});
        addAlert({message: "snackbarItemAddedToExportList", type: "success"});
  })
  .catch(function (error) {
    logger('oops, something went wrong!', error);
    newContent = {
      type: "text",
      content: "Oops! Error when adding data table to export list!"
    }
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]});
    addAlert({message: "snackbarItemAddedToExportListFailed", type: "error"});
  });
  }

  return(
    <GridContainer container rowSpacing={2} mt={5}>
      <Grid xs={12}>
        <Stack direction={"row"}>
          <IconButton color="primary" onClick={() => toggleTableShow()}>
            {showTableSection ? 
              <KeyboardArrowUpIcon />
            :
              <KeyboardArrowDownIcon />
            }
          </IconButton>
          <Typography sx={{marginTop: "auto", marginBottom: "auto"}}><b><TranslationContent contentID="table" /></b></Typography>
          <ButtonGroup
            sx={{marginRight: 0, marginLeft: "auto", verticalAlign: "middle"}}
            orientation={width > 500 ? "horizontal" : "vertical"}
          >
            <Tooltip title={<TranslationContent contentID="addTableToExportList" />} arrow >
              <Button variant="contained" 
               sx={{
                width: width > 500 ? "" : "70px",
                mb: width > 500 ? "" : "3px",
                marginRight: 1,
                marginLeft: "auto", 
                height: "32px"}} 
                onClick={() => addImageToExportContent()}
                disabled={!showTableSection}
                >
                <PlaylistAddCircleIcon />
              </Button>
            </Tooltip>
            <DownloadMenu
              domElement="table-container"
              disabled={!showTableSection}
            />
            <Tooltip title={<TranslationContent contentID={expandAll ? "closeAllTableRows" : "expandAllTableRows"} />} arrow >
              <Button 
                onClick={() => toggleExpandAll()}
                variant="contained" 
                sx={{
                  width: width > 500 ? "" : "70px",
                  mb: width > 500 ? "" : "3px",
                  marginRight: 1,
                  marginLeft: "auto", 
                  height: "32px"
                }} 
                disabled={!showTableSection}
              >
                {!expandAll ? <UnfoldMoreDoubleIcon /> : <UnfoldLessDoubleIcon />}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{marginBottom: "1vh"}}/>
      </Grid>
      <Grid xs={12}>
        <Collapse in={showTableSection}>
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <TableManager chartData={chartData} comparisonData={comparisonData} expandAll={expandAll} />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
      <CustomBackdrop open={isOverlayVisible && showTableSection}>
        <Stack spacing={2} bgcolor={"#121212"} p={5}>
          <Typography><b><TranslationContent contentID="filtersChanged" /></b></Typography>
          <Button variant="contained" color="primary" onClick={() => createChartData()}>
            <RefreshIcon />&nbsp;&nbsp;<TranslationContent contentID="update" />
          </Button>
        </Stack>
      </CustomBackdrop>
    </GridContainer>
  )
}

export default TableSection;