import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TranslationContent from "../../../Translations/TranslationContent";


const SuccessContent = ({ dataEntries, error }) => {
  if(dataEntries.length === 0 && !error) {
    return(
      <Box sx={{ bgcolor: "#E3F3E4", border: "1px #66BB6A solid", padding: "5px"}}>
        <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
          <DoneAllIcon sx={{color: "#66BB6A" }}/>
          <Typography sx={{color: "black"}} variant="body2">
            <TranslationContent contentID="noErrorsInFile" />
          </Typography>
        </Stack>
      </Box>
    )
  }
  else return null;
}

export default SuccessContent;