import { logger } from "../../services/logger";

export const initializeChartData = async (data, filters) => {
  logger("Starting to initialize chart data...");

  let chartData = [];

  //let chartDataWithUniqueValues = [];

  if(filters.length < 1) {
    logger("Data has no filters, continue to check duplicates");
    //chartData = await duplicateChecker({filteredData: data, data: data, filters: [...filters]});
    return chartData;
  }

  // filter every item
  chartData = data.filter(row => {
    for (const filter of filters) {
      if (filter?.objectKey?.split(":")[2] === "string") {
        if (!filter.values.includes(row[filter.objectKey])) {
          return false; // If any filter condition fails, exclude the row
        }
      }
    }
    return true; // Include the row if all filter conditions are met
  });

  //chartDataWithUniqueValues = await duplicateChecker({filteredData: chartData, data: data, filters: [...filters]});

  logger("Chart data initialization complete!");
  logger(chartData);

  return chartData;

  //return chartDataWithUniqueValues;
};