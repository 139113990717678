import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SelectSetting from "../../Settings/SelectSetting";
import SettingDivider from "../../Settings/SettingDivider";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";
import { Collapse, Stack, Typography } from "@mui/material";
import SliderSetting from "../../Settings/SliderSetting";
import SwitchSetting from "../../Settings/SwitchSetting";


const PieStyleSettings = 
  ({
    chartColors,
    updateChartColors,

    startAngle,
    updateStartAngle,
    endAngle,
    updateEndAngle,
    fit,
    updateFit,
    innerRadius,
    updateInnerRadius,
    padAngle,
    updatePadAngle,
    cornerRadius,
    updateCornerRadius,
    sortByValue,
    updateSortByValue,

    enableArcLabels,
    updateEnableArcLabels,
    arcLabel,
    updateArcLabel,
    arcLabelsRadiusOffset,
    updateArcLabelRadiusOffset,
    arcLabelsSkipAngle,
    updateArcLabelSkipAngle,

    enableArcLinkLabels,
    updateEnableArcLinkLabels,
    arcLinkLabel,
    updateArcLinkLabel,
    arcLinkLabelsOffset,
    updateArcLinkLabelsOffset,
    arcLinkLabelsSkipAngle,
    updateArcLinkLabelsSkipAngle,
    arcLinkLabelsDiagonalLength,
    updateArcLinkLabelsDiagonalLength,
    arcLinkLabelsStraightLength,
    updateLinkLabelsStraightLength,
    arcLinkLabelsTextOffset,
    updateArcLinkLabelsTextOffset,
    arcLinkLabelsThickness,
    updateArcLinkLabelsThickness,

  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="style">
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="colors" />
          </b>
        </Typography>
        <SelectSetting 
          label="colorScheme"
          value={chartColors}
          handleChange={updateChartColors}
          optionKey="colorOptions"
        />
      </Stack>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, mr: -1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="arcs" />
          </b>
        </Typography>
        <SwitchSetting 
          label="sort"
          value={sortByValue}
          handleChange={updateSortByValue}  
        />
        <SwitchSetting 
          label="fit"
          value={fit}
          handleChange={updateFit}  
        />
      </Stack>
      <SliderSetting
        label="startAngle"
        icon="startAngle"
        minValue={-180}
        maxValue={360}
        step={10}
        value={startAngle}
        updateValue={updateStartAngle}
        variableType="degreeMark"
      />
      <SliderSetting
        label="endAngle"
        icon="endAngle"
        minValue={-360}
        maxValue={360}
        step={10}
        value={endAngle}
        updateValue={updateEndAngle}
        variableType="degreeMark"
      />
      <SliderSetting
        label="innerRadius"
        icon="innerPieRadius"
        minValue={0}
        maxValue={0.95}
        step={0.05}
        value={innerRadius}
        updateValue={updateInnerRadius}
        variableType=""
      />
      <SliderSetting
        label="padAngle"
        icon="padAngle"
        minValue={0}
        maxValue={45}
        step={5}
        value={padAngle}
        updateValue={updatePadAngle}
        variableType="degreeMark"
      />
      <SliderSetting
        label="cornerRadius"
        icon="pieCornerRadius"
        minValue={0}
        step={5}
        maxValue={45}
        value={cornerRadius}
        updateValue={updateCornerRadius}
        variableType="px"
      />
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="arcLabel" />
          </b>
        </Typography>
        <SwitchSetting 
          label="show"
          value={enableArcLabels}
          handleChange={updateEnableArcLabels}  
        />
        <SelectSetting 
          label="content"
          value={arcLabel}
          handleChange={updateArcLabel}
          optionKey="arcLabelOptions"
          disabled={!enableArcLabels}
        />
      </Stack>
      <Collapse in={enableArcLabels}>
        <SliderSetting
          label="arcLabelsRadiusOffset"
          icon="yOffset"
          minValue={0}
          step={0.1}
          maxValue={2}
          value={arcLabelsRadiusOffset}
          updateValue={updateArcLabelRadiusOffset}
          variableType=""
        />
        <SliderSetting
          label="arcLabelsSkipAngle"
          icon="angleToShowLabel"
          minValue={0}
          step={5}
          maxValue={45}
          value={arcLabelsSkipAngle}
          updateValue={updateArcLabelSkipAngle}
          variableType="degreeMark"
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="arcLinkLabel" />
          </b>
        </Typography>
        <SwitchSetting 
          label="show"
          value={enableArcLinkLabels}
          handleChange={updateEnableArcLinkLabels}  
        />
        <SelectSetting 
          label="content"
          value={arcLinkLabel}
          handleChange={updateArcLinkLabel}
          optionKey="arcLabelOptions"
          disabled={!enableArcLinkLabels}
        />
      </Stack>
      <Collapse in={enableArcLinkLabels}>
        <SliderSetting
          label="arcLabelsSkipAngle"
          icon="angleToShowLabel"
          minValue={0}
          step={5}
          maxValue={45}
          value={arcLinkLabelsOffset}
          updateValue={updateArcLinkLabelsOffset}
          variableType="degreeMark"
        />
        <SliderSetting
          label="arcLabelsRadiusOffset"
          icon="yOffset"
          minValue={-50}
          step={1}
          maxValue={50}
          value={arcLinkLabelsSkipAngle}
          updateValue={updateArcLinkLabelsSkipAngle}
          variableType="px"
        />
        <SliderSetting
          label="arcLinkLabelsDiagonalLength"
          icon="straigten"
          minValue={0}
          step={1}
          maxValue={36}
          value={arcLinkLabelsDiagonalLength}
          updateValue={updateArcLinkLabelsDiagonalLength}
          variableType="px"
        />
        <SliderSetting
          label="arcLinkLabelsStraightLength"
          icon="straigten"
          minValue={0}
          step={1}
          maxValue={36}
          value={arcLinkLabelsStraightLength}
          updateValue={updateLinkLabelsStraightLength}
          variableType="px"
        />
        <SliderSetting
          label="arcLinkLabelsTextOffset"
          icon="textOffsetX"
          minValue={0}
          step={1}
          maxValue={36}
          value={arcLinkLabelsTextOffset}
          updateValue={updateArcLinkLabelsTextOffset}
          variableType="px"
        />
        <SliderSetting
          label="arcLinkLabelsThickness"
          icon="lineWeight"
          minValue={0}
          step={1}
          maxValue={20}
          value={arcLinkLabelsThickness}
          updateValue={updateArcLinkLabelsThickness}
          variableType="px"
        />
      </Collapse>
    </SettingContainer>
  )
}

export default PieStyleSettings;