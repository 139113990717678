import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider, Stack } from "@mui/material";
import { useViewport } from "../../Context/ViewportProvider";
import CompanyLogo from "../Utils/GlobalComponents/CompanyLogo";
import TranslationContent from "../../Translations/TranslationContent";


const LandingHeader = () => {
  return(
    <AppBar position="static" sx={{minWidth: "100vw", maxWidth: "100vw",}}>
      <Container maxWidth="100%" >
        <Toolbar disableGutters>
          <MainTitle />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default LandingHeader;


const MainTitle = () => {
  const { width} = useViewport();
  if(width < 500) { 
    return (
      <Stack direction="row" spacing={2} sx={{padding: "5px", margin: "auto"}}>
        <CompanyLogo width={155} showAppLogo={true} />
        <Divider orientation="vertical" flexItem/>
        <Typography sx={{width: "155px", marginBottom: "auto", marginTop: "auto", alignSelf: "center", color: "#fc2516", fontSize: "13px", marginLeft: "5px"}}><TranslationContent contentID="mainTitle" /></Typography>
      </Stack>
    )
  }
  else {
    return(
      <Stack direction="row" spacing={2} sx={{padding: "5px"}}>
        <CompanyLogo width={155} showAppLogo={true} />
        <Divider orientation="vertical" flexItem/>
        <Typography sx={{alignContent: "center", alignSelf: "center", marginBottom: "auto", margin: "auto", display: "flex"}} color="primary" variant="h5"><TranslationContent contentID="mainTitle" /></Typography>
      </Stack>
    )
  }
  
}