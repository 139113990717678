import { logger } from "../../services/logger";


export const createHeaders = async (data) => {
  logger("Starting to create headers...");

  if(data?.length < 1) {
    logger("Creating headers failed. Reason: Length of data is too short.");
    return [];
  };

  let objectKeys = data.flatMap(dataEntry => Object.keys(dataEntry));
  let uniqueObjectKeys = [...new Set(objectKeys)];
  let headers = [];

  const splitHeaderInfo = (uniqueObjectKey) => {
    let [name, category, type] = uniqueObjectKey.split(":");
    let newHeader = {
      uniqueKey: uniqueObjectKey,
      name: name || '',
      category: category || '',
      type: type || '',
    };
    headers.push(newHeader);
  };

  uniqueObjectKeys.forEach(splitHeaderInfo);

  logger("Headers created. The list of headers:");
  logger(headers);

  return headers;
};