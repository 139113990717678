import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ExportContext, ExportDispatchContext } from "../../Context/ExportProvider";
import TranslationContent from "../../Translations/TranslationContent";


const NoExportContent = () => {
  const exportContent = React.useContext(ExportContext);
  const updateExportContent = React.useContext(ExportDispatchContext);

  const handleContentAdd = () => {
    let updatedExportContent = [...exportContent];
    let newContent = {
      type: "",
      content: ""
    }
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]})
  }

  if(exportContent.length < 1) {
    return(
      <Box textAlign={"center"}>
        <br />
        <Typography><b><TranslationContent contentID="noContent" /></b></Typography>
        <br />
        <Typography><TranslationContent contentID="noContentDesc" /></Typography>
        <br />
        <Button variant="contained"  size="small" onClick={() => handleContentAdd()}>+ <TranslationContent contentID="addFirstItem" /></Button>
        <br />
      </Box>
    )
  }
}

export default NoExportContent;