import React from "react";
import { styled } from '@mui/material/styles';
import { Typography, Stack, InputAdornment, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../../../Translations/TranslationContent";
import MuiInput from '@mui/material/Input';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { ThemeModeStateContext } from "../../../../Theme/ThemeProvider";


const Input = styled(MuiInput)`
  width: 50%;
`;

const ChartMarginSettings = ({ topMargin, bottomMargin, rightMargin, leftMargin, updateMargin }) => {
  const [focusedInput, setFocusedInput] = React.useState("");
  const currentTheme = React.useContext(ThemeModeStateContext);

  return(
    <Grid container sx={{ml: 1.5}}>
      <Grid xs={12}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 0, width: "80px"}}>
          <b><TranslationContent contentID="margins" /></b></Typography>
      </Grid>
      <Grid xs={12} md={4} sx={{textAlign: "center", justifyContent: "center", display: "flex"}}>
        <Stack sx={{textAlign: "center", justifyContent: "center", width: "100%"}}>
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <Typography variant="caption" sx={{width: "30%", textAlign: "justify"}}>
              <TranslationContent contentID="top" />
            </Typography>
            <Input
              onFocus={() => setFocusedInput("top")}
              onBlur={() => setFocusedInput("")}
              value={topMargin}
              size="small"
              onChange={(event) => updateMargin(event.target.value, "top")}
              endAdornment={<InputAdornment position="end"><TranslationContent contentID="px" /></InputAdornment>}
              inputProps={{
                step: 10,
                min: -2000,
                max: 2000,
                type: 'number',
              }}
            />
          </Box>
          <div style={{height: "10px"}} />
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <Typography variant="caption" sx={{width: "30%", textAlign: "justify"}}>
              <TranslationContent contentID="bottom" />
            </Typography>
            <Input
              onFocus={() => setFocusedInput("bottom")}
              onBlur={() => setFocusedInput("")}
              value={bottomMargin}
              onChange={(event) => updateMargin(event.target.value, "bottom")}
              size="small"
              endAdornment={<InputAdornment position="end"><TranslationContent contentID="px" /></InputAdornment>}
              inputProps={{
                step: 10,
                min: -2000,
                max: 2000,
                type: 'number',
              }}
            />
          </Box>
        </Stack>
      </Grid>

      <Grid xs={12} md={4} sx={{textAlign: "center", justifyContent: "center", display: "flex"}}>
        <Stack sx={{textAlign: "center", justifyContent: "center", width: "100%"}}>
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <Typography variant="caption" sx={{width: "30%", textAlign: "justify"}}>
              <TranslationContent contentID="right" />
            </Typography>
            <Input
              onFocus={() => setFocusedInput("right")}
              onBlur={() => setFocusedInput("")}
              value={rightMargin}
              onChange={(event) => updateMargin(event.target.value, "right")}
              size="small"
              endAdornment={<InputAdornment position="end"><TranslationContent contentID="px" /></InputAdornment>}
              inputProps={{
                step: 10,
                min: -2000,
                max: 2000,
                type: 'number',
              }}
            />
          </Box>
          <div style={{height: "10px"}} />
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <Typography variant="caption" sx={{width: "30%", textAlign: "justify"}}>
              <TranslationContent contentID="left" />
            </Typography>
            <Input
              onFocus={() => setFocusedInput("left")}
              onBlur={() => setFocusedInput("")}            
              value={leftMargin}
              onChange={(event) => updateMargin(event.target.value, "left")}
              size="small"
              endAdornment={<InputAdornment position="end"><TranslationContent contentID="px" /></InputAdornment>}
              inputProps={{
                step: 10,
                min: -2000,
                max: 2000,
                type: 'number',
              }}
            />
          </Box>
        </Stack>
      </Grid>

      <Grid xs={12} md={4} sx={{textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex"}}>
        <Box 
          sx={{
            height: "40px",
            width: "80px",
            border: "1px solid white",
            textAlign: "center", 
            alignContent: "center",
            borderTop: focusedInput === "top" ? "2px solid #fc2516" : currentTheme.theme === "light" ? "1px solid black" : "1px solid white",
            borderBottom: focusedInput === "bottom" ? "2px solid #fc2516" : currentTheme.theme === "light" ? "1px solid black" : "1px solid white",
            borderRight: focusedInput === "right" ? "2px solid #fc2516" : currentTheme.theme === "light" ? "1px solid black" : "1px solid white",
            borderLeft: focusedInput === "left" ? "2px solid #fc2516" : currentTheme.theme === "light" ? "1px solid black" : "1px solid white"
          }}>
            <InsertChartIcon />
          </Box>
      </Grid>
    </Grid>
  )
}

export default ChartMarginSettings;