import React from "react";
import TranslationContent from "../../../../Translations/TranslationContent";
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from "@mui/material";


const SelectSetting = ({ label, value, handleChange, optionKey, disabled }) => {
  
  return(
    <FormControl variant="standard" sx={{ minWidth: 150 }} size="small">
      <InputLabel id={`chart-settings-label-select-for-${label}`}><TranslationContent contentID={label} /></InputLabel>
      <Select
        labelId={`chart-settings-select-label-for-${label}`}
        id={`chart-settings-select-for-${label}`}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        label={<TranslationContent contentID={label} />}
        disabled={disabled || false}
      >
        {allOptions[optionKey]?.map(option =>
        typeof option === "object" ?
          <MenuItem key={option.value} value={option.value}><TranslationContent contentID={option.label} /></MenuItem>
          :
          <ListSubheader key={option}><TranslationContent contentID={option} /></ListSubheader>
      )}
      </Select>
    </FormControl>
  )
}

export default SelectSetting;

const allOptions = {

  legendPositionOptions: [
    {value: "top", label: "top"},  
    {value: "top-right", label: "topRight"},
    {value: "right", label: "right" },
    {value: "bottom-right", label: "bottomRight"},
    {value: "bottom", label: "bottom"},
    {value: "bottom-left", label: "bottomLeft"},
    {value: "left", label: "left"},
    {value: "top-left", label: "topLeft"},
    {value: "center", label: "center"}
  ],

  legendDirectionOptions: [
    {value: "left-to-right", label: "leftToRight"},  
    {value: "right-to-left", label: "rightToLeft"},
    {value: "top-to-bottom", label: "topToBottom" },
    {value: "bottom-to-top", label: "bottomToTop"},
  ],

  curveOptions: [
    {value: "basis", label: "basis"},
    {value: "cardinal", label: "cardinal"},
    {value: "catmullRom", label: "catmullRom"},
    {value: "linear", label: "linear"},
    {value: "monotoneX", label: "monotoneX"},
    {value: "monotoneY", label: "monotoneY"},
    {value: "natural", label: "natural"},
    {value: "step", label: "step"},
    {value: "stepAfter", label: "stepAfter"},  
    {value: "stepBefore", label: "stepBefore"},
  ],

  colorOptions: [
    "categoricalColors",
    {value: "nivo", label: "default"},
    {value: "category10", label: "category10"},
    {value: "accent", label: "accent"},
    {value: "dark2", label: "dark2"},
    {value: "paired", label: "paired"},
    {value: "pastel1", label: "pastel1"},
    {value: "pastel2", label: "pastel2"},
    {value: "set1", label: "set1"},
    {value: "set2", label: "set2"},
    {value: "set3", label: "set3"},
    {value: "tableau10", label: "tableau10"},
    "divergingColors",
    {value: "brown_blueGreen", label: "brown_blueGreen"},
    {value: "purpleRed_green", label: "purpleRed_green"},
    {value: "pink_yellowGreen", label: "pink_yellowGreen"},
    {value: "purple_orange", label: "purple_orange"},
    {value: "red_blue", label: "red_blue"},
    {value: "red_grey", label: "red_grey"},
    {value: "red_yellow_blue", label: "red_yellow_blue"},
    {value: "red_yellow_green", label: "red_yellow_green"},
    {value: "spectral", label: "spectral"},
    "sequentialColors",
    {value: "blues", label: "blues"},
    {value: "greens", label: "greens"},
    {value: "greys", label: "greys"},
    {value: "oranges", label: "oranges"},
    {value: "purples", label: "purples"},
    {value: "reds", label: "reds"},
    {value: "blue_green", label: "blue_green"},
    {value: "blue_purple", label: "blue_purple"},
    {value: "green_blue", label: "green_blue"},
    {value: "orange_red", label: "orange_red"},
    {value: "purple_blue_green", label: "purple_blue_green"},
    {value: "purple_blue", label: "purple_blue"},
    {value: "purple_red", label: "purple_red"},
    {value: "red_purple", label: "red_purple"},
    {value: "yellow_green_blue", label: "yellow_green_blue"},
    {value: "yellow_green", label: "yellow_green"},
    {value: "yellow_orange_brown", label: "yellow_orange_brown"},
    {value: "yellow_orange_red", label: "yellow_orange_red"},
  ],

  crosshairTypeOptions: [
    {value: "x", label: "x"},  
    {value: "y", label: "y"},  
    {value: "top", label: "top"},  
    {value: "top-right", label: "topRight"},
    {value: "right", label: "right" },
    {value: "bottom-right", label: "bottomRight"},
    {value: "bottom", label: "bottom"},
    {value: "bottom-left", label: "bottomLeft"},
    {value: "left", label: "left"},
    {value: "top-left", label: "topLeft"},
    {value: "cross", label: "cross"}
  ],

  tooltipTypeOptions: [
    {value: false, label: "all"},
    {value: "x", label: "x"},
    {value: "y", label: "y"},
  ],

  arcLabelOptions: [
    {value: "id", label: "identifier"},
    {value: "value", label: "value"},
    {value: `idAndValue`, label: "idAndValue"},
  ],

  barScaleType: [
    {value: "linear", label: "linear"},
    {value: "symlog", label: "symlog"},
  ],
}