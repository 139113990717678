import React from "react";
import { Box, Typography } from "@mui/material";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import TranslationContent from "../../Translations/TranslationContent";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";
import { logger } from "../../services/logger";


const RoleStatus = ({ activeAccount, activeAccountSelected }) => {
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if(activeAccount?.idTokenClaims?.groups?.length > 0) {
      logger(activeAccount);
      let updatedSession = {...session};
      updatedSession.name = activeAccount.name;
      updatedSession.email = activeAccount.username;
      updateSession({type: "UPDATE_SESSION", value: updatedSession});
      navigate("/home");
    }
    logger("User missing roles! He won't have access to application.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeAccount?.idTokenClaims?.groups?.length])

  if(activeAccount?.idTokenClaims?.groups?.length > 0 && activeAccountSelected) {
    return(
      <Box mt={5}>
        <LoadingSpinner />
      </Box>
    )
  }

  if(!Array.isArray(activeAccount?.idTokenClaims?.groups) || (activeAccountSelected && activeAccount?.idTokenClaims?.groups?.length === 0)) {
    return(
      <Box mt={5}>
        <GppMaybeIcon color="error" fontSize="large"/>
        <Typography textAlign={"center"} mx={5}><TranslationContent contentID="noGroupsAttachedErrorDesc" /></Typography>
      </Box>
    )
  }
  
  //if((/*activeAccountSelected && activeAccount?.idTokenClaims?.groups?.length < 1) || (*/activeAccount?.idTokenClaims?.groups?.length > 0 && activeAccountSelected)) {return null}
}

export default RoleStatus;