import React from "react";
import { Typography, Divider, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BooleanValue from "../../../Utils/GlobalComponents/BooleanValue";


const BarChartTooltip = ({ /*id, value, index,*/ indexValue, data, /*label*/ }) => {

  return(
    <Paper sx={{padding: "10px", maxWidth: "75%"}}>
      <Typography><b>{indexValue}</b></Typography>
      <Divider />
      <Grid container columnSpacing={1} rowSpacing={1}>
        {Object.keys(data).map(objectKey => 
          <ObjectItem key={objectKey} objectKey={objectKey} data={data}/>
        )}
      </Grid>
    </Paper>
  )
}

export default BarChartTooltip;


const ValueTypography = ({value}) => {
  if(typeof value === "boolean") {
    return <BooleanValue value={value} />
  }
  if(value !== "No value" && value !== "Incorrect value type") {
    return(
      <Typography variant="caption" sx={{opacity: 0.9}}>{value}</Typography>
    )
  }
  if(value === "No value") {
    return(
      <Typography variant="caption" sx={{opacity: 0.5}}><i>{value}</i></Typography>
    )
  }
  if(value === "Incorrect value type") {
    return(
      <Typography variant="caption" color="error">{value}</Typography>
    )
  }
  
}

const ObjectItem = ({ data, objectKey }) => {
  return(
      <Grid xs={6} md={4}>
      <Stack direction="column">
        <Typography variant="caption"><b>{objectKey.split(":")[0]}</b></Typography>
        <ValueTypography value={data[objectKey]} />
      </Stack>
      </Grid>
  )
}