import React from "react";
import { TextField } from "@mui/material";
import TranslationContent from "../../../../Translations/TranslationContent";

const TextSetting = ({ label, value, handleChange, disabled }) => {
  return(
    <TextField 
      id="standard-basic"
      label={<TranslationContent contentID={label} />}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      variant="standard"
      size="small"
      sx={{width: "60%"}}
      disabled={disabled || false}
      autoComplete="off"
    />
  )
}

export default TextSetting;