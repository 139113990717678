import React from "react";
import DataManagementDialog from "../DataManagementDialog";
import { useParams } from "react-router-dom";
import { Tokens } from "../../../services/Tokens";
import { logger } from "../../../services/logger";
import compareServices from "../../../services/compare";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { SessionContext } from "../../../Context/SessionProvider";
import { initializeData } from "../../../DataFactory/initializeData";
import "../loadingDots.css";
import CompareLoading from "./CompareLoading";
import CompareError from "./CompareError";
import CompareSuccess from "./CompareSuccess";
import GlobalLayout from "../../../Layouts/GlobalLayout";
import { useNavigate } from "react-router-dom";


const Compare = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const { id } = useParams();
  const [groupData, setGroupData] = React.useState({
    name: {
      fi: "",
      en: "",
    },
    role: "",
    type: "",
    country: "",
    saved: null,
    lastModifier: "",
  });

  const [comparisonData, setComparisonData] = React.useState(
    {
      //data: [...testData],
      
      //data: JSON.parse(JSON.stringify([...testData])),
      data: [],
      //filteredData: [],
      headers: 
        [
          /* Template
          {
            uniqueKey: "",
            name: "",
            category: "",
            type: "",
          },
          */
        ],
      uniqueCategories: [],
      filters: 
        [
          /* Template, first for string Autocomplete filters, second for other types which use Checkbox
          {
            values: [],
            objectKey: "",
          },
          {
            objectKey: "",
            value: Type(),
          }
          */
        ],
      discounts: 
        [
          /* Template
          {
            label: "",
            discount: "",
            key: "",
          },
          */
        ],
        preferredIdentifier: "",
    }
  );

  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.email.length < 1) {
      navigate("/");
      addAlert({message: "snackbarLoginToUseFiltersDesc", type: "info"});
      return;
    }
    if(fetchData.current) {
      fetchData.current = false;
      fetchComparisonData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchComparisonData = async () => {
    setLoading(true);
    setError(false);
  
    try {
      const tokens = await Tokens();
      const response = await compareServices.getData(id, tokens.accessToken, tokens.idToken);
      logger(response);
      let updatedGroupData = { ...groupData };
      updatedGroupData.country = response.country;
      updatedGroupData.saved = response.saved;
      updatedGroupData.lastModifier = response.lastModifier;
      updatedGroupData.name = { ...response.name };
      updatedGroupData.type = response.type;
      updatedGroupData.role = response.role;
      setGroupData(updatedGroupData);
  
      let initializedData = await initializeData(response.data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.discounts = [...initializedData.discounts];
      updatedState.filters = [...initializedData.filters];
      updatedState.preferredIdentifier = (response.preferredIdentifier);
      setComparisonData(updatedState);
      setLoading(false);

    } catch (error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
      addAlert({ message: "snackbarFetchingDataFailed", type: "error" });
    }
  };

  const uploadLocalData = async (data) => {
    setLoading(true);
    setError(false);
    toggleDialogShow(false);
    let updatedGroupData = {...groupData};
    updatedGroupData.country = "own";
    updatedGroupData.saved = Date.now();
    updatedGroupData.lastModifier = session.name;
    updatedGroupData.name = {fi: "Lokaalidata", en: "Local Data"};
    updatedGroupData.type = "local";
    updatedGroupData.role = "local";
    setGroupData(updatedGroupData);

    try {
      let initializedData = await initializeData(data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.discounts = [...initializedData.discounts];
      updatedState.filters = [...initializedData.filters];
      setComparisonData(updatedState);
      setLoading(false);
    }
    catch (error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
    }
  }

  const uploadData = async (data) => {
    setLoading(true);
    setError(false);
    toggleDialogShow(false);

    try {
      const tokens = await Tokens();
      const response = await compareServices.updateData(id, data, tokens.accessToken, tokens.idToken);
      logger(response);
      let updatedGroupData = {...groupData};
      updatedGroupData.country = response.country;
      updatedGroupData.saved = response.saved;
      updatedGroupData.lastModifier = response.lastModifier;
      updatedGroupData.name = {...response.name};
      updatedGroupData.type = response.type;
      updatedGroupData.role = response.role;
      setGroupData(updatedGroupData);

      let initializedData = await initializeData(response.data.data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.discounts = [...initializedData.discounts];
      updatedState.filters = [...initializedData.filters];
      setComparisonData(updatedState);
      setLoading(false);
    }
    catch(error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarFailedToUploadData", type: "error"});
    }
  };

  const addFilter = (objectKey, filters) => {
    let updatedState = {...comparisonData};

    const foundFilter = updatedState.filters.find(filter => filter.objectKey === objectKey);
    
    if(typeof foundFilter !== 'undefined') {
      if(filters.length > foundFilter.values.length) {
        foundFilter.values = [...new Set(foundFilter.values.concat(filters))]
      }
      else {
        foundFilter.values = foundFilter.values.filter(oldFilter => filters.includes(oldFilter));
      }
    }
    else {
      let categoryFirstTimeFilter = {
        objectKey: objectKey,
        values: filters,
      }
      updatedState.filters = [...updatedState.filters, categoryFirstTimeFilter]
    }
    updatedState.filters = updatedState.filters.filter(filter => filter.values.length > 0);
    setComparisonData(updatedState)
  }

  const removeFilter = (objectKey) => {
    let updatedFilters = {...comparisonData};
    updatedFilters.filters = [...comparisonData.filters]
    updatedFilters.filters = updatedFilters.filters.filter(filter => filter.objectKey !== objectKey);
    setComparisonData(updatedFilters);
  }

  const removeAllFilters = () => {
    let removedFilters = {...comparisonData};
    removedFilters.filters = removedFilters.filters.slice(removedFilters.filters.length)
    setComparisonData(removedFilters);
  }

  const removeAllDiscounts = () => {
    let discountsRemoved = [...comparisonData.discounts];
    discountsRemoved = discountsRemoved.map(discount => {
      discount.discount = 0;
      return discount;
    })
    let updatedState = {...comparisonData};
    updatedState.discounts = [...discountsRemoved];
    setComparisonData(updatedState);
  }

  const changeDiscount = async (event, label) => {
    let updatedDiscounts = await Promise.all(
      comparisonData.discounts.map(async oldDiscount => {
        if (oldDiscount.label === label) {
            oldDiscount.discount = event.target.value.replace(",",".")
        }
        return oldDiscount;
      })
    );
  
    let updatedComparisonData = { ...comparisonData };
    updatedComparisonData.discounts = [...updatedDiscounts];
    setComparisonData(updatedComparisonData);
  };

  const changeDiscountKey = (event) => {
    setLoading(true);

    let masterData = [...comparisonData.data];
    let keyHeader = comparisonData.headers.find(header => header.name === event.target.value);
    let newState = {...comparisonData}
    let foundDiscount = comparisonData.headers.filter(header => header.type === "discount").length > 0;
    
    logger("Discount involved in excel sheet: " + foundDiscount)

    if(foundDiscount) {
      let discounts = [];
      masterData.map(dataEntry => discounts.push(dataEntry[keyHeader.uniqueKey]));
      let uniqueIndexZeroHeaders = [...new Set(discounts)];
      let initializedDiscounts = [];
      uniqueIndexZeroHeaders.forEach(indexZeroHeader => {
        let percentage = 0;  
        let matchingObjects = masterData.filter(dataEntry => dataEntry[keyHeader.uniqueKey] === indexZeroHeader);
        matchingObjects.forEach(possibleObject => {
          if(Object.keys(possibleObject).filter(objectKey => objectKey.includes("discount")).length > 0) {
            let discountKey = Object.keys(possibleObject).filter(objectKey => objectKey.includes("discount"))[0];  
            let possiblePercentage = matchingObjects.find(includesDiscount => includesDiscount[discountKey]) !== undefined;
            if(possiblePercentage) {
              percentage = matchingObjects.find(includesDiscount => includesDiscount[discountKey])[discountKey]
            }
          }
        })
          
        let discountObject = {
          label: indexZeroHeader,
          discount: percentage,
          key: event.target.value,
        };

        initializedDiscounts.push(discountObject);

      })
      newState.discounts = [...initializedDiscounts];
      }
      else {
        let discounts = [];
        masterData.map(dataEntry => discounts.push(dataEntry[keyHeader.uniqueKey]));
        let uniqueIndexZeroHeaders = [...new Set(discounts)];
        let initializedDiscounts = [];

        uniqueIndexZeroHeaders.forEach(indexZeroHeader => {
          let discountObject = {
            label: indexZeroHeader,
            discount: 0,
            key: event.target.value,
          }

          initializedDiscounts.push(discountObject);

        });

        newState.discounts = [...initializedDiscounts];

      }
    setComparisonData(newState);
    setLoading(false);
  };

  const toggleDialogShow = () => {
    setShowDialog(!showDialog);
  }

  return(
    <GlobalLayout>
      <DataManagementDialog 
        showDialog={showDialog}
        toggleDialogShow={toggleDialogShow}
        comparisonData={comparisonData}
        role={groupData.role}
        uploadLocalData={uploadLocalData}
        uploadData={uploadData}
      />
      <CompareLoading 
        loading={loading} 
        error={error} 
      />
      <CompareError 
        error={error}
        toggleDialogShow={() => setShowDialog(!showDialog)} 
        fetchComparisonData={fetchComparisonData}
      />
      <CompareSuccess
        loading={loading} 
        error={error}
        toggleDialogShow={() => setShowDialog(!showDialog)} 
        comparisonData={comparisonData}
        addFilter={addFilter}
        removeFilter={removeFilter}
        removeAllFilters={removeAllFilters}
        changeDiscount={changeDiscount}
        changeDiscountKey={changeDiscountKey}
        removeAllDiscounts={removeAllDiscounts}
        groupData={groupData}
      />
    </GlobalLayout>
  )
}

export default Compare;