import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Tooltip, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React from "react";
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TranslationContent from "../../../Translations/TranslationContent";


const ChartTypeSelector = ({ handleChartTypeChange, activeChartType, buttonsEnabled }) => {
  
  const chartTypeChange = (event, value) => {
    handleChartTypeChange(value);
  }

  return(
    <Grid xs={12} md={6}>
      <ToggleButtonGroup disabled={!buttonsEnabled} value={activeChartType} exclusive onChange={(event, value) => chartTypeChange(event, value)} color="primary" size="small" >
        <ToggleButton value="bar">
          <Tooltip title={<TranslationContent contentID="bar" />} arrow >
            <BarChartIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="pie">
          <Tooltip title={<TranslationContent contentID="pie" />} arrow >
            <PieChartIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="line">
          <Tooltip title={<TranslationContent contentID="line" />} arrow >
            <MultilineChartIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  )
}

export default ChartTypeSelector;