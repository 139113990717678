export const translations = {

  // ENGLISH TRANSLATIONS
  en: {
    //Global buttons
    darkModeButton: "Dark",
    lightModeButton: "Light",
    finnish: "suomi",
    english: "English",
    languageSelectLabel: "Language",
    retry: "Retry",

    //Login component
    loginHeader: "Login",
    loginCaption: "Please sign in to continue.",
    loginFailed: "Login failed!",
    loginButton: "Sign in",

    //Snackbar content
    snackbarPlaceholderText: "Placeholder for text",
    snackbarLinkCopiedClipboard: "Link copied to clipboard",
    snackbarFetchingGroupsFailed: "Fetching groups failed.",
    snackbarSessionExpired: "Session expired. Please login again to continue.",
    snackbarFetchingDataFailed: "Fetching data failed",
    snackbarFailedToUploadData: "Failed to upload data",
    snackbarLoginToUseFiltersDesc: "Please login, then paste shared link to the browser's address bar to see the filtered data.",

    //Main Header
    mainTitle: "Product Comparison Tool",
    tools: "Tools",
    compare: "Compare",
    export: "Export",
    management: "Management",
    data: "Product data",
    userManagement: "User management",
    logout: "Logout",

    //Roles
    admin: "Admin",
    user: "User",
    own: "main device",
    welcomeback: "Welcome back",
    role: "Role",
    userList: "User list",
    loginSuccessful: "Login successful!",
    loginSuccessDesc1: "Currently",
    loginSuccessDesc2: "account(s) signed in.",
    loginFailedDesc: "No accounts available.",
    noGroupsAttachedErrorDesc: "There are no roles associated with the user account. To proceed to the application, contact your contact person and ensure that you have been assigned a role. Sometimes it may take a while for the rights to be updated, so we recommend trying again later.",

    
    //Not Found 404
    notFoundCaption1: "OOPS! PAGE NOT FOUND",
    notFoundCaption2: "WE ARE SORRY TO INFORM YOU BUT THE PAGE YOU REQUESTED WAS NOT FOUND",
    notFoundTimer1: "REDIRECTING TO HOME PAGE IN",
    notFoundTimer2: "seconds",
    notFoundButton: "Back to home page",

    //Tooltip
    chartRefreshButton: "Update chart",
    addChartToExportList: "Add current chart to export tool",
    addTableToExportList: "Add current data table to export tool",
    downloadChartAsImage: "Download chart as image",
    downloadTableAsImage: "Download data table as image",
    expandAllTableRows: "Expand the additional information for all rows",
    closeAllTableRows: "Close the additional information for all rows",

    // HOME
    home: "Home",
    finland: "Finland",
    poland: "Poland",
    homeErrorOccured: "Fetching data failed!",

    // COMPARE
    oopsSomethingWentWrong: "Oops! Something went wrong!",
    noData: "No data",
    basic: "Basic",
    technical: "Technical",
    warranty: "Warranty",
    price: "Price",
    other: "Other",
    options: "options",
    optionsSelected: "options selected",

    // PRODUCT DATA
    noRightsToAccessProductData: "You don't have rights to process product data",
    //uploadData: "Upload data",
    uploadDesc: "Upload data from your device to cloud",
    //downloadData: "Download data",
    downloadDesc: "Download data to your device",
    validateData: "Validate data",
    validateDesc: "Validate your excel file's data integrity",
    lastEdit: "Last updated",
    name: "name",
    category: "category",
    type: "type",
    fileValidation: "File validation",
    done: "Done",
    productData: "product data",

    
    // PRODUCT DATA SUCCESS
    noErrorsInFile: "No errors or warnings were found in the file.",

    //  PRODUCT DATA ERROR
    errorsFound: "error(s) found",
    header: "header",
    headerMissingLabelCategoryOrType: "Header is missing label, category or type",
    headerTypeInvalid: "Header's type is incorrect",
    headerMissing: "Header cell is empty",
    headerFormatInvalid: "Header format invalid",
    headerNameTooShort: "Header name is too short",
    headerCategoryTooShort: "Header category is too short",
    headerConflict: "Duplicates are not allowed in headers",
    invalidString: "Invalid text",
    invalidStringDesc: "The text in the cell is invalid. Please check the cell and confirm it's in correct format (general or text in excel).",
    invalidNumber: "Invalid number",
    invalidNumberDesc: "The number in the cell is invalid. Check the cell and make sure it is in the correct format (general, text or number in excel). The program automatically changes commas to periods, but make sure that there is not more than one comma/period in the number.",
    invalidBoolean: "Invalid yes/no input",
    invalidBooleanDesc: "The value in the cell is invalid. Check the cell and make sure it is in the correct format (general or text in excel). When the value must be positive, the value of the cell can be kyllä, yes, true & tak. When the value must be negative, the cell value can be ei, no, false & nie. Uppercase and lowercase letters do not matter.",
    invalidDiscount: "Invalid discount",
    invalidDiscountDesc: "The discount in the cell is invalid. Check the cell and make sure it is in the correct format (general, text, percentage, or number in excel). The program automatically changes commas to periods, but make sure that there is not more than one comma/period in the number. The discount percentage can be a decimal, but it must be between 0 and 100.",
    invalidDate: "Invalid date",
    invalidDateDesc: "The date in the cell is invalid. Check the cell and make sure it is in the correct format (date in excel). The program only recognizes dates that have been set as a date in the cell's settings.",
    noMatchWithDataType: "No match with data type",
    noMatchWithDataTypeDesc: "No match found for cell value's type and assumed type. Check the value of the cell's value and the assumed type of that column from the header row.",
    fileValidationFailed: "Validating file failed!",

    //PRODUCT DATA INFO
    guidelineToFixErrosAndWarnings: "Instructions for fixing errors and warnings",
    errors: "Errors",
    warnings: "Warnings",
    errorDesc: "Correcting errors is mandatory for the program to function properly. Read the instructions, make the necessary changes to the file and validate the file again before saving it to the cloud. In the error notification, you can find the cell where the error is located.",
    nameTooShortDesc: "The name must be at least three (3) characters long and the recommendation is at least five (5) characters.",
    categoryTooShortDesc: "The category must be at least three (3) characters long and the recommendation is at least five (5) characters.",
    headerDuplicateDesc: "At least two same headers from different cells were found in the file, which is not allowed. Remove duplicates or edit duplicates by e.g. separating them alphabetically (a, b, c,...) or sequential numbers (1, 2, 3,...).",
    headerFormatInvalidDesc: "More than three parts were found for the header. The header should be in the format name:category:type. Remember to separate the different parts with a colon.",
    headerIsEmptyDesc: "The header is empty. Add a header in the format name:category:type. Remember to separate the different parts with a colon. The name and category have no other rules than the fact that it cannot be empty. You can check the type restrictions at:",
    headerTypeInvalidDesc: "The header's type is incorrectly defined. Types are limited to the following",
    text: "text",
    numberDesc: "number (decimal separated",
    withPoint: "with a point",
    discountDesc: "number without the % sign (decimals separated",
    booleanDesc: "yes/no",
    dateDesc: "date",
    headerTypeInvalidSummary: "Each header should therefore contain a string, number, discount, boolean or date as the last part.",
    headerMissingPartsDesc: "The header should be in the format name:category:type. Remember to separate the different parts with a colon. The header should be in the first row of the table.",


    // PRODUCT DATA WARNINGS
    warningsFound: "warning(s) found",
    warningsDesc: "Failure to fix the warnings will not prevent you from using the app. However, it is recommended to fix the warnings to ensure that the application works correctly and without any problems. Read the instructions, make the necessary changes to the file and validate the file again before saving it to the cloud. In the notification, you will find the cell where the warning is located.",
    headerNameShort: "Header name is shorter than recommended",
    headerCategoryShort: "Header category is shorter than recommended",
    headerNameShortDesc: "The recommendation is the name to be at least five (5) characters.",
    headerCategoryShortDesc: "The recommendation is the category to be at least five (5) characters.",
    emptyCellValue: "Empty value",
    emptyCellValueDesc: "The cell is empty. The recommendation is that the cells are not empty. If there is no up-to-date information available for the cell, you can initialize it, for example, with a number or, in the case of discount percentages, with the value 0. If the entire row is empty, it is recommended to delete the entire row from the file. Otherwise, the application initializes the cell value itself or skips the cell when drawing graphs.",



    // EXPORT 
    order: "Order",
    download: "Download", 
    add: "Add", 
    preview: "Preview", 
    addFirstItem: "Add first item",
    noContent: "No content!",
    noContentDesc: "You can add multiple charts and data tables from the compare page. You can also add custom text and header fields and change the order of the different elements.",
    selectType: "Select type",
    cancel: "Cancel",

    // CHARTS
    chartSettings: "Chart settings",
    horizontal: "Horizontal",
    vertical: "Vertical",
    grouped: "Grouped",
    stacked: "Stacked",
    filterBy: "Filter by",
    indexBy: "Index by",
    highlightRuukki: "Highlight Ruukki Products",
    emptyChart1: "Add filters above and press ",
    emptyChart2: "UPDATE",
    emptyChart3: "to visualize data",
    downloadAs: "Download as",
    chartType: "Chart type",
    bar: "Bar",
    pie: "Pie",
    line: "Line",
    charts: "CHARTS",
    table: "TABLE",
    details: "Details",
    rowsPerPage: "Rows per page",
    filters: "FILTERS",
    removeFilters: "Remove filters",
    discount: "DISCOUNT",
    sortedBy: "Sorted by",
    removeDiscounts: "Remove discounts",
    manageData: "Manage data",
    downloadData: "Download file to your device",
    useLocalData: "Use local data from your device",
    help: "Help?",
    shareFilters: "Share filters",
    uploadData: "Upload file to cloud",
    close: "Close",
    faqTitle: "FAQ (data management)",
    whatFilesSupported: "What file types are supported?",
    whatFilesSupportedAnswer: "The software supports .xlsx and .numbers file types.",
    whoCanUpdate: "Who can update the cloud data?",
    whoCanUpdateAnswer: "Admin(s) of the corresponding section can update the cloud data. All users are able to use local data for comparison which can be totally different from the cloud data.",
    howShouldBeFormated: "How the data should be formated?",
    howShouldBeFormatedAnswer1: "The first row of the file should be the headers/keys for the columns. All rows after that are values of these headers/keys.",
    howShouldBeFormatedAnswer2: "The header should include the headers/keys label, category which it's included, and type. Please see below:",
    howShouldBeFormatedAnswer3: "label:category:type",
    howShouldBeFormatedAnswer4: "label = The label is the key/headers for the value like productName, title, etc. If the software has translations for the label, it will use those translations. If not, the provided label text is used for all languages.",
    howShouldBeFormatedAnswer5: "category = The category can be used to group different labels together. It can be i.e. general, price, technical, etc. If the software has translations for the category, it will use those translations. If not, the provided category text is used for all languages.",
    howShouldBeFormatedAnswer6: "type = The type for the header/key. This information is used for the software to understand how the header/key should be handled. Follow the list below:",
    howShouldBeFormatedAnswer7: "type text = string",
    howShouldBeFormatedAnswer8: "type number/numberic = number",
    howShouldBeFormatedAnswer9: "type yes/no = boolean<",
    howShouldBeFormatedAnswer10: "type dates etc = date",
    howShouldBeFormatedAnswer11: "Example: productName:general:string",
    howShouldBeFormatedAnswer12: "Software will place this header under general category and handle it as a text header/key. The productName translation can be found from the software so its translated to the selected language of the end user.",
    howShouldBeFormatedAnswer13: "IMPORTANT: The person updating the data is responsible to follow the guidelines provided here.",
    update: "Update",
    filtersChanged: "Filters changed! Update to see changes",
    value: "Value",
    identifier: "Identifier",
    position: "Position",
    layout: "Layout",
    row: "Row",
    column: "Column",
    left: "left",
    right: "right",
    top: "top",
    topRight: "top-right",
    topLeft: "top-left",
    bottom: "bottom",
    bottomRight: "bottom-right",
    bottomLeft: "bottom-left",
    leftToRight: "left to right",
    rightToLeft: "right to left",
    topToBottom: "top to bottom",
    bottomToTop: "bottom to top",
    center: "center",
    chartHeight: "Height",
    chartWidth: "Width",
    values: "Values",
    additionalInformation: "Tooltip",
    gridYAxis: "Y-Grid",
    gridXAxis: "X-Grid",
    yes: "Yes",
    no: "No",
    all: "All",
    xAxis: "X-axis",
    legends: "Legends",
    show: "Show",
    justify: "Justify",
    direction: "Direction",
    translateX: "Translate X",
    translateY: "Translate Y",
    itemWidth: "Width",
    itemHeight: "Height",
    itemSpacing: "Spacing",
    symbolSize: "Symbol's size",
    axisAndGrids: "Axis & grids",
    axisTop: "Axis top",
    axisRight: "Axis right",
    axisBottom: "Axis bottom",
    axisLeft: "Axis left",
    label: "Label",
    tickSize: "Tick size",
    tickPadding: "Tick padding",
    tickRotation: "Tick rotation",
    truncateTickAt: "Truncate tick at",
    labelOffSet: "Label offset",
    degreeMark: "°",
    percentageMark: "%",
    px: "px",
    dimensionsAndMargins: "Dimensions & margins",
    generalHeight: "Height",
    generalWidth: "Width",
    margins: "Margins",
    dimensions: "Dimensions",
    dimensionsDesc: "The orange area shows the area to which the settings apply. Container height will always be greater than chart height. Adjust container dimendions with chart dimensions and margins if legends and/or chart will overlap.",
    chart: "Chart",
    chartDesc: "The orange area shows the area to which the settings apply. Chart height will always be smaller than container height. Adjust chart dimendions and margins with container dimensions if legends and/or chart will overlap.",
    restore: "Restore",
    marginDimensionDesc: "Dimensions and margins are optimized when chart is first rendered. Restore optimized dimensions and margins?",
    container: "Container",
    scales: "Scales",
    yScale: "Y-scale",
    yMin: "Y-min",
    yMax: "Y-max",
    auto: "Auto",
    number: "Number",
    typeSetting: "Type",
    linear: "Linear",
    point: "Point",
    reverse: "Reverse",
    style: "Style",
    curve: "Curve",
    curveInterpolation: "Curve interpolation",
    colors: "Colors",
    basis: "Basis",
    cardinal: "Cardinal",
    catmullRom: "Catmull-Rom",
    monotoneX: "Monotone x",
    monotoneY: "Monotone y",
    natural: "Natural",
    step: "Step",
    stepAfter: "Step after",
    stepBefore: "Step before",
    categoricalColors: "CATEGORICAL COLORS",
    default: "Default",
    category10: "Category 10",
    accent: "Accent",
    dark2: "Dark 2",
    paired: "Paired",
    pastel1: "Pastel 1",
    pastel2: "Pastel 2",
    set1: "Set 1",
    set2: "Set 2",
    set3: "Set 3",
    tableau10: "Tableau 10",
    divergingColors: "DIVERGING COLORS",
    brown_blueGreen: "Brown -> Blue Green",
    purpleRed_green: "Purple Red -> Green",
    pink_yellowGreen: "Pink -> Yellow Green",
    purple_orange: "Purple -> Orange",
    red_blue: "Red -> Blue",
    red_grey: "Red -> Green",
    red_yellow_blue: "Red -> Yellow -> Blue",
    red_yellow_green: "Red -> Yellow -> Green",
    spectral: "Spectral",
    sequentialColors: "SEQUENTIAL COLORS",
    blues: "Blues",
    greens: "Greens",
    greys: "Greys",
    oranges: "Oranges",
    purples: "Purples",
    reds: "Reds",
    blue_green: "Blue -> Green",
    blue_purple: "Blue -> Purple",
    green_blue: "Green -> Blue",
    orange_red: "Orange -> Red",
    purple_blue_green: "Purple -> Blue -> Green",
    purple_blue: "Purple -> Blue",
    purple_red: "Purple -> Red",
    red_purple: "Red -> Purple",
    yellow_green_blue: "Yellow -> Green -> Blue",
    yellow_green: "Yellow -> Green",
    yellow_orange_brown: "Yellow -> Orange -> Brown",
    yellow_orange_red: "Yellow -> Orange -> Red",
    colorScheme: "Color scheme",
    lineWidth: "Line width",
    enableArea: "Show area",
    active: "Active",
    baselineValue: "Baseline balue",
    opacity: "Opacity",
    pointSize: "Point size",
    yPointOffset: "Y point offset",
    area: "Area",
    points: "Points",
    interactivity: "Interactivity",
    voronoiMesh: "Voronoi mesh",
    highlightVoronoiMesh: "Display voronoi cells",
    voronoiDesc: "A Voronoi mesh is used to identify mouse movements relative to points in the diagram. By turning it off, mouse movements are not recognized unless x or y is selected in the tooltip type.",
    crosshair: "Crosshair",
    crosshairType: "Crosshair type",
    crosshairTouchEnable: "Crosshair to be dragged around a touch screen",
    productTooltip: "Tooltip",
    infoType: "Tooltip type",
    drawHelpers: "Display helpers",
    x: "x",
    y: "y",
    cross: "cross",
    sort: "Sort",
    fit: "Fit",
    arcs: "Arcs",
    startAngle: "Start angle",
    endAngle: "End angle",
    innerRadius: "Inner radius",
    padAngle: "Pad angle",
    cornerRadius: "Corner radius",
    idAndValue: "Identifier (value)",
    content: "Content",
    arcLabel: "Arc label",
    arcLinkLabel: "Arc link label",
    arcLabelsRadiusOffset: "Arc label's radius offset",
    arcLabelsSkipAngle: "Arc label's skip angle",
    arcLinkLabelsDiagonalLength: "Arc link label's diagonal length",
    arcLinkLabelsStraightLength: "Arc link label's straight length",
    arcLinkLabelsTextOffset: "Arc link label's text offset",
    arcLinkLabelsThickness: "Arc link label's thickness",
    activeInnerRadiusOffset: "Active inner radius offset",
    activeOuterRadiusOffset: "Active outer radius offset",
    tooltipWithAllInfo: "Tooltip with all product data",
    symlog: "Symlog",
    bars: "Bars",
    groupMode: "Group mode",
    barPadding: "Bar padding",
    barInnerPadding: "Bar inner padding",
    borderRadius: "Border radius",
    borderWidth: "Border width",
    barLabel: "Bar label",
    totals: "Totals",
    labelSkipWidth: "Label skip width",
    labelSkipHeight: "Label skip height",
    totalsOffset: "Totals offset",

  },

  // FINNISH TRANSLATIONS
  fi: {
    //Global buttons
    darkModeButton: "Tumma",
    lightModeButton: "Valoisa",
    finnish: "suomi",
    english: "English",
    languageSelectLabel: "Kieli",
    retry: "Yritä uudelleen",

    //Login component
    loginHeader: "Kirjautuminen",
    loginCaption: "Ole hyvä ja kirjaudu sisään jatkaaksesi.",
    loginFailed: "Sisäänkirjautuminen epäonnistui!",
    loginButton: "Kirjaudu",

    //Snackbar content
    snackbarPlaceholderText: "Tekstille varattu paikka",
    snackbarLinkCopiedClipboard: "Linkki kopioitu leikepöydälle",
    snackbarFetchingGroupsFailed: "Ryhmien haku epäonnistui.",
    snackbarSessionExpired: "Istunto vanhentunut. Ole hyvä ja kirjaudu uudelleen.",
    snackbarFetchingDataFailed: "Tietojen haku epäonnistui",
    snackbarFailedToUploadData: "Tietojen päivitys epäonnistui",
    snackbarItemAddedToExportList: "Kohde lisätty",
    snackbarItemAddedToExportListFailed: "Kohteen lisääminen epäonnistui",
    snackbarLoginToUseFiltersDesc: "Ole hyvä ja kirjaudu sisään, liitä sen jälkeen linkki osoitekenttään nähdäksesi datan.",


    //Main Header
    mainTitle: "Tuotevertailutyökalu",
    tools: "Työkalut",
    compare: "Vertaile",
    export: "Vie",
    management: "Hallinnoi",
    data: "Tuotedata",
    userManagement: "Käyttäjien hallinta",
    logout: "Kirjaudu ulos",

     // ROLES
    admin: "Pääkäyttäjä",
    user: "Peruskäyttäjä",
    role: "Rooli",
    own: "päälaite",
    userList: "Käyttäjälista",
    loginSuccessful: "Kirjautuminen onnistui!",
    loginSuccessDesc1: "Tällä hetkellä",
    loginSuccessDesc2: "käyttäjätunnusta on kirjautunut sisään.",
    loginFailedDesc: "Ei käyttäjätunnuksia saatavilla.",
    welcomeback: "Tervetuloa takaisin",
    noGroupsAttachedErrorDesc: "Käyttäjätunnukseen ei ole liitetty yhtään roolia. Jotta voit jatkaa sovellukseen, ole yhteydessä yhteyshenkilöösi ja varmista, että sinulle on annettu rooli. Joskus oikeuksien päivityksessä voi mennä hetki, joten suosittelemme yrittämään myöhemmin uudelleen.",

    //Not Found 404
    notFoundCaption1: "OHO! SIVUA EI LÖYTYNYT",
    notFoundCaption2: "OLEMME PAHOILLAMME, MUTTA ETSIMÄÄNNE SIVUA EI LÖYTYNYT",
    notFoundTimer1: "OHJATAAN ETUSIVULLE",
    notFoundTimer2: "sekunnin kuluttua",
    notFoundButton: "Palaa takaisin etusivulle",

    //Tooltip
    chartRefreshButton: "Päivitä kaavio",
    addChartToExportList: "Lisää nykyinen kaavio vientityökaluun",
    addTableToExportList: "Lisää nykyinen taulukko vientityökaluun",
    downloadChartAsImage: "Lataa kaavio kuvana",
    downloadTableAsImage: "Lataa taulukko kuvana",
    expandAllTableRows: "Avaa kaikkien rivien lisätiedot",
    closeAllTableRows: "Sulje kaikkien rivien lisätiedot",

    // HOME
    home: "Koti",
    finland: "Suomi",
    poland: "Puola",
    homeErrorOccured: "Tapahtui virhe!",

    // COMPARE
    oopsSomethingWentWrong: "Oho! Jotain meni pieleen!",
    noData: "Ei dataa",
    basic: "Yleiset",
    technical: "Tekniset",
    warranty: "Takuu",
    price: "Hinta",
    other: "Muut",
    options: "vaihtoehtoa",
    optionsSelected: "vaihtoehtoa valittu",

    // PRODUCT DATA
    noRightsToAccessProductData: "Sinulla ei ole oikeuksia käsitellä tuotetietoja",
    //uploadData: "Päivitä tiedot",
    uploadDesc: "Päivitä tiedosto laitteeltasi pilveen",
    //downloadData: "Lataa tiedot",
    downloadDesc: "Lataa tiedosto pilvestä laitteellesi",
    validateData: "Validoi tiedot",
    validateDesc: "Tarkista tiedostosi tietojen eheys",
    lastEdit: "Viimeksi päivitetty",
    name: "nimi",
    category: "kategoria",
    type: "tyyppi",
    fileValidation: "Tiedoston validointi",
    done: "Valmis",
    productData: "tuotedata",

    // PRODUCT DATA SUCCESS
    noErrorsInFile: "Tiedostosta ei löytynyt virheitä tai varoituksia.",

    // PRODUCT DATA ERROR
    errorsFound: "virhe(ttä) löytyi",
    header: "otsikko",
    headerMissingLabelCategoryOrType: "Otsikosta puuttuu nimi, kategoria tai tyyppi",
    headerTypeInvalid: "Otsikon tyyppi on virheellinen",
    headerMissing: "Otsikko on tyhjä",
    headerFormatInvalid: "Otsikko on väärässä muodossa",
    headerNameTooShort: "Otsikon nimi on liian lyhyt",
    headerCategoryTooShort: "Otsikon kategoria on liian lyhyt",
    headerConflict: "Duplikaatit eivät ole sallittuja otsikoissa",
    invalidString: "Virheellinen teksti",
    invalidStringDesc: "Solun teksti on virheellinen. Tarkista solu ja varmista, että se on oikeassa muodossa (yleinen tai teksti excelissä).",
    invalidNumber: "Virheellinen numero",
    invalidNumberDesc: "Solun numero on virheellinen. Tarkista solu ja varmista, että se on oikeassa muodossa (yleinen, teksti tai numero excelissä). Ohjelma vaihtaa automaattisesti pilkut pisteiksi, mutta varmista, ettei numerossa ole useampaa kuin yksi pilkku/piste.",
    invalidBoolean: "Virheellinen kyllä/ei -merkintä",
    invalidBooleanDesc: "Solun arvo on virheellinen. Tarkista solu ja varmista, että se on oikeassa muodossa (yleinen tai teksti excelissä). Kun arvon tulee olle myönteinen, voi solun arvona olla kyllä, yes, true & tak. Kun arvon tulee olla kielteinen, voi solun arvona olla ei, no, false & nie. Isoilla ja pienillä kirjaimilla ei ole merkitystä.",
    invalidDiscount: "Virheellinen alennus",
    invalidDiscountDesc: "Solun alennus on virheellinen. Tarkista solu ja varmista, että se on oikeassa muodossa (yleinen, teksti prosentti, tai numero excelissä). Ohjelma vaihtaa automaattisesti pilkut pisteiksi, mutta varmista, ettei numerossa ole useampaa kuin yksi pilkku/piste. Alennusprosentti voi olla desimaali, mutta sen tulee olla välillä 0-100.",
    invalidDate: "Virheellinen päiväys",
    invalidDateDesc: "Solun päiväys on virheellinen. Tarkista solu ja varmista, että se on oikeassa muodossa (päiväys excelissä). Ohjelma tunnistaa ainoastaan päiväykset, mitkä ollaan asetettu solun asetuksissa päiväykseksi.",
    noMatchWithDataType: "Datatyypille ei löytynyt osumaa",
    noMatchWithDataTypeDesc: "Solun arvon tyypille ja oletetulla tyypille ei löytynyt osumaa. Tarkasta solun arvo sekä kyseisen kolumnin oletettu tyyppi otsikkoriviltä.",
    fileValidationFailed: "Tiedoston validointi epäonnistui!",


    //PRODUCT DATA INFO
    guidelineToFixErrosAndWarnings: "Ohjeita virheiden ja varoitusten korjaamiseen",
    errors: "Virheet",
    warnings: "Varoitukset",
    errorDesc: "Virheiden korjaaminen on pakollista, jotta ohjelma toimii kunnolla. Tutustu ohjeisiin, tee tarvittavat muutokset tiedostoon ja validoi tiedosto uudestaan ennen tallentamista pilveen. Virhe-ilmoituksesta löydät solun, missä virhe sijaitsee.",
    nameTooShortDesc: "Nimen tulee olla vähintään kolme (3) merkkiä pitkä ja suositus on vähintään viisi (5) merkkiä.",
    categoryTooShortDesc: "Kategorian tulee olla vähintään kolme (3) merkkiä pitkä ja suositus on vähintään viisi (5) merkkiä.",
    headerDuplicateDesc: "Tiedostosta löytyi vähintään kaksi samaa otsikkoa eri soluista, mikä ei ole sallittua. Poista duplikaatit tai muokkaa duplikaatteja erottamalla ne esimerkiksi aakkosittain (a, b, c,...) tai järjestysnumeroilla (1, 2, 3,...).",
    headerFormatInvalidDesc: "Otsikosta löytyi useampi kuin kolme osaa. Otsikon tulee olla muodossa nimi:kategoria:tyyppi. Muista erottaa eri osat kaksoispisteellä.",
    headerIsEmptyDesc: "Otsikko on tyhjä. Lisää otsikko muodossa nimi:kategoria:tyyppi. Muista erottaa eri osat kaksoispisteellä. Nimeen ja kategoriaan ei liity muita sääntöjä kuin se, ettei se voi olla tyhjä. Tyyppien rajoitukset voit tarkastaa kohdasta:",
    headerTypeInvalidDesc: "Otsikkorivin tyyppi on määritelty väärin. Tyypit on rajoitettu seuraaviin",
    text: "teksti",
    numberDesc: "numero (desimaalit eroteltuna",
    withPoint: "pisteellä",
    discountDesc: "numero ilman %-merkkiä (desimaalit eroteltuna",
    booleanDesc: "kyllä/ei",
    dateDesc: "päivämäärä",
    headerTypeInvalidSummary: "Jokaisen otsikon pitäisi siis sisältää viimeisenä osana string, number, discount, boolean tai date.",
    headerMissingPartsDesc: "Otsikon tulee olla muodossa nimi:kategoria:tyyppi. Muista erottaa eri osat kaksoispisteellä. Otsikon tulee olla taulukon ensimmäisellä rivillä.",


    // PRODUCT DATA WARNINGS
    warningsFound: "varoitus(ta) löytyi",
    warningsDesc: "Varoitusten korjaamisen laiminlyönminen ei estä sovelluksen käyttöä. On kuitenkin suositeltavaa korjata varoitukset, jotta voidaan varmistaa, että sovellus toimii oikein ja ongelmitta. Tutustu ohjeisiin, tee tarvittavat muutokset tiedostoon ja validoi tiedosto uudestaan ennen tallentamista pilveen. Ilmoituksesta löydät solun, missä varoitus sijaitsee.",
    headerNameShort: "Otsikon nimi on suositeltua lyhyempi",
    headerCategoryShort: "Otsikon kategoria on suositeltua lyhyempi",
    headerNameShortDesc: "Suositus on, että nimi on vähintään viisi (5) merkkiä pitkä.",
    headerCategoryShortDesc: "Suositus on, että kategoria on vähintään viisi (5) merkkiä pitkä.",
    emptyCellValue: "Tyhjä arvo",
    emptyCellValueDesc: "Solu on tyhjä. Suositus on, että solut eivät ole tyhjiä. Jos soluun ei ole ajantasaista tietoa saatavilla, voit alustaa sen esimerkiksi numero tai alennusprosenttien tapauksessa arvoksi 0. Jos koko rivi on tyhjä, on suositus poistaa koko rivi tiedostosta. Muussa tapauksessa sovellus alustaa solun arvon itse tai ohittaa solun kaavioita piirrettäessä.",

    // EXPORT 
    order: "Järjestys",
    download: "Lataa", 
    add: "Lisää", 
    preview: "Esikatsele", 
    addFirstItem: "Aloita lisäämään sisältöä",
    noContent: "Ei sisältöä!",
    noContentDesc: "Voit lisätä useita kaavioita ja tietotaulukoita vertailusivulta. Voit myös lisätä mukautettuja teksti- ja otsikkokenttiä ja muuttaa eri elementtien järjestystä.",
    selectType: "Valitse tyyppi",
    cancel: "Peruuta",

    // CHARTS
    chartSettings: "Kaavion asetukset",
    horizontal: "Vaaka",
    vertical: "Pysty",
    grouped: "Ryhmitelty",
    stacked: "Pinottu",
    filterBy: "Suodata",
    indexBy: "Indeksoi",
    highlightRuukki: "Korosta Ruukin tuotteet",
    emptyChart1: "Lisää filttereitä yläpuolelta ja paina ",
    emptyChart2: "PÄIVITÄ",
    emptyChart3: "visuliasoidaksesi datan",
    downloadAs: "Lataa muodossa",
    chartType: "Kaavion tyyppi",
    bar: "Pylväs",
    pie: "Ympyrä",
    line: "Viiva",
    charts: "KAAVIOT",
    table: "TAULUKKO",
    details: "Tiedot",
    rowsPerPage: "Rivejä per sivu",
    filters: "SUODATTIMET",
    removeFilters: "Poista suodattimet",
    discount: "ALENNUKSET",
    sortedBy: "Lajitteluperuste",
    removeDiscounts: "Poista alennukset",
    manageData: "Hallinnoi dataa",
    downloadData: "Lataa tiedosto laitteellesi",
    useLocalData: "Käytä tiedostoa laitteeltasi",
    help: "Apua?",
    shareFilters: "Jaa suodattimet",
    uploadData: "Lataa tiedosto pilveen",
    close: "Sulje",
    faqTitle: "UKK (datan hallinnointi)",
    whatFilesSupported: "Mitä tiedostotyyppejä tuetaan?",
    whatFilesSupportedAnswer: "Ohjelmisto tukee .xlsx- ja .numbers-tiedostotyyppejä.",
    whoCanUpdate: "Kuka voi päivittää tietoja pilveen?",
    whoCanUpdateAnswer: "Vastaavan osion järjestelmänvalvojat voivat päivittää tiedot pilveen. Kaikki käyttäjät voivat käyttää vertailuun paikallisia tiedostoja, jotka voivat olla täysin erilaisia ​​​​kuin pilveen tallennettu tiedosto.",
    howShouldBeFormated: "Miten tiedot pitäisi muotoilla?",
    howShouldBeFormatedAnswer1: "Tiedoston ensimmäisen rivin tulee olla sarakkeiden otsikot/avaimet. Kaikki sen jälkeiset rivit ovat näiden otsikoiden/avainten arvoja.",
    howShouldBeFormatedAnswer2: "Otsikon tulee sisältää otsikko-/avaimet-tunniste, luokka, johon se sisältyy, ja tyyppi. Katso alla:",
    howShouldBeFormatedAnswer3: "nimi:kategoria:tyyppi",
    howShouldBeFormatedAnswer4: "nimi = Nimi on arvon avain/otsikot, kuten productName, title jne. Jos ohjelmistolla on nimelle käännöksiä, se käyttää niitä käännöksiä. Jos ei, annettua nimitekstiä käytetään kaikille kielille.",
    howShouldBeFormatedAnswer5: "kategoria = Kategorialla voidaan ryhmitellä eri tarroja yhteen. Se voi olla esim. yleinen, hinta, tekninen jne. Jos ohjelmistolla on käännöksiä kyseiselle kategorialle, se käyttää niitä käännöksiä. Jos ei, toimitettua luokkatekstiä käytetään kaikille kielille.",
    howShouldBeFormatedAnswer6: "type = Otsikon/avaimen tyyppi. Näitä tietoja käytetään ohjelmiston ymmärtämään, kuinka otsikkoa/avainta tulee käsitellä. Seuraa alla olevaa listaa:",
    howShouldBeFormatedAnswer7: "teksti = string",
    howShouldBeFormatedAnswer8: "numero = number",
    howShouldBeFormatedAnswer9: "kyllä/ei = boolean<",
    howShouldBeFormatedAnswer10: "päivämäärä = date",
    howShouldBeFormatedAnswer11: "Esimerkki: tuotteenNimi:yleinen:string",
    howShouldBeFormatedAnswer12: "Ohjelmisto sijoittaa tämän otsikon yleiseen kategoriaan ja käsittelee sitä tekstiotsikkona/avaimena. ProductName-käännös löytyy ohjelmistosta, joten se käännetään loppukäyttäjän valitsemalle kielelle.",
    howShouldBeFormatedAnswer13: "TÄRKEÄÄ: Tietojen päivittäjä on vastuussa tässä annettujen ohjeiden noudattamisesta.",
    filtersChangedDrawChart: "Suodattimet muuttuneet! Piirrä päivitetty kaavio",
    update: "Päivitä",
    filtersChanged: "Suodattimet muuttuneet! Päivitä nähdäksesi muutokset",
    value: "Arvo",
    identifier: "Tunniste",
    position: "Sijainti",
    layout: "Järjestys",
    row: "Rivi",
    column: "Sarake",
    left: "vasen",
    right: "oikea",
    top: "ylös",
    topRight: "ylä oikea",
    topLeft: "ylä vasen",
    bottom: "alas",
    bottomRight: "ala oikea",
    bottomLeft: "ala vasen",
    leftToRight: "vasemmalta oikealle",
    rightToLeft: "oikealta vasemmalle",
    topToBottom: "ylhäältä alas",
    bottomToTop: "alhaalta ylös",
    center: "keskellä",
    chartHeight: "Korkeus",
    chartWidth: "Leveys",
    values: "Arvot",
    additionalInformation: "Lisätiedot",
    gridYAxis: "Y-Ruudukko",
    gridXAxis: "X-Ruudukko",
    yes: "Kyllä",
    no: "Ei",
    all: "Kaikki",
    xAxis: "X-akseli",
    legends: "Legendat",
    show: "Näytä",
    justify: "Tasaus",
    direction: "Suuntaus",
    translateX: "Horisontaalinen asettelu",
    translateY: "Vertikaalinen asettelu",
    itemWidth: "Rivileveys",
    itemHeight: "Rivikorkeus",
    itemSpacing: "Kohdeväli",
    symbolSize: "Merkin koko",
    axisAndGrids: "Akselit & ruudukot",
    axisTop: "Yläakseli",
    axisRight: "Oikea akseli",
    axisBottom: "Ala-akseli",
    axisLeft: "Vasen akseli",
    label: "Nimi",
    tickSize: "Viivaimen korkeus",
    tickPadding: "Viivaimen reunusteet",
    tickRotation: "Viivaimen kierto",
    truncateTickAt: "Katkaise kohdassa",
    labelOffSet: "Nimen tasoitus",
    degreeMark: "°",
    percentageMark: "%",
    px: "px",
    dimensionsAndMargins: "Mitat & marginaalit",
    generalHeight: "Korkeus",
    generalWidth: "Leveys",
    margins: "Marginaalit",
    dimensions: "Mitat",
    dimensionsDesc: "Oranssi alue näyttää alueen, jota asetukset koskevat. Säädä säiliön mittoja kaavion mittojen kanssa, jos legendat ja/tai kaavio menevät päällekkäin tai katoavat säiliön ulkopuolelle.",
    chart: "Kaavio",
    chartDesc: "Oranssi alue näyttää alueen, jota asetukset koskevat. Kaavion korkeus on aina pienempi kuin säiliön korkeus. Säädä kaavion mittoja ja marginaaleja säilön mittojen kanssa, jos legendat ja/tai kaavio menevät päällekkäin tai katoavat säiliön ulkopuolelle.",
    restore: "Palauta",
    marginDimensionDesc: "Mitat ja marginaalit optimoidaan, kun kaavio piirretään ensimmäisen kerran. Palautetaanko optimoidut mitat ja marginaalit?",
    container: "Säiliö",
    scales: "Asteikot",
    yScale: "Y-asteikko",
    yMin: "Y-min",
    yMax: "Y-max",
    auto: "Auto",
    number: "Numero",
    typeSetting: "Tyyppi",
    linear: "Lineaarinen",
    point: "Piste",
    reverse: "Käänteinen",
    style: "Tyyli",
    curve: "Käyrä",
    curveInterpolation: "Käyrän interpolointi",
    colors: "Värit",
    basis: "Perus",
    cardinal: "Kardinaali",
    catmullRom: "Catmull-Rom",
    monotoneX: "Monotoninen x",
    monotoneY: "Monotoninen y",
    natural: "Luonnollinen",
    step: "Porras",
    stepAfter: "Porras jälkeen",
    stepBefore: "Porras ennen",

    categoricalColors: "KATEGOORISET VÄRIT",
    default: "Oletus",
    category10: "Kategoria 10",
    accent: "Korostus",
    dark2: "Tumma 2",
    paired: "Paritettu",
    pastel1: "Pastelli 1",
    pastel2: "Pastelli 2",
    set1: "Sarja 1",
    set2: "Sarja 2",
    set3: "Sarja 3",
    tableau10: "Kuvaelma 10",
    divergingColors: "ERITTÄVÄT VÄRIT",
    brown_blueGreen: "Ruskea -> Sinivihreä",
    purpleRed_green: "Violetti Punainen -> Vihreä",
    pink_yellowGreen: "Pinkki -> Keltainen Vihreä",
    purple_orange: "Violetti -> Oranssi",
    red_blue: "Punainen -> Sininen",
    red_grey: "Punainen -> Vihreä",
    red_yellow_blue: "Punainen -> Keltainen -> Sininen",
    red_yellow_green: "Punainen -> Keltainen -> Vihreä",
    spectral: "Spektri",
    sequentialColors: "SEKVENTIÄISET VÄRIT",
    blues: "Siniset",
    greens: "Vihreät",
    greys: "Harmaat",
    oranges: "Oranssit",
    purples: "Violetit",
    reds: "Punaiset",
    blue_green: "Sininen -> Vihreä",
    blue_purple: "Sininen -> Violetti",
    green_blue: "Vihreä -> Sininen",
    orange_red: "Oranssi -> Punainen",
    purple_blue_green: "Violetti -> Sininen -> Vihreä",
    purple_blue: "Violetti -> Sininen",
    purple_red: "Violetti -> Punainen",
    red_purple: "Punainen -> Violetti",
    yellow_green_blue: "Keltainen -> Vihreä -> Sininen",
    yellow_green: "Keltainen -> Vihreä",
    yellow_orange_brown: "Keltainen -> Oranssi -> Ruskea",
    yellow_orange_red: "Keltainen -> Oranssi -> Punainen",
    colorScheme: "Väriskeema",
    lineWidth: "Käyrän paksuus",
    enableArea: "Näytä alue",
    active: "Aktiivinen",
    baselineValue: "Lähtöarvo",
    opacity: "Läpinäkyvyys",
    pointSize: "Pisteen koko",
    yPointOffset: "Arvon tasoitus y-suunnassa",
    area: "Pinta-ala",
    points: "Pisteet",
    interactivity: "Interaktiivisuus",
    voronoiMesh: "Voronoi-verkko",
    highlightVoronoiMesh: "Piirrä voronoi-verkko",
    voronoiDesc: "Voronoi-verkkoa käytetään tunnistamaan hiiren liikkeet suhteessa diagrammin pisteisiin. Kytkemällä sen pois päältä hiiren liikkeitä ei tunnisteta ellei tuotetiedoissa ole valintana x tai y.",
    crosshair: "Ristikko",
    crosshairType: "Ristikon tyyppi",
    crosshairTouchEnable: "Ristikon siirtäminen kosketusnäytöllä",
    productTooltip: "Tuotetiedot",
    infoType: "Tiedot",
    drawHelpers: "Piirrä avustajat",
    x: "x",
    y: "y",
    cross: "risti",
    sort: "Järjestä",
    fit: "Sovita",
    arcs: "Sektorit",
    startAngle: "Aloituskulma",
    endAngle: "Lopetuskulma",
    innerRadius: "Sisäsäde",
    padAngle: "Sektoriväli",
    cornerRadius: "Kulman säde",
    idAndValue: "Tunniste (arvo)",
    content: "Sisältö",
    arcLabel: "Sisäinen nimike",
    arcLinkLabel: "Ulkoinen nimike",
    arcLabelsRadiusOffset: "Sektorin nimikkeen säteen siirtymä",
    arcLabelsSkipAngle: "Sektorin nimikkeen pienin kulma, kun se näytetään",
    arcLinkLabelsDiagonalLength: "Sektorin nimikkeen viivan diagonaalin pituus",
    arcLinkLabelsStraightLength: "Sektorin nimikkeen viivan suora pituus",
    arcLinkLabelsTextOffset: "Sektorin nimikkeen tekstin siirtymä",
    arcLinkLabelsThickness: "Sektorin nimikkeen viivan paksuus",
    activeInnerRadiusOffset: "Aktiivinen sisäsäteen siirtymä",
    activeOuterRadiusOffset: "Aktiivinen ulkosäteen siirtymä",
    tooltipWithAllInfo: "Lisätiedot kaikilla tuotetiedoilla",
    symlog: "Symlog",
    bars: "Pylväät",
    groupMode: "Ryhmitys",
    barPadding: "Pylväsväli",
    barInnerPadding: "Pylväsosien väli",
    borderRadius: "Reunojen säde",
    borderWidth: "Reunojen leveys",
    barLabel: "Pylvään tunniste",
    totals: "Summat",
    labelSkipWidth: "Tunnisteen ohitusleveys",
    labelSkipHeight: "Tunnisteen ohituskorkeus",
    totalsOffset: "Summien pystytasaus",

  }

}