import React from "react";
import { Collapse, Stack, Typography } from "@mui/material";
import SettingContainer from "../../Settings/SettingContainer";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SwitchSetting from "../../Settings/SwitchSetting";
import SettingDivider from "../../Settings/SettingDivider";
import TextSetting from "../../Settings/TextSetting";
import SliderSetting from "../../Settings/SliderSetting";


const BarAxisSettings = ({
  showGridX,
  setShowGridX,
  showGridY,
  setShowGridY,

  topAxisLabel,
  setTopAxisLabel,
  rightAxisLabel,
  setRightAxisLabel,
  bottomAxisLabel,
  setBottomAxisLabel,
  leftAxisLabel,
  setLeftAxisLabel,

  showTopAxis,
  setShowTopAxis,
  showRightAxis,
  setShowRightAxis,
  showBottomAxis,
  setShowBottomAxis,
  showLeftAxis,
  setShowLeftAxis,

  topAxisTickSize,
  setTopAxisTickSize,
  topAxisTickPadding,
  setTopAxisTickPadding,
  topAxisTickRotation,
  setTopAxisTickRotation,
  topAxisTruncateTickAt,
  setTopAxisTruncateTickAt,
  topAxisLabelOffSet,
  setTopAxisLabelOffSet,

  rightAxisTickSize,
  setRightAxisTickSize,
  rightAxisTickPadding,
  setRightAxisTickPadding,
  rightAxisTickRotation,
  setRightAxisTickRotation,
  rightAxisTruncateTickAt,
  setRightAxisTruncateTickAt,
  rightAxisLabelOffSet,
  setRightAxisLabelOffSet,

  bottomAxisTickSize,
  setBottomAxisTickSize,
  bottomAxisTickPadding,
  setBottomAxisTickPadding,
  bottomAxisTickRotation,
  setBottomAxisTickRotation,
  bottomAxisTruncateTickAt,
  setBottomAxisTruncateTickAt,
  bottomAxisLabelOffSet,
  setBottomAxisLabelOffSet,

  leftAxisTickSize,
  setLeftAxisTickSize,
  leftAxisTickPadding,
  setLeftAxisTickPadding,
  leftAxisTickRotation,
  setLeftAxisTickRotation,
  leftAxisTruncateTickAt,
  setLeftAxisTruncateTickAt,
  leftAxisLabelOffSet,
  setLeftAxisLabelOffSet,
}) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="axisAndGrids">
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="gridXAxis" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showGridX}
          handleChange={setShowGridX}  
        />
        <Typography variant="caption" sx={{alignSelf: "flex-start", width: "80px"}}><b><TranslationContent contentID="gridYAxis" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showGridY}
          handleChange={setShowGridY}  
        />
      </Stack>
      <SettingDivider />
      <div style={{height: "15px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="axisTop" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showTopAxis}
          handleChange={setShowTopAxis}  
        />
        <TextSetting 
          label="label"
          value={topAxisLabel}
          handleChange={setTopAxisLabel}
          disabled={!showTopAxis}
        />
      </Stack>
      <Collapse in={showTopAxis}>
        <SliderSetting
          label="tickSize"
          icon="itemHeight"
          minValue={0}
          maxValue={20}
          step={2}
          value={topAxisTickSize}
          updateValue={setTopAxisTickSize}
          variableType="px"
        />
        <SliderSetting
          label="tickPadding"
          icon="padding"
          minValue={0}
          maxValue={20}
          step={2}
          value={topAxisTickPadding}
          updateValue={setTopAxisTickPadding}
          variableType="px"
        />
        <SliderSetting
          label="tickRotation"
          icon="textRotate"
          minValue={-90}
          maxValue={90}
          value={topAxisTickRotation}
          updateValue={setTopAxisTickRotation}
          variableType="degreeMark"
        />
        <SliderSetting
          label="truncateTickAt"
          icon="truncate"
          minValue={0}
          maxValue={200}
          value={topAxisTruncateTickAt}
          updateValue={setTopAxisTruncateTickAt}
          variableType=""
        />
        <SliderSetting
          label="labelOffSet"
          icon="straigten"
          minValue={-60}
          maxValue={60}
          value={topAxisLabelOffSet}
          updateValue={setTopAxisLabelOffSet}
          variableType="px"
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "15px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="axisRight" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showRightAxis}
          handleChange={setShowRightAxis}  
        />
        <TextSetting 
          label="label"
          value={rightAxisLabel}
          handleChange={setRightAxisLabel}
          disabled={!showRightAxis}
        />
      </Stack>
      <Collapse in={showRightAxis}>
        <SliderSetting
          label="tickSize"
          icon="itemHeight"
          minValue={0}
          maxValue={20}
          step={2}
          value={rightAxisTickSize}
          updateValue={setRightAxisTickSize}
          variableType="px"
        />
        <SliderSetting
          label="tickPadding"
          icon="padding"
          minValue={0}
          maxValue={20}
          step={2}
          value={rightAxisTickPadding}
          updateValue={setRightAxisTickPadding}
          variableType="px"
        />
        <SliderSetting
          label="tickRotation"
          icon="textRotate"
          minValue={-90}
          maxValue={90}
          value={rightAxisTickRotation}
          updateValue={setRightAxisTickRotation}
          variableType="degreeMark"
        />
        <SliderSetting
          label="truncateTickAt"
          icon="truncate"
          minValue={0}
          maxValue={200}
          value={rightAxisTruncateTickAt}
          updateValue={setRightAxisTruncateTickAt}
          variableType=""
        />
        <SliderSetting
          label="labelOffSet"
          icon="straigten"
          minValue={-60}
          maxValue={60}
          value={rightAxisLabelOffSet}
          updateValue={setRightAxisLabelOffSet}
          variableType="px"
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "15px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="axisBottom" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showBottomAxis}
          handleChange={setShowBottomAxis}  
        />
        <TextSetting 
          label="label"
          value={bottomAxisLabel}
          handleChange={setBottomAxisLabel}
          disabled={!showBottomAxis}
        />
      </Stack>
      <Collapse in={showBottomAxis}>
        <SliderSetting
          label="tickSize"
          icon="itemHeight"
          minValue={0}
          maxValue={20}
          step={2}
          value={bottomAxisTickSize}
          updateValue={setBottomAxisTickSize}
          variableType="px"
        />
        <SliderSetting
          label="tickPadding"
          icon="padding"
          minValue={0}
          maxValue={20}
          step={2}
          value={bottomAxisTickPadding}
          updateValue={setBottomAxisTickPadding}
          variableType="px"
        />
        <SliderSetting
          label="tickRotation"
          icon="textRotate"
          minValue={-90}
          maxValue={90}
          value={bottomAxisTickRotation}
          updateValue={setBottomAxisTickRotation}
          variableType="degreeMark"
        />
        <SliderSetting
          label="truncateTickAt"
          icon="truncate"
          minValue={0}
          maxValue={200}
          value={bottomAxisTruncateTickAt}
          updateValue={setBottomAxisTruncateTickAt}
          variableType=""
        />
        <SliderSetting
          label="labelOffSet"
          icon="straigten"
          minValue={-60}
          maxValue={60}
          value={bottomAxisLabelOffSet}
          updateValue={setBottomAxisLabelOffSet}
          variableType="px"
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "15px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="axisLeft" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={showLeftAxis}
          handleChange={setShowLeftAxis}  
        />
        <TextSetting 
          label="label"
          value={leftAxisLabel}
          handleChange={setLeftAxisLabel}
          disabled={!showLeftAxis}
        />
      </Stack>
      <Collapse in={showLeftAxis}>
        <SliderSetting
          label="tickSize"
          icon="itemHeight"
          minValue={0}
          maxValue={20}
          step={2}
          value={leftAxisTickSize}
          updateValue={setLeftAxisTickSize}
          variableType="px"
        />
        <SliderSetting
          label="tickPadding"
          icon="padding"
          minValue={0}
          maxValue={20}
          step={2}
          value={leftAxisTickPadding}
          updateValue={setLeftAxisTickPadding}
          variableType="px"
        />
        <SliderSetting
          label="tickRotation"
          icon="textRotate"
          minValue={-90}
          maxValue={90}
          value={leftAxisTickRotation}
          updateValue={setLeftAxisTickRotation}
          variableType="degreeMark"
        />
        <SliderSetting
          label="truncateTickAt"
          icon="truncate"
          minValue={0}
          maxValue={200}
          value={leftAxisTruncateTickAt}
          updateValue={setLeftAxisTruncateTickAt}
          variableType=""
        />
        <SliderSetting
          label="labelOffSet"
          icon="straigten"
          minValue={-60}
          maxValue={60}
          value={leftAxisLabelOffSet}
          updateValue={setLeftAxisLabelOffSet}
          variableType="px"
        />
      </Collapse>
    </SettingContainer>
  )
}

export default BarAxisSettings;