import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, Divider, Box, IconButton, TextField, InputAdornment, Collapse, Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useViewport } from "../../../Context/ViewportProvider";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const DiscountSection = ({ comparisonData, changeDiscount, removeAllDiscounts, changeDiscountKey }) => {
  const [showDiscountsSection, setShowDiscountsSection] = React.useState(true);
  const [filteredDiscounts, setFilteredDiscounts] = React.useState([]);
  const { width } = useViewport();

  const toggleDiscountShow = () => {
    setShowDiscountsSection(!showDiscountsSection);
  }

  React.useEffect(() => {
    let updatedDiscounts = [...comparisonData.discounts];

    if (comparisonData.filters.length > 0) {
      let currentDiscountKey = comparisonData.discounts[0].key;
      
      updatedDiscounts = updatedDiscounts.filter(discount => {
        for (const filter of comparisonData.filters) {
          if (filter?.objectKey.split(':')[0] === currentDiscountKey.split(':')[0]) {
            if (!filter.values.includes(discount.label)) {
              return false; // If any filter condition fails, exclude the row
            }
          }
        }
        return true; // Include the row if all filter conditions are met
      });
    }

    setFilteredDiscounts(updatedDiscounts);
  }, [comparisonData.filters, comparisonData.discounts]);

  return(
    <>
    <Grid xs={12} mt={5}>
    <Stack direction={width > 500 ? "row" : "column"} sx={{margin: "auto"}}>
      <Box sx={{display: "inline-flex"}}>
      <IconButton color="primary" onClick={() => toggleDiscountShow()}>
        {showDiscountsSection ? 
        <KeyboardArrowUpIcon />
        :
        <KeyboardArrowDownIcon />
        }
      </IconButton>
      <Typography sx={{marginTop: "auto", marginBottom: "auto"}}><b><TranslationContent contentID="discount" /> %</b></Typography>
      </Box>
      {width < 500 ? <br /> : null}
      <Box sx={{marginRight: width > 500 ? 0 : "auto", marginLeft: width < 500 ? 0 : "auto", /*display: "inline-flex",*/}}>
          <FormControl sx={{minWidth: "150px", marginTop: "auto", marginBottom: "auto"}}>
            <InputLabel id="discount-selector"><TranslationContent contentID="sortedBy" /></InputLabel>
          <Select labelId="discount-selector" label={<TranslationContent contentID="sortedBy" />} value={comparisonData?.discounts[0]?.key?.split(":")[0]} onChange={(event) => changeDiscountKey(event)} size="small" variant="outlined">
            {comparisonData.headers.map(header => 
            header.type === "string" && 
            <MenuItem key={header.name} value={header.name}>{header.name}</MenuItem>
            
            )}
          </Select>
          </FormControl>&nbsp;&nbsp;
      <Button onClick={() => removeAllDiscounts() } variant="text" size="small" /*sx={{minWidth: "150px"}}*/><TranslationContent contentID="removeDiscounts" /></Button>
      </Box>
    </Stack>
</Grid>

<Grid xs={12}>
<Collapse in={showDiscountsSection}>
  <Box>
    <Grid container spacing={2} sx={{borderBottom: "solid"}}>
      <Grid xs={12}>
        <Divider sx={{marginBottom: "1vh"}}/>
      </Grid>
      {filteredDiscounts.map((discount, index) => 
      <Grid xs={12} sm={6} md={4} xl={3} key={discount+index+discount.key}>
        <TextField 
        size="small"
          label={discount.label}
          value={discount.discount}
          onChange={(event) => changeDiscount(event, discount.label)}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
      )}
    </Grid>
  </Box>
  </Collapse>
</Grid>
</>
  )
}

export default DiscountSection;