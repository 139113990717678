import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Button, Stack } from "@mui/material";
import GlobalLayout from "../../Layouts/GlobalLayout";
import TranslationContent from "../../Translations/TranslationContent";

const NotFound = () => {
  const [count, setCount] = React.useState(10);
  const navigate = useNavigate();

  const handleBackButton = () => {
    const path = '/'
    navigate(path);
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);
    count === 0 && navigate("/");
    return () => clearInterval(interval);
  }, [count, navigate]);

  return(
  <GlobalLayout>
    <Paper sx={{/*width:"500px",*/ margin: "auto", marginTop: "5px", textAlign: "center"}}>
      <br />
      <br />
      <Stack spacing={0} sx={{width: "180px", margin: "auto"}}>
      <Typography variant="caption"><TranslationContent contentID="notFoundCaption1" /></Typography>
      <Typography variant="h1" sx={{textDecoration: "underline"}}><b>404</b></Typography>
      <Typography variant="caption"><TranslationContent contentID="notFoundCaption2" /></Typography>
      </Stack>
      <br />
      <br />
      <Typography variant="caption"><TranslationContent contentID="notFoundTimer1" /> <b>{count}</b> <TranslationContent contentID="notFoundTimer2" /></Typography>
      <br />
      <br />
      <Button variant="outlined" onClick={() => handleBackButton()}><TranslationContent contentID="notFoundButton" /></Button>
      <br />
      <br />
    </Paper>
  </GlobalLayout>
  )
}

export default NotFound;