import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SwitchSetting from "../../Settings/SwitchSetting";
import SettingDivider from "../../Settings/SettingDivider";
import SliderSetting from "../../Settings/SliderSetting";
import { Collapse, Stack, Typography } from "@mui/material";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";

const BarLabelSettings = 
  ({
    enableLabel,
    updateEnableLabel,
    labelSkipWidth,
    updateLabelSkipWidth,
    labelSkipHeight,
    updateLabelSkipHeight,
    enableTotals,
    updateEnableTotals,
    totalsOffset,
    updateTotalsOffset
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer open={showSettings} toggleOpen={() => setShowSettings(!showSettings)} label="barLabel">
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="identifier" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={enableLabel}
          handleChange={updateEnableLabel}  
        />
      </Stack>
      <Collapse in={enableLabel}>
        <SliderSetting
          label="labelSkipWidth"
          icon="textOffsetX"
          minValue={0}
          maxValue={36}
          step={2}
          value={labelSkipWidth}
          updateValue={updateLabelSkipWidth}
          variableType="px"
        />
        <SliderSetting
          label="labelSkipHeight"
          icon="labelSkipHeight"
          minValue={0}
          maxValue={36}
          step={2}
          value={labelSkipHeight}
          updateValue={updateLabelSkipHeight}
          variableType="px"
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "15px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}}><b><TranslationContent contentID="totals" /></b></Typography>
        <SwitchSetting 
          label="show"
          value={enableTotals}
          handleChange={updateEnableTotals}  
        />
      </Stack>
      <Collapse in={enableTotals}>
        <SliderSetting
          label="totalsOffset"
          icon="yOffset"
          minValue={0}
          maxValue={40}
          step={2}
          value={totalsOffset}
          updateValue={updateTotalsOffset}
          variableType="px"
        />
      </Collapse>
    </SettingContainer>
  )
}

export default BarLabelSettings;